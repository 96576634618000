import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
// myside
import CopyToClipboard from "react-copy-to-clipboard";
import moment from 'moment'
import {
  Link,
  useParams,

} from "react-router-dom";
import config from "views/config/config"

import Avatars from './Avatar';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import { toast } from 'react-toastify';
import action_config from './config/config';

import Banner from "../assets/images/banner.jpg";

import TokenCard from './separate/TokenCard';
import { userRegister, Token_MyList_Func, FollowUnFollowFunc, getFollowStatus } from '../actions/axioss/user.axios'
import { isEmpty } from "actions/common";


toast.configure();


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Myitems(props) {
  const classes = useStyles();
  const { ...rest } = props;

  var { customurl } = useParams();
  const { payload, isAdmin } = useSelector(state => state.LoginReducer.User)

  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  console.log("myitem", accountAddress);
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [cursor, setcursor] = React.useState('');
  const [filter, setFilter] = useState('activity');
  const [Follow, SetFollow] = useState('follow');
  const [value, SetTabName] = React.useState('onsale');
  const [userProfile, setUserProfile] = useState({})
  console.log("hhhhh", userProfile);
  const [followStatus, SetFollowStatus] = useState("")

  const [Tokens, SetTokens] = useState({ 'onsale': { 'loader': true, page: 1, list: [] } })

  console.log('paramss', userProfile)

  useEffect(() => {

    if (typeof Tokens[value] == 'undefined') {
      Tokens[value] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, value);
    }
    if (customurl !== userProfile?.CustomUrl) {
      SetTokens({});
      SetTabName('onsale')
      document.getElementById('onsale-tab').click()
    }

  }, [value, customurl, userProfile?.WalletAddress])

  useEffect(() => {

    // Explore();
    getProfileDetails();
  }, [customurl, userProfile?.WalletAddress, value])

  useEffect(() => {
    console.log('paramss', userProfile)
    // SetTokens(Tokens);
    // Explore(1, value);
    if ((userProfile?.CustomUrl && customurl == userProfile?.CustomUrl) || isEmpty(userProfile?.CustomUrl)) {
      Explore();
    }
    else {
      // SetTokens(Tokens);
      SetTabName('onsale')
      Explore(1, 'onsale');
    }
  }, [customurl])

  useEffect(() => {
    getInitialFollowStatus();

  }, [userProfile, accountAddress])




  const getProfileDetails = async () => {
    var SendDATA = {
      CustomUrl: customurl,
      Type: 'getProfile'
    }

    var profileInfo = await userRegister(SendDATA)
    if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
      console.log("profileInfo?.data?.WalletAddress", profileInfo?.data)
      setUserProfile(profileInfo.data)
    }
  }

  const Explore = async (data, tab) => {
    var page = data ? data : (Tokens[value]?.page)
    var SendDATA = {
      TabName: tab ? tab : value,
      limit: 12,
      CustomUrl: customurl,
      NFTOwner: (userProfile?.WalletAddress),
      page: page ?? 1,
      from: 'myItem',
      cursor: cursor
    }
    console.log("sendadata to myitem", SendDATA)
    let Resp = await Token_MyList_Func(SendDATA)
    console.log('sfgfhgfs', value, tab, Resp)
    if (Resp?.success == 'success' && Resp.data.length > 0) {
      console.log("success call")
      setcursor(Resp?.cursor)

      SetTokens({
        ...Tokens, ...{
          [value]: {
            list: [...Tokens[value].list, ...Resp.data],
            loader: (Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
            page: Tokens[value].page
          }
        }
      })
    }
    else {
      console.log("fail call", Resp)

      // console.log("Resp.data",...Resp.data)
      SetTokens({
        ...Tokens, ...{
          [value]: {
            list: Tokens[value]?.list,
            loader: false,
            page: Tokens[value].page

          }
        }
      })
    }
  }

  const LoadMore = () => {
    Tokens[value].page = Tokens[value].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[value].page);

  }

  const FollowUnFollow = async () => {
    const id = toast.loading(Follow == "follow" ? "You Following " + userProfile?.DisplayName : "You UnFollowing " + userProfile?.DisplayName)
    let SendData = {
      MyItemAddr: userProfile?.WalletAddress,
      ClickAddr: accountAddress,
      From: 'myitem',
      MyItemCustomUrl: userProfile?.CustomUrl,
      ClickCustomUrl: payload?.CustomUrl,
    }
    console.log('senddataaa', SendData, userProfile, payload)
    let Resp = await FollowUnFollowFunc(SendData)
    if (Resp?.success) {
      console.log("Resp.msg follwo", Resp.msg)
      SetFollow(Resp.msg)
      toast.update(id, { render: Resp.msg == 'follow' ? 'You UnFollowing ' + userProfile?.DisplayName + ' Successfully' : 'Your Following ' + userProfile?.DisplayName + ' Successfully', type: 'success', isLoading: false, autoClose: 1000 })
      getInitialFollowStatus();
    }
    else {
      toast.update(id, { render: 'Try Again', type: 'error', isLoading: false, autoClose: 1000 })

    }
  }

  function LikeList(data) {
    setLikedTokenList(data)
  }

  const CoverImg = async (event) => {
    window.$("#closebutton").click();
    const toastupd = toast.loading("You Updated Image")
    var reader = new FileReader()
    const { id, files } = event.target;
    console.log('photooooo', files, id)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];

      if (file.type.includes("image")) {

        var Resp;
        if (id == 'coverupload') {
          console.log("payload banner", payload.WalletAddress)
          Resp = await userRegister({ Type: 'cover', CustomUrl: payload.CustomUrl, Cover: files[0], WalletAddress: payload.WalletAddress })
        }
        else {
          // not used
          Resp = await userRegister({ Type: 'profileimage', CustomUrl: payload.CustomUrl, Profile: files[0] })
        }
        if (Resp.success == 'success') {
          getProfileDetails();
          toast.update(toastupd, { render: Resp.msg, type: 'success', isLoading: false, autoClose: 700 })
        }

      }
      else {
        console.log("invalid image format")
        return toast.update(toastupd, { render: "Choose a valid Image", type: 'error', isLoading: false, autoClose: 700 })
      }
    }

  }


  const getInitialFollowStatus = async () => {
    var paylaod = {
      paramAddress: userProfile?.WalletAddress,
      WalletAddress: accountAddress
    }
    console.log("follow payload", paylaod, userProfile?.WalletAddress, accountAddress)
    var followstatus = await getFollowStatus(paylaod)
    if (followstatus?.status) {
      SetFollowStatus(followstatus?.msg)
    } else {
      SetFollowStatus(followstatus?.msg)

    }
  }








  return (
    <div className="inner_header">

      {console.log("Tokens[value]", Tokens, Tokens["created"])}
      <div id="AfterWalletConnected_two" ></div>
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader items_header"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                {/* <img src={Banner} alt="profile" className="img-fluid" /> */}
                <img src={userProfile?.Cover ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/cover/' + userProfile?.Cover : Banner} alt="profile" className="img-fluid" />
                {accountAddress == userProfile?.WalletAddress &&
                  <div className="container edit_cover_container">

                    <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>

                  </div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg_pink">
                <div className="item_prof">
                  {userProfile?.Profile ?
                    <div className="item_prof_img">
                      <img src={`${config?.IMG_URL}/user/${userProfile?.WalletAddress}/profile/${userProfile?.Profile}`} />
                    </div> :
                    <div className="item_prof_img">
                      <Avatars item="img-fluid items_profile" />
                    </div>}

                </div>
                <span className="address_text">{userProfile?.DisplayName}</span>
                <span className="address_text">{userProfile?.Bio}</span>
                <ul className="social-item">
                  {userProfile?.Facebook && <li><a href={userProfile?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>}
                  {userProfile?.Twitter && <li><a href={userProfile?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>}
                  {userProfile?.Instagram && <li><a href={userProfile?.Instagram} target="_blank"><i className="fab fa-instagram"></i></a></li>}
                  {userProfile?.Youtube && <li><a href={userProfile?.Youtube} target="_blank"><i className="fab fa-youtube"></i></a></li>}
                </ul>
                <p className="text-center">
                  {/* <span className="address_text">{userProfile?.DisplayName }</span> */}
                  <span className="address_text">{userProfile?.DisplayName ? userProfile?.WalletAddress : userProfile?.WalletAddress}</span>
                  {console.log("profilesss", userProfile.WalletAddress)}
                  <span>
                    <CopyToClipboard
                      text={userProfile?.WalletAddress}
                      onCopy={() =>
                        toast.success("Address Copied")
                      } >

                      <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                    </CopyToClipboard>
                  </span>
                </p>






                <div className="mt-3">

                  {accountAddress == userProfile?.WalletAddress ?

                    <button className='create_btn'>

                      <Link className="" to={`/profile/${accountAddress}`}>Edit Profile</Link>

                    </button> :
                    <button className='create_btn' onClick={FollowUnFollow}>{followStatus}

                      {/* <Link className=""></Link> */}

                    </button>}



                  <span className="bg_red_icon cur_poinet" data-toggle="modal" data-target="#share_modal1">
                    <i className="fas fa-share-alt"></i>
                  </span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container-fluid container-theme">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-4 mb-5 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true"
                  >
                    <div className="tab_head" onClick={() => SetTabName("onsale")} >OnSale</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  <a className="nav-link" id="collectibles-tab" onClick={() => SetTabName("owned")} data-toggle="tab" href="#onsale" role="tab" aria-controls="collectibles" aria-selected="false"
                  >
                    <div className="tab_head" onClick={() => SetTabName("owned")}>Collectibles</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  <a className="nav-link" id="created-tab" onClick={() => SetTabName("created")} data-toggle="tab" href="#onsale" role="tab" aria-controls="created" aria-selected="false" >
                    <div className="tab_head">Created</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  <a className="nav-link" id="liked-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="liked" aria-selected="false" >
                    <div className="tab_head" onClick={() => SetTabName("liked")}>Liked</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  <a className="nav-link" onClick={() => SetTabName('following')} id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false" >
                    <div className="tab_head">Following</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  <a className="nav-link" onClick={() => SetTabName('follower')} id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false" >
                    <div className="tab_head">Followers</div>
                    {/* <div className="tab_count">0</div> */}
                  </a>
                  {/* <a className="nav-link" id="UserCollection-tab" data-toggle="tab" href="#userCollection" role="tab" aria-controls="userCollection" aria-selected="false" >
                    <div className="tab_head">Collections</div>
                    <div className="tab_count">0</div>
                  </a> */}

                </div>
              </nav>

              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                {console.log("data profile test", userProfile.Profile)}
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">


                    <div className="row  m-0 ma_no_gap">
                      {Tokens[value] &&
                        Tokens[value]?.list.length > 0 ?
                        (Tokens[value]?.list.map((val, index) =>
                          <>
                            {console.log("valueinmyitem", val)}
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">


                              <TokenCard
                                from={value == 'usercollection' ? 'usercollection' : 'marketplace'}
                                product={value == 'usercollection' ?
                                  {
                                    NFTName: val.name,
                                    CollectionNetwork: action_config.COIN_NAME,
                                    ContractAddress: val.colladdress,
                                    NFTOwner: userProfile.WalletAddress,
                                    NFTId: val.tokenCounts,
                                    NFTRoyalty: val.NFTRoyalty,
                                    Image: val.image,
                                    OwnerName: userProfile.DisplayName,
                                    OwnerProfile: userProfile.Profile,
                                    type: val.type,
                                    amount: val.amount,
                                    ipfshash: val.ipfshash,
                                    meta: val.meta,
                                    metadata: val.metadata

                                  }
                                  : val}
                                type="marketplace"
                                index={index}
                                LikeList={LikeList}
                                LikedTokenList={LikedTokenList}
                                setLikedTokenList={setLikedTokenList}
                                LikeForwardRef={LikeForwardRef}
                              />
                            </div>
                          </>)) :
                        <div className="text-center py-5 col-12  ">
                          <div className="text-center py-3  no_items_row">
                            <div className="mt-3">
                              <span class="address_text">No Items Found</span>
                            </div>
                          </div>
                        </div>




                      }

                    </div>





                    <div className="text-center mt-4 w-100 ">
                      <div id="spinButs" className="overflow-hidden">
                        {Tokens[value]?.loader &&
                          <Button className="create_btn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}
                            onClick={LoadMore}>
                            Load More <span>

                            </span>

                          </Button>}

                      </div>
                    </div>


                  </div>

                </div>

                <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="proposal_panel_overall">



                    <div className="masonry myitemcards m-0 ma_no_gap">

                      <div className="item ">

                        <TokenCard


                        />
                      </div>

                    </div>





                    <div className="text-center mt-4 w-100 ">

                      <div id="spinButs" className="overflow-hidden">
                        <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}>
                          Load More <span>

                          </span>

                        </Button>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="proposal_panel_overall">
                    <div className=" masonry myitemcards m-0 ma_no_gap">

                      <div className="item ">

                        <TokenCard


                        />
                      </div>
                    </div>



                    <div className="text-center mt-4 w-100 ">

                      <div id="spinButs" className="overflow-hidden">
                        <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}>
                          Load More <span>

                          </span>

                        </Button>

                      </div>
                    </div>


                  </div>
                </div>

                <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                  <div className="proposal_panel_overall">
                    <div className=" myitemcards masonry m-0 ma_no_gap">



                      <div className="item ">

                        <TokenCard
                        />
                      </div>

                    </div>


                    <div className="text-center mt-4 w-100 ">

                      <div id="spinButs" className="overflow-hidden">
                        <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}>
                          Load More <span>

                          </span>

                        </Button>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">

                    <div className="followers_overall py-1" >

                      <div className="row" >

                        <div className="col-12 col-md-6 col-xl-4 mb-4" >
                          <div className="card my-0 h-100 acti_card">
                            <div className="card-body px-3">
                              <div className="media follow_media icon_img">
                                <div className="icon-img-div">
                                  <div className="">
                                    {/* <i className="fas fa-share-alt"></i> */}
                                  </div>
                                  <div className="img_media_new  mr-3">

                                    <img src="" alt="User" className="img-fluid" />



                                    <div className="img-fluid">
                                      <Avatars item="img-fluid" /></div>



                                  </div>
                                </div>
                                <div className="media-body flex_body">
                                  <div>
                                    <div >
                                      <p className="my-0 media_text" title="">00</p></div>

                                    <div ><p className="mt-0 media_num mt-0">
                                      <b>name</b> activity</p></div>

                                    <div ><p className="my-0 media_text" title="">fieldname</p></div>



                                  </div>

                                </div>
                              </div>
                              <div className="ml-2">
                                <p className="mt-0 media_num mb-0 dur_text">		{moment("00/00/0000").format('MM/DD/YYYY, h:mm a')}
                                </p>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                  {
                    <div className="followers_overall py-1">
                      <div className="row">
                        {Tokens["following"] &&
                          Tokens["following"]?.list.length > 0 ?
                          (Tokens["following"]?.list.map((val, index) =>
                            <div className="col-12 col-md-6 col-xl-4 mb-4">

                              <div className="card my-0">
                                <div className="card-body">
                                  {/* <Link to={`/my-item/${val?.CustomUrl}`}> */}
                                  <a href={`/my-item/${val?.CustomUrl}`}>
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">
                                        <div className="img-fluid">
                                          {(val?.Profile) ?
                                            <img src={`${config.IMG_URL}/user/${val?.WalletAddress}/profile/${val?.Profile}`}></img> :
                                            <Avatars item="img-fluid" />

                                          }</div>
                                      </div>
                                      <div className="media-body flex_body"
                                      >
                                        <div>

                                          <>
                                            <p className="my-0 media_text" title="">{(val?.DisplayName) ?
                                              (val?.DisplayName).length > 12 ? (val?.DisplayName).slice(0, 13).concat("...") : (val?.DisplayName)
                                              : (val?.WalletAddress)
                                            }</p>
                                            <p className="mt-0 media_num font_14 mb-0">You Started Following</p>




                                          </>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </Link> */}
                                  </a>
                                </div>
                              </div>
                            </div>)) :
                          <div className="text-center py-5 col-12  ">
                            <div className="text-center py-3  no_items_row">
                              <div className="mt-3">
                                <span class="address_text">No Users Found</span>
                              </div>
                            </div>
                          </div>
                        }


                      </div>

                    </div>}
                </div>
                <div className="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                  <div className="proposal_panel_overall">
                    {
                      <div className="followers_overall py-1">
                        <div className="row">
                          {Tokens["follower"] &&
                            Tokens["follower"]?.list.length > 0 ?
                            (Tokens["follower"]?.list.map((val, index) =>


                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0">
                                  <div className="card-body">
                                    <a href={`/my-item/${val?.CustomUrl}`}>

                                      <div className="media follow_media">
                                        <div className="img_media_new  mr-3">

                                          <div className="img-fluid">
                                            {(val?.Profile) ?
                                              <img src={`${config.IMG_URL}/user/${val?.WalletAddress}/profile/${val?.Profile}`}></img> :
                                              <Avatars item="img-fluid" />

                                            }</div>

                                        </div>
                                        <div className="media-body flex_body"
                                        >
                                          <div>


                                            <>


                                              <p className="my-0 media_text" title="">{(val?.DisplayName) ?
                                                (val?.DisplayName).length > 12 ? (val?.DisplayName).slice(0, 13).concat("...") : (val?.DisplayName)
                                                : (val?.WalletAddress)
                                              }</p>
                                              <p className="mt-0 media_num font_14 mb-0">Started Following You</p>

                                            </>

                                          </div>
                                        </div>
                                      </div>
                                    </a>

                                  </div>
                                </div>
                              </div>)
                            ) :
                            <div className="text-center py-5 col-12  ">
                              <div className="text-center py-3  no_items_row">
                                <div className="mt-3">
                                  <span class="address_text">No Users Found</span>
                                </div>
                              </div>
                            </div>}

                        </div>

                      </div>}

                  </div>
                </div>

                <div className="tab-pane fade" id="NewCollectibles" role="tabpanel" aria-labelledby="NewCollectibles-tab">
                  <div className="proposal_panel_overall">
                    {
                      <div className=" masonry myitemcards m-0 ma_no_gap">


                        <div className="item ">
                          <div className="item itemd_heih_adj">
                            <div className="card_inner_item">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex creators_details mb-2">
                                  <Avatars item="img-fluid align-self-center" />

                                </div>
                                <div className="pl-2">
                                  <div className="dropdown ">
                                    <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      ....
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                      <div className="menu_itm dropdown-item" id={"BurnCollectible"} data-toggle="modal" data-target="#share_modal" >
                                        <span>Share</span>
                                      </div>
                                    </div>




                                  </div>
                                </div>

                              </div>
                              <div className="remaintime">
                                <div className="item_inner_img  my_itens_sec_img">

                                  <>



                                    <object type="image/webp" data=""
                                    >
                                    </object>



                                    {/* <object type="image/webp" data=""
                                                  >
                                                  </object> */}



                                  </>

                                </div>
                              </div>

                              <h2>name</h2>
                              <div className="d-flex justify-content-between align-items-center">
                                <h3 className="mb-0" >1</h3>

                              </div>

                            </div></div>
                        </div>

                      </div>
                    }

                  </div>
                </div>

                <div className="tab-pane fade" id="userCollection" role="tabpanel" aria-labelledby="UserCollection-tab">
                  <div className="proposal_panel_overall">
                    {/* {console.log("fgbfdgbfdbgfd", userCollection)} */}

                    <div className="followers_overall py-1">
                      <div className="row">

                        <div className="col-12 col-md-6 col-xl-4 mb-4">
                          <div className="card my-0">
                            <div className="card-body">
                              <div className="media follow_media">
                                <div className="img_media_new  mr-3">







                                  <div className="img-fluid">
                                    <Avatars item="img-fluid" /></div>



                                </div>
                                <div className="media-body flex_body">
                                  <div>
                                    <>

                                      <p className="mt-0 media_num font_14 mb-0">collectionName</p>


                                      <p className="my-0 media_text" >collectionType</p>

                                    </>

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div className="modal fade primary_modal" id="edit_cover_modal" tabIndex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
              <h5 className="modal-title" id="edit_cover_modalLabel_2">Follow Steps</h5>

              <button type="button" id="closebutton" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution and supported format PNG,GIF,WEPG,JPG</p>
                <form className="text-center">
                  <Button className="file_btn btn primary_btn" >Choose image
                    <input className="inp_file"
                      type="file"
                      id="coverupload" name="upload" onChange={(e) => CoverImg(e)}

                    />
                  </Button>


                </form>
                <div className="text-center mb-2">

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      <div className="modal fade primary_modal" id="share_modal1" tabIndex="-1"
        role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-module">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="share_modalLabel">Share this NFT</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>

              </div>
              <div className="modal-body px-0">
                {console.log("title,url", `${userProfile?.DisplayName}  User`, `${config.FRONT_URL}/user/${userProfile?.CustomUrl}`)}
                <div className="row justify-content-center mx-0">
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TwitterShareButton
                        title={`${userProfile?.DisplayName}  User`}
                        url={`${config.FRONT_URL}/user/${userProfile?.CustomUrl}`} >
                        <i className="fab fa-twitter"></i>
                        <p>Twitter</p>
                      </TwitterShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TelegramShareButton
                        title={`${userProfile?.DisplayName}  User`}
                        url={`${config.FRONT_URL}/user/${userProfile?.CustomUrl}`} >

                        <i className="fab fa-telegram-plane"></i>
                        <p>Telegram</p>

                      </TelegramShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <FacebookShareButton
                        quote={`${userProfile?.DisplayName} User`}
                        // title={`${productdetail.TokenId}  NFT`}
                        url={`${config.FRONT_URL}/user/${userProfile?.CustomUrl}`} >
                        <i className="fab fa-facebook-f"></i>
                        <p>Facebook</p>
                      </FacebookShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <WhatsappShareButton
                        title={`${userProfile?.DisplayName}  User`}
                        url={`${config.FRONT_URL}/user/${userProfile?.CustomUrl}`} >
                        <i className="fab fa-whatsapp"></i>
                        <p>Whatsapp</p>
                      </WhatsappShareButton>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
