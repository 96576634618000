
import React from "react";
import Avatars from "views/Avatar";
import  Config  from "views/config/config";
import { Link, useHistory } from "react-router-dom";



export default function UserCard(props) {

const product = props?.data
const history = useHistory();

  return (

    <div className="card my-0">
      <div className="card-body px-3">
        <Link to={`/my-item/${product?.CustomUrl}`}>
        <div className="media follow_media">
          <div className="img_media_new  mr-3">

           
   
                  <div >
                    {(product?.Profile)?
                      <img src={`${Config?.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Profile}`} /> :
                      <Avatars item="img-fluid" />
                    }
                
                  </div>
           
          </div>
          <div className="media-body flex_body">
            <div>
             
                <p className="my-0 media_text" title="">{(product?.DisplayName)?
                (product?.DisplayName).length>16?(product?.DisplayName).slice(0,16).concat("..."):(product?.DisplayName)
                :(product?.WalletAddress)
                }</p>
            

            </div>

          </div>
        </div>
        </Link>

      </div>
    </div>

  )
}
