
// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
const EnvName = 'production';
let key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://bidexnft.infura-ipfs.io/ipfs/'
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if (EnvName === "demo") {
    key.FRONT_URL = 'https://bidex.maticz.in/'
    key.BACK_URL = 'https://backend-bidex.maticz.in/v1/front'
    key.ADMIN_URL = 'https://backend-bidex.maticz.in/v1/admin'
    key.IMG_URL = 'https://backend-bidex.maticz.in/'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'
    key.TradeContract = '0x6918fC142BD8dA882efA8280ac32DA11A2622CbE'
    key.ERC721 = '0xeaaff0ab5b7f19a24746c24e8b2d6136058be3ee'
    key.ERC1155 = '0x0ef500eb2a670525e6e78a6d5e72ca3b868144c2'
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'
    key.RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/"
    key.CHAIN_ID = 97
    key.COIN_NAME = "BNB"
    key.chain_Id_List = [97, 80001]
    key.tickerNameEthereum = "Binance testnet"
}
else if (EnvName === "stage") {

}
else if (EnvName === "production") {
    key.FRONT_URL = 'https://bidexnft.io/v1/front'
    key.BACK_URL = 'https://api.bidexnft.io/v1/front'
    key.ADMIN_URL = 'https://api.bidexnft.io/v1/admin'
    key.IMG_URL = 'https://api.bidexnft.io'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'
    key.TradeContract = '0xD59aeE8E76Aa7c86314974d576ADe6d70Efd3090'
    key.ERC721 = '0xc80299d55412071f702772Df6283a8aE1598250A'
    key.ERC1155 = '0x672A391f531bcfdb9d6BE83270449bC4EC4b1350'
    key.erc20Address = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    key.RPC_URL = "https://bsc-mainnet.nodereal.io/v1/d5f3c74dd6164c8f8d7473486ebff75c"
    key.CHAIN_ID = 56
    key.COIN_NAME = "BNB"
    key.chain_Id_List = [56]
    key.tickerNameEthereum = "Binance"
}
else {
    key.FRONT_URL = 'http://localhost:3000'
    key.BACK_URL = 'http://localhost:3331/v1/front'
    key.ADMIN_URL = 'http://localhost:3331/v1/admin'
    key.IMG_URL = 'http://localhost:3331'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'
    key.TradeContract = '0x5fe0eaf6c7804dbe6d4e73403307e3d2fbd72c95'
    key.ERC721 = '0xbb888c8278b62a9bae4ff8d9360e8cb989a32633'
    key.ERC1155 = '0x7f61f811ada27208f19f89df46544ca152ac95a7'
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'
    key.RPC_URL = "https://data-seed-prebsc-1-s1.bnbchain.org:8545"
    key.CHAIN_ID = 97
    key.COIN_NAME = "BNB"
    key.chain_Id_List = [97, 80001]
    key.tickerNameEthereum = "Binance testnet"
}
export default key;

