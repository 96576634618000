 import React, {
    forwardRef,useImperativeHandle,useState
} from 'react';
import { Button } from '@material-ui/core';
import '@metamask/legacy-web3'
import $ from "jquery"

import { useSelector } from 'react-redux';
import { report } from 'actions/axioss/user.axios';
import { useHistory } from 'react-router-dom';




import { toast } from 'react-toastify';



toast.configure();

export const ReportNowRef = forwardRef((props, ref) => {


const history = useHistory();

const [item,setItem] = useState("");
const [Message,SetMessage] = useState('')
const [Btn,SetBtn] = useState('start')
const { payload } = useSelector(state => state.LoginReducer.User)

const FormSubmit =   async()  =>  {
    SetBtn('process')
    window.$("#report_modal").modal("hide")

    const id = toast.loading('Reporting')
    var senddata = {
       ...item ,
       Address  :  payload.WalletAddress,
       CustomUrl  :  payload.CustomUrl,
       Message  :  Message
    }
    let Resp = await report(senddata)
    if(Resp){    
      toast.update(id, { render: Resp?.msg, type: Resp?.success, isLoading: false, autoClose: 1000 })
      SetBtn('done')
      history.push("/explore/All")

    }

 }

    useImperativeHandle(
        ref,
        () => ({
            async Report_Click( data) {
              var {item} =data;
              console.log("report item",item) 
              setItem(item)         
              window.$("#report_modal").modal("show")

              
            }
     
    
         
        }),
        
    )

    
    


  


  
    return (
        <div>
            <div className="modal fade primary_modal" id="report_modal" tabIndex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="report_modalLabel">Report This Profile?</h5>
                            <button type="button" className="close" id="closereport" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <p className="font_14 font_600 line_20 mb-1 text_drk_bl">Tell us how this user violates the rules of the site</p>
                                <div className="form-row mt-3">
                                    <div className="form-group col-md-12">

                                        <label className="primary_label" htmlFor="name">Message</label>
                                        <textarea
                                      
                                        className="form-control primary_inp"
                                         id="report"
                                         name="report"
                                         rows="3" 
                                         placeholder="Tell us some details"
                                         onChange={(e)=>SetMessage(e.target.value)}
                                          ></textarea>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <Button  className="primary_btn  btn_white btn_big_white w-100" onClick={FormSubmit}>Report</Button>
                                    <p className="text-danger" id="reportmessage" ></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

