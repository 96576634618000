import config from '../../views/config/config'
import { axiosFunc, AppenData } from '../common'

// Common Formdata function

// const AppenData = (data) =>{
//     var formdata = new FormData()
//     var SendDta = Object.entries(data).map((item)=>{
//         if(Array.isArray(item[1])){
//           var come=  item[1].map(data=>{
//                 formdata.append(item[0],data)
//             return formdata
//             })
//             return come

//         }
//         else{
//             formdata.append(item[0],item[1])
//             return formdata 
//         }
//     })
//  return SendDta
// }

// Common Axios Function
// const axiosFunc    =   async   (data)  =>  {
//     try{
//         let Resp    =   await axios(data)
//         return Resp
//     }
//     catch(e){
//         return {success:'error',msg:null}
//     }
// }


var token;


export const GetUserCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        console.log("hasbdoaudqiw", token);
        const parts = v.split('=')
        console.log('egfwafwer', parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
    }, '');
}



//user ProfileCreate, Update axios Function 
export const userRegister = async (data) => {
    console.log("UserRegister is Calling", data)
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/user/create`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }

    let Resp = await axiosFunc(senddata)
    // console.log('resppp',Resp,senddata,data)
    return Resp.data
}

export const Token_MyList_Func = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/myItemList`,
        params: data
    }
    let Resp = await axiosFunc(senddata)

    return Resp.data
}

export const FollowUnFollowFunc = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/user/FollowUnFollowFunc`,
        data: data,
        'headers': {
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata);

    return Resp.data;
}

export const getFollowStatus = async (data) => {
    console.log("data to get follow status", data)
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/user/getfollowstatus`,
        params: data
    }
    let Resp = await axiosFunc(senddata);

    return Resp.data;
}

export const GetLikeDataAction = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/like/list`,
        'headers': {
            'Authorization': token
        },
        data: data
    }
    let Resp = await axiosFunc(senddata)
    return Resp.data
}

export const AddLikeAction = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/like`,
        data: data,
        'headers': {
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    return Resp.data
}

export const findOwners = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/findOwners`,
        params: data

    }
    let Resp = await axiosFunc(senddata)
    return Resp.data
}

export const Newsletter = async (data) => {
    console.log("newsletter front", data)
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/user/newsletter`,
        'headers': {
            'Authorization': token
        },
        data: data
    }
    console.log('hdghgh', senddata)
    let Resp = await axiosFunc(senddata);

    return Resp.data;
}

export const Report = async (data) => {
    var senddata = {
        'method': 'post',
        'url': `${config.BACK_URL}/token/report`,
        'headers': {
            'Authorization': token
        },
        data: data
    }
    let Resp = await axiosFunc(senddata);

    return Resp?.data;
}
// report
export const report = async (data) => {
    var senddata = {
        'method': 'post',
        'url': `${config.BACK_URL}/nft/report`,
        'headers': {
            'Authorization': token
        },
        data: data
    }
    let Resp = await axiosFunc(senddata);

    return Resp?.data;
}

export const Sociallinks = async () => {

    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/user/social`
    }
    let Resp = await axiosFunc(senddata);

    return Resp.data;
}


export const getFaqList = async () => {

    try {
        var resp = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/getfaqlist`,
        })
        console.log("tokonwer resp", resp.data)
        return resp.data;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const getuser = async () => {
    try {
        var resp = await axiosFunc({

            'method': 'GET',
            'url': `${config.BACK_URL}/user/getuser`,
        })
        console.log("tokonwer resp", resp.data)
        return resp.data;
    }
    catch (err) { console.log("err in gettok owner", err) }
}


export const getcms = async (data) => {

    console.log("sdlksddas");
    let Resp = await axiosFunc({
        method: 'get',
        url: `${config.BACK_URL}/user/getcms`,
        params:data

    })
    console.log("concms list", Resp);
    return Resp.data
}

export const getallcms = async (data) => {

    console.log("sdlksddas");
    let Resp = await axiosFunc({
        method: 'get',
        url: `${config.BACK_URL}/user/getallcms`,
        params:data

    })
    console.log("concms list", Resp);
    return Resp.data
}

export const kycadd = async (data) => {

    console.log("rytryhrtyh");
    let Resp = await axiosFunc({
        method: 'POST',
        'headers': {
            'Content-Type': 'multipart/form-data',
            // 'Authorization': token
        },
        url: `${config.ADMIN_URL}/user/kycadd`,
        data: data

    })
    console.log("concms list", Resp);
    return Resp.data
}


export const getallkyc = async (data) => {
console.log("sdfsdfsd",data);
    console.log("sdlksddas");
    let Resp = await axiosFunc({
        method: 'post',
        url: `${config.BACK_URL}/user/getallkyc`,
        data:data

    })
    console.log("concms list", Resp);
    return Resp.data
}
// export const getCmsContent = async () => {
//     console.log("dfdsfdf");

//     try {
//         var Resp = await axiosFunc({

//             method: 'GET',

//             "headers": {
//                 "Content-Type": "multipart/form-data",
//             },
//             url: `${config.BACK_URL}/user/getcmslist`,
//             // params: data
//         })
//         console.log("tokonwerresp", Resp)
//         return Resp.data;
//     }
//     catch (err) { console.log("err in gettok owner", err) }
// }
