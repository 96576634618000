import React, {
    forwardRef,
    useImperativeHandle
} from 'react';


import Web3 from 'web3';



import { toast } from 'react-toastify';
toast.configure();


export const WalletRef = forwardRef((props, ref) => {


})