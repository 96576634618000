import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useMemo
} from 'react';
import {
  useHistory, useLocation
} from "react-router-dom";
import { Button } from '@material-ui/core';
import video from 'video.js';
import $ from 'jquery';
// import Web3 from 'web3';
import '@metamask/legacy-web3'
import Card from "../../assets/images/carddet.jpg"
// import Audios from 'assets/images/audio.png'
import Select from 'react-select';
import randomInteger from 'random-int';
import Convert1 from '../separate/Convert1'
// import ReactPlayer from 'react-player';
import Convert from '../separate/Convert'
import { toast } from 'react-toastify';
import Web3 from "web3"
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import { useSelector, useDispatch } from "react-redux";

import useContractProviderHook from "./../../actions/contractProviderHook";
import { isEmpty, NumANdDotOnly } from "../../actions/common";
import config from "../config/config";
import { network } from '../config/networkconfig'
import { CreateOrder, getAllChain ,updatetokenHash} from "../../actions/axioss/nft.axios";
import { checkChain } from 'views/hooks/useWallet';
import ImgAudVideo from "views/separate/ImgAudVideo";
import { Gettoken } from "../../actions/axioss/nft.axios"






toast.configure();


export const PutOnSaleRef = forwardRef((props, ref) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose8 = () => setShow8(false);
  const [show4, setShow4] = useState(true);
  const [show8, setShow8] = useState(false);
  const handleClose4 = () => setShow4(false);
  const [BtnData, SetBtnData] = useState("start");
  const [TokenBtn, SetTokenBtn] = useState("start");
  const [Mintbtn, SetMintbtn] = useState("start");
  const [dropdown, setdropdown] = useState(false);
  const [dropdown1, setdropdown1] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { currency } = useSelector((state) => state.LoginReducer);
  const ContractCall = useContractProviderHook();
  // const push = useNavigate();

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { web3 } = useSelector((state) => state.LoginReducer.AccountDetails);
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
  // const [lazy, setlazy] = useState({})
  const [FormValue, SetFormValue] = useState("");
  console.log("fffefrfffeff", FormValue);
  const [owner, SetOwner] = useState("");

  const [ValidateError, SetValidateError] = useState({});

  console.log("web3 available", web3)

  var chainid = null;


  useImperativeHandle(
    ref,
    () => ({
      async PutOnSale_Click(data) {
        var { owner, item } = data;
        chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
        console.log('shgkdfjhgskjhgsxxxaa', data)
        var currentChainId = await web3.eth.getChainId();
        if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
         console.log("sdffsdfsdfdgdg");
          if (item.status == "Lazymint") {
            SetFormValue(item)
            SetOwner(owner)
            window.$("#PutOnSale_modal").modal("show")
          }
          else {
            let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)
            console.log("Nftbalance",Nftbalance);
            // if (Nftbalance != owner.NFTBalance) {
              if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
              // window.$("#PutOnSale_modal").modal("hide")
              // $('#close').trigger("click");
              console.log("mmmmmmmmmmmmxxxx" ,Nftbalance )
              console.error("butsssssssxxx"  , owner)
              toast.warning("You won't buy at this moment please refresh you data");
              setTimeout(() => {
                history.push("/")
              }, 1000);
            } else {
              SetFormValue(item)
              SetOwner(owner)

              window.$("#PutOnSale_modal").modal("show")
            }

          }
        } else {
          var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
          var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
          if (proceed) {
            var Allnetwork = await getAllChain()
            var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
            var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
            console.log("sdadasdasdas", curr[0]);
            if (Allnetwork?.status) {
              proceed.chainList = Allnetwork?.data;
            }
            toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
            dispatch({
              type: "Account_Section",
              Account_Section: { AccountDetails: proceed }
            })
            dispatch({
              type: "Register_Section",
              Register_Section: {
                currency: FinalCurrency,

              },
            });
          }
          else {
            toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
            window.$("#PutOnSale_modal").modal("hide")
          }
        }
      }
    }),
  )

  const onChange = (e) => {
    const { files, value, id } = e.target;
    console.log("price@123", value);
    SetBtnData("start");
    // var price = NumANdDotOnly(value)
    // console.log("price@1243",price);
    SetFormValue({ ...FormValue, ...{ [id]: NumANdDotOnly(value) } });
  };


  const onSelectChange = (e, data) => {
    SetBtnData("start");

    const id = "CoinName";
    //console("ada", e,id)
    const { label, value } = e;
    SetFormValue({ ...FormValue, ...{ [id]: value } });
  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(FormValue.NFTPrice);
  }, [FormValue.NFTPrice]);

  const FormSubmit = async () => {
    SetBtnData("start");
console.log("putttonsallleee");
    const id = toast.loading("Validating Form");
    var Error = Validation(FormValue);
    SetBtnData("process");
    if (isEmpty(Error)) {
      let Respc = await ContractCall.Contract_Base_Validation();
      if (!Respc) {
        let Statu = await ContractCall.GetApproveStatus(
          FormValue.ContractType == 721 || FormValue.ContractType == "721"
            ? "Single"
            : "Multiple",
          FormValue.ContractAddress
        );
        console.log("isapproved putonsalwe", Statu)
        if (Statu == true) {
          setShow4(false);
          setShow8(true);
          SetBtnData("process");
          toast.update(id, {
            render: "Ready To Place Order",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
          window.$("#PutOnSale_modal").modal("hide")
          window.$("#price_item_modal").modal("show")
        } else {
          setShow4(false);
          setShow8(true);
          SetBtnData("open");
          toast.update(id, {
            render: "Get APProve",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
          window.$("#PutOnSale_modal").modal("hide")
          window.$("#price_item_modal").modal("show")
        }
      } else {
        SetBtnData("error");
        SetValidateError(Respc);
      }
    } else {
      toast.update(id, {
        render: "Check Fields",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      SetBtnData("error");
      SetValidateError(Error);
    }
  };

  const Validation = (data) => {
    let ValidateError = {};
    const { NFTPrice, CoinName, PutOnSaleType, ClockTime, EndClockTime } = data;
    console.log("NFTPrice, CoinName, PutOnSaleType, ClockTime, EndClockTime", NFTPrice, CoinName, PutOnSaleType, ClockTime, EndClockTime)
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction" ) &&
      (!NFTPrice || NFTPrice == 0 || NFTPrice  == "0")
    )
      ValidateError.NFTPrice = "TokenPrice Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction" ) &&
      !CoinName && CoinName == undefined
    )
      ValidateError.CoinName = "CoinName Required";
    if (PutOnSaleType == "TimedAuction" && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if (PutOnSaleType == "TimedAuction" && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";

    console.log("ValidateError", ValidateError)
    return ValidateError;
  };

  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      FormValue.ContractType == 721 || FormValue.ContractType == "721"
        ? "Sinle"
        : "Multiple",
      FormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtnData("process");
    } else SetTokenBtn("try");
  };

  async function ListCall() {
    const id = toast.loading("Listing Processing");
    SetMintbtn("process");
    console.log("aiuewhiruwjrw", owner);
    if (FormValue.PutOnSaleType == "FixedPrice") {
      var error = await ContractCall.Contract_Base_Validation();
      if (error)
        toast.update(id, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      else {
        const cont = await ContractCall.place_order_721_1155(
          owner.NFTId,
          web3.utils.toWei(FormValue.NFTPrice?.toString()),
          FormValue.ContractAddress,
          owner.NFTOwner,
          Number(FormValue.ContractType),
          "order"
        );
        console.log("cont", cont)
        if (cont) {
          let _data = FormValue;
          _data.NFTOwner = payload.WalletAddress;
          _data.HashValue = cont.HashValue;
          _data.NFTId = owner.NFTId;
          _data.activity = "PutOnSale";
          _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
          BackCall(id, _data);
        } else {
          console.log("json fil")
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
          console.log("ewjewkljelwjrkwejkrweklr")
          SetMintbtn("try");
        }
      }
    } else {
      let _data = FormValue;
      _data.NFTOwner = payload.WalletAddress;
      _data.HashValue = "";
      _data.NFTId = owner.NFTId;
      _data.activity = "PutOnSale";
      _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;

      BackCall(id, _data);
    }
  }

  const BackCall = async (id, _data) => {
    let Resp = await CreateOrder(_data);
    if (Resp.success == "success") {
      toast.update(id, {
        render: "Listed Successfully",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
      });
      SetMintbtn("done");
      history.push("/my-item/" + payload.CustomUrl);
    } else {
      console.log("dsahgdhasgjgdjasd")
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      SetMintbtn("try");
    }
  };


  const BackCall_Lazy = async (signhash, RandomName, NonceHash) => {
    const id = toast.loading("Listing Processing");
    console.log("fffffffffffffff");
    var Error = Validation(FormValue);
    console.log("errorrr",Error);
    if(isEmpty(Error)){
      let _data = FormValue;
      _data.NFTOwner = payload.WalletAddress;
      _data.NFTId = owner.NFTId;
      _data.activity = "PutOnSale";
      _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
      var Resptoken = await updatetokenHash({ NFTIdeee: owner.NFTId, HashValue: signhash, RandomName: RandomName, NonceHash: NonceHash });
      console.log("ResptokenResptokenResptoken", Resptoken);
      let Resp = await CreateOrder(_data);
      if (Resp.success == "success") {
        toast.update(id, {
          render: "Listed Successfully",
          type: Resp.success,
          isLoading: false,
          autoClose: 1000,
        });
        $('#close9').trigger("click");
        SetMintbtn("done");
  
        history.push("/my-item/" + payload.CustomUrl);
      } else {
        console.log("dsahgdhasgjgdjasd")
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        SetMintbtn("try");
      }
    }
    else{
      toast.update(id, {
        render: "Check Fields",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      SetBtnData("error");
      SetValidateError(Error);
    }
    
  };

  const signcall = async () => {
    SetBtnData("process")
    const id = toast.loading("Signature Call", { closeButton: true, theme: "light" });
    // var generator = require('generate-password');
    if (web3) {
        var randomNum = randomInteger(10000000, 100000000);
        console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
        var password = "Bidex"
        // var password = generator.generate({
        //     length: 10,
        //     numbers: true
        // });
        console.log("asdasdasdas", password);
        if (web3) {
            var web3RpcPro = new Web3(web3.providers);
            console.log("asddfsfsdvbvbvcb", web3RpcPro, payload.WalletAddress);
            const to = payload.WalletAddress
            const _amount = FormValue.PutOnSaleType == "FixedPrice" ? web3RpcPro.utils.toWei(String(FormValue.NFTPrice)) : 0
            console.log("ajhghjas", _amount);
            const _nonce = Date.now();
            console.log("qwewqewqreqwrqrq", password);

            var tot = _nonce + Number(randomNum);
            console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            console.log("asdsadasfdafaf", result);
            const signhash = await web3.eth.personal.sign(result, to);
            console.log("Signature", result, signhash)
            if (signhash) {
                // SetSignTxt('done')
                toast.update(id, {
                    render: "Approved Successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2500,
                    closeButton: true,
                    theme: "light"
                });
                BackCall_Lazy(signhash, password, tot);
                // lazymint({ _nonce: tot, Randomname: password, signature: signhash })
            }
        }
    }
}

  return (
    <>

      <div className="modal fade primary_modal" id="PutOnSale_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true"   >
        {console.log("foemvalue", FormValue, owner)}
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Put on Sale</h5>
              <div className="change_price_img_div">
                {/* {
                                   
                                    <video
                                        alt="video"
                                        id="my-video"
                                        className="img-fluid" muted
                                        autoPlay controls playsInline loop
                                        preload="auto"
                                    >
                                        <source src="" type="video/mp4" />
                                    </video>
                                }
                                {
                                    
                                    <>
                                       

                                        <audio
                                            muted
                                            className="img-fluid"
                                            autoPlay
                                            controls
                                            playsInline
                                            loop
                                        >
                                            <source src=""type="audio/mp3" />
                                        </audio>
                                    </>
                                }
                                {
                                    <img src=""alt="Collections" className="img-fluid" />
                                } */}
                <ImgAudVideo
                  file={`${config.IMG_URL}/nft/${FormValue.NFTCreator}/Compressed/NFT/${FormValue.CompressedFile}`}
                  type={
                    FormValue.CompressedFile
                      ? FormValue.CompressedFile?.includes(".webp")
                        ? "image"
                        : FormValue.CompressedFile.includes(".webm")
                          ? "video"
                          : "audio"
                      : FormValue.CompressedFile
                  }
                  thumb={`${config.IMG_URL}/nft/${FormValue.NFTCreator}/Compressed/NFT_THUMB/${FormValue.CompressedThumbFile}`}
                  from="info"
                  origFile={`${config.IMG_URL}/nft/${FormValue.NFTCreator}/Original/NFT/${FormValue.OriginalFile}`}
                  className="img-fluid"
                />
                {/* <img src={Card} /> */}
              </div>
              <p className="text-gray font_we_600 font_12">You are about to place sale for
                <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{FormValue?.NFTName}</span>


                {/* <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                    {
                                        
                                            <span className="word_brak_text_inline_new">owner <span>{String(owner?.NFTOwner).slice(0,8).concat("....")}</span></span>
                                            
                                    }
                                </span> */}
              </p>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close9">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <form className="px-4 bid_form">

                <>
                  <div className="input-group mb-2 input_grp_style_1 putonslae">
                    <input
                      type="text"
                      autoComplete="off"
                      className=""
                      placeholder="e.g.10"
                      onChange={onChange}
                      id="NFTPrice"
                    //   value={FormValue.NFTPrice}
                    />
                    {
                      <div className="input-group-append">
                        <Select
                          defaultValue={selectedOption}
                          isSearchable={false}
                          onChange={(e) => onSelectChange(e, "price")}
                          classNamePrefix="react-select"
                          value={
                            FormValue.PutOnSaleType
                              ? FormValue.PutOnSaleType == "FixedPrice"
                                ? {
                                  label: FormValue.CoinName,
                                  value: FormValue.CoinName,
                                }
                                : currency.filter(
                                  (item) => item.value !== "BNB"
                                )[0]
                              : currency[0]
                          }
                          options={
                            FormValue.PutOnSaleType == "FixedPrice"
                              ? currency
                              : currency.filter((item) => item.value != "BNB")
                          }
                          id="CoinName"
                        />
                      </div>}



                  </div>
                  {(FormValue.PutOnSaleType == "FixedPrice" ||
                    FormValue.PutOnSaleType == "TimedAuction" ) &&
                    ValidateError.CoinName && (
                      <span className="error_msg mx-2">
                        {ValidateError.CoinName}
                      </span>
                    )}
                  {(FormValue.PutOnSaleType == "FixedPrice" ||
                    FormValue.PutOnSaleType == "TimedAuction" ) &&
                    ValidateError.NFTPrice && (
                      <span className="error_msg mx-2">
                        {ValidateError.NFTPrice}
                      </span>
                    )}


                  {<p className="buy_desc_sm pb-3">Current Price <span>{FormValue?.NFTPrice}</span></p>


                  }

                </>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{web3?.utils.fromWei(String(sellerFees))}% </p>
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will get {YouWillGet ?? 0}</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">

                      <span>{FormValue?.CoinName}</span>
                    </p>
                  </div>
                </div>

                <div className="text-center">
                  {FormValue?.status !== "Lazymint" ?
                    <Button
                      className="create_btn btn-block"
                      type="button"
                      onClick={BtnData == "start" ? FormSubmit : ""}

                    >
                      {BtnData == "start" && "Put on Sale"}
                      {BtnData == "error" && "Put on Sale"}
                      {BtnData == "process" && "processing"}
                      {BtnData == "done" && "Done"}
                    </Button> :
                    <Button
                      className="create_btn btn-block"
                      type="button"
                      onClick={BtnData == "start" ? signcall : ""}

                    >
                      {BtnData == "start" && "Put on Sale"}
                      {BtnData == "error" && "Put on Sale"}
                      {BtnData == "process" && "processing"}
                      {BtnData == "done" && "Done"}
                    </Button>}
                  <Button className="btn_outline_red btn-block create_btn "
                    disabled={BtnData == "process"}
                    data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>

      <div className="modal fade primary_modal" id="price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {BtnData == "open" && (
                  <>
                    <div className="media approve_media">


                      <div className="media-body">
                        <p className="mt-0 approve_text">Approve</p>
                        <p className="mt-0 approve_desc">Checking balance and approving</p>
                      </div>
                    </div>
                    <div className="text-center my-3">
                      <Button className={"primary_btn btn-block"}
                        disabled={
                          TokenBtn == "process" || TokenBtn == "done" ? true : false
                        }
                        onClick={
                          TokenBtn == "start" || TokenBtn == "try"
                            ? TokenApproveCall
                            : null
                        }
                      >
                        {TokenBtn == "start" && "Start"}
                        {TokenBtn == "process" && "In-Progress"}
                        {TokenBtn == "try" && "Try-Again"}
                        {TokenBtn == "done" && "Done"}

                      </Button>
                    </div>
                  </>
                )}
                <div className="media approve_media">

                  <div className="media-body">
                    <p className="mt-0 approve_text">Listing the NFT</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}

                    disabled={
                      Mintbtn == "process" || Mintbtn == "done" ? true : false
                    }
                    onClick={Mintbtn == "start" || Mintbtn == "try" ? ListCall : ""}
                  >
                    {Mintbtn == "start" && "Start"}
                    {Mintbtn == "process" && "In-Progress"}
                    {Mintbtn == "try" && "Try-Again"}
                    {Mintbtn == "done" && "Done"}
                    {Mintbtn == "init" && "Start"}
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  value={new Date()}
                />
              </div>
              <div className="text-center pb-3">
                <Button className="btn btn_blue" id="doneStartDate" >Done</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  value={new Date()}

                />
              </div>
            </div>
            <div className="text-center">
              <Button className="primary_btn" id="doneEndDate" >Done</Button>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
    </>
  )
})

