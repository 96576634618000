import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { getallcms } from '../actions/axioss/user.axios'
import { useParams } from "react-router-dom";
import config from '../views/config/config'
const useStyles = makeStyles(styles);


function ScrollToTopOnMount() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}





export default function Certificates(props) {
  console.log("hhhhghhhh", props);
  const classes = useStyles();
  const { ...rest } = props;
  const { data } = useParams()
  console.log("sdfgdgfdghfgh", rest, data)
  const [formData, setFormData] = useState()
  console.log("dgtrgyrth", formData);
  useEffect(() => {
    FetchCms()
  }, [])
  const FetchCms = async () => {
    try {
      var data = { question: 'certificate' }
      var Resp = await getallcms(data)
      console.log("repssspffffff", Resp);

      setFormData(Resp)
      //   setImage(Resp?.data.image)

    } catch (error) {
      console.log("errerr", error);
    }


  }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        // routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />

      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>

          <h1 class="title_text_white mb-3">Certificates</h1>

          <div className="row text-center">
          <div className="col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto mt-4">
          <p className="imagetitle text-center mb-3"><div dangerouslySetInnerHTML={{__html:formData?.[0]?.answer}}></div></p>
         </div>
          </div>

          <div className="row justify-content-center text-center">

            {/* {formData.length != 0 &&   */}
            {console.log("hduasghdhags", formData)}
            {formData && formData[0]?.image?.length > 0 && formData[0]?.image.map((data) => <div className="col-sm-10 col-md-9 col-lg-9 mx-auto mt-4">

              {console.log("sdopjuasoidh", data)}
              <a href={`${config.IMG_URL}/cmsimages/${data}`} target="_blank">
                <img src={`${config.IMG_URL}/cmsimages/${data}`} className="img-fluid multiimg" alt="images" />
              </a>
            </div>)
            }

          </div>

        </div>
      </div>


      <Footer />
    </div>

  );
}
