import React, {useState, useEffect, useCallback} from "react";
import { useSelector , useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button} from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ProfileImg from 'assets/images/no_profile2.png'
import '@metamask/legacy-web3';
import { toast } from 'react-toastify';

import action_config from './config/config';
import { isEmpty } from "../actions/common";
import { userRegister,getallkyc } from "../actions/axioss/user.axios";

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const dispatch = useDispatch()

const {push} =useHistory();
  const { token, payload, isAdmin } = useSelector(state => state.LoginReducer.User)

  

console.log("gdgdfgdfgdfg",token,payload);

  const initialValue = {
      DisplayName: payload?.DisplayName,
      EmailId: payload?.EmailId,
      Password: '',
      ConfirmPassword: '',
      Youtube: payload?.Youtube,
      Facebook:payload?.Facebook,
      Twitter: payload?.Twitter,
      Instagram: payload?.Instagram,
      Profile:payload?.Profile,
      WalletAddress: payload?.WalletAddress,
      // Profile:payload?.Profile ? action_config.BACK_URL + '/user/' + payload?.ProfileUrl + '/profile/' + payload?.Profile : '',
      Cover: payload?.Cover,
      Bio: payload?.Bio,
      CustomUrl:payload?.CustomUrl,
      Type: 'profile'
  }
  console.log('asgdgdsg',payload)
 
useEffect(()=>{
  setFormValue(initialValue)
  kycall()
},[payload])
// kycpage


const [formValue, setFormValue] = useState(initialValue)
const [ValidateError, SetValidateError] = useState('')
const [onchangeimg, setOnchangeimg] = useState('')
const [imageVal, setImageVal] = useState('')
const [disablebtn, setDisablebtn] = useState(0)
const [disable,setdisable] = useState(false)
const [kyclist, setkyclist] = useState([])
console.log("trtrtrtrt", kyclist);
console.log("onchangeimage",imageVal,formValue);
   const {
    DisplayName,
    WalletAddress,
    EmailId,
    Password,
    ConfirmPassword,
    Youtube,
    Facebook,
    Twitter,
    Instagram,
    Profile,
    Cover,
    Bio,
    CustomUrl,
    Type
} = formValue

console.log("walletAddress",WalletAddress,payload)

useEffect(()=>{
  if(kyclist?.verifiedStatus == "Approved"){
  //  setTimeout(() => {
    toast.success(`your kyc is already ${kyclist?.verifiedStatus}`)
  //  }, 1000);
    
    // window.location.reload()
    // push(`/profile/${formValue?.CustomUrl ?formValue?.CustomUrl :WalletAddress}`)
  }
  else{
    console.log("ddddddddddddddd");
    // push(`/profile/${formValue?.CustomUrl ?formValue?.CustomUrl :WalletAddress}`)
  }

},[])

useEffect(()=>{
  kycall()
},[])
useEffect(() => {
  window.scrollTo(0, 0);
  getProfile()

}, [payload?.Profile]);


const getProfile = async () => {
  console.log("adsda",Profile);
  if (payload?.Profile) {
 console.log('shdsh',payload?.Profile)
 var profileimage = action_config.IMG_URL + '/user/' + payload.WalletAddress + '/profile/' + payload.Profile;
 console.log("adsda",profileimage);
 setOnchangeimg(profileimage);
}
}



const kycall = async () => {
  console.log("wewererere",);
  var data = {
    WalletAddress: WalletAddress
  }
  var listed = await getallkyc(data)
  console.log("rerererer", listed?.data);
setkyclist(listed?.data)
}





const onChange = (e) => {
  setDisablebtn(0)
  const { value, id } = e.target
  if(id == 'CustomUrl')
      var formData = { ...formValue, ...{ ['CustomUrl']: value.toString().replace(/\s/g,'').toLowerCase() } } 
  else
      var formData = { ...formValue, ...{ [id]: value } }
  setFormValue(formData)
}

const Validation = useCallback((data) => {
  // console("validation Checking", data)
  console.log("profileloooo",data);
  console.log("ataffffffff",onchangeimg);
  // console.log("ataffffffff",data.Profile);



  let ValidateError = {}
  let imageSize = 5000000;

  if (onchangeimg != "") {
    // console.log("asswqaqsa",data.Profile);
      if (data.Profile != "") {
          if (imageSize < (data.Profile).size) {
              ValidateError.Profile = "File size must be below 5mb"
          }
          if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data?.Profile))) {
            
              ValidateError.Profile = "file is invalid. only allowed JPG,PNG,WEBP,gif";
          }
          
      }

  }
 
  if (!data.Profile) ValidateError.Profile = 'Profile Image Required'
  if (!data.DisplayName) ValidateError.DisplayName = 'DisplayName Required'
  if (!data.CustomUrl) ValidateError.CustomUrl = 'CustomUrl Required'
  if (data.CustomUrl && !(action_config.NumberOnly).test(data.CustomUrl)) ValidateError.CustomUrl = 'CustomUrl Must Be Allowed Only Numbers'

  if (!data.EmailId) ValidateError.EmailId = 'EmailId Required'
  if (data.EmailId && !(action_config.EMAIL).test(data.EmailId)) ValidateError.EmailId = 'Invalid Email Format'
  SetValidateError(ValidateError)
  return ValidateError
}, [ValidateError])
// const [ValidateError, SetValidateError] = useState('')
console.log("ValidateError",ValidateError);
   const FormSubmit = async () => {
       const id = toast.loading("Updating Progress..")
       let error = Validation(formValue)
       console.log('errsubbb',formValue,error,!isEmpty(error),'dfdsg',ValidateError)
       if (!isEmpty(error)) {
           setDisablebtn(1)
           SetValidateError(error)
           toast.update(id, { render:  'Validation Failed', type: 'error', isLoading: false,autoClose:1000,position:'top-center'})

       }
       else {
           console.log("form value",formValue)
       
           let Resp = await userRegister(formValue)
           console.log("Checking the Validation",Resp);
           if(Resp.success === 'success'){
               toast.update(id, { render: 'Saved successfully', type: 'suceess', isLoading: false,autoClose:1000,position:'top-center'})
               setDisablebtn(0)

           dispatch({
               type: 'Register_Section',
               Register_Section: {
                   User: {
                       payload	: 	Resp.data,
                       isAdmin	: 	Resp.data?.isAdmin ? Resp.data?.isAdmin == 'Yes' ? true : false : isAdmin,
                       token	:	Resp.token ? Resp.token : token
                   }
               }
           })
           if(Resp.data.CustomUrl ) document.cookie = 'user' + "=" + Resp.data.CustomUrl + ";" + ";path=/";
           push(`/my-item/${formValue.CustomUrl}`)
       }
       else{
           setDisablebtn(1)
           toast.update(id, { render:  'Validation Failed', type: 'error', isLoading: false,autoClose:1000,position:'top-center'})
           SetValidateError({...ValidateError,...Resp.validate})
       }
       }

   }

   const kycpage = async () =>{
    setdisable(true)
    if(kyclist?.verifiedStatus == "Approved"){

        toast.success(`your kyc is already ${kyclist?.verifiedStatus}`)

      // push(`/profile/${formValue?.CustomUrl}`)
    }
    else{
      push(`/kyc/${formValue?.CustomUrl}`)
    }
    
   }
   const handleFile = (event) => {
 
       setDisablebtn(0)
       event.preventDefault();
       // console.log('dsugfdsk',event)
       var reader = new FileReader()
      console.log("reader",reader);
       const { id, files } = event.target;
       console.log("profile id",id)
       if (event.target.files && event.target.files[0]) {
           var file = event.target.files[0];
           console.log("imgssss",file);
           setImageVal(file)
           var url = reader.readAsDataURL(file);
           reader.onloadend = function (e) {
               console.log('hiiiiiiii')
               if (reader.result) {
                   setOnchangeimg(reader.result);
               }
           }
       }
       let formData = { ...formValue, ...{ [id]: files[0] } };
       setFormValue(formData);
   }



  return (
    <div className="inner_header edit_profile">
     
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid"/></Link></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
      
            <div className="container container-lg">
        
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Edit Profile</h3>
              </div>
          
          </div>
   
        <div className="container container-lg mt-3 mt-md-5">
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <div className="holder mb-5">
                <div className="profile_edit_panel">
        
                    <img src={onchangeimg?onchangeimg:ProfileImg} alt="logo" id="imgPreview" className="img-fluid" />
                
               

                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input
                    
                    type="file" id="Profile" name="upload" onChange={(e) => handleFile(e)}
                    required="true"
                    className="photo"
                  
                   
                  />
                      {ValidateError && ValidateError.Profile && <span className="error_msg">{ValidateError.Profile}</span>}

                </div>
          

              </div>

            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Display Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      placeholder="Enter your display name"
                      id="DisplayName" value={DisplayName} onChange={onChange} required
                      autoComplete="off"
                    
                  />
                  {ValidateError && ValidateError.DisplayName && <span className="error_msg">{ValidateError.DisplayName}</span>}
                  

                  </div>
                  <div className="form-group col-md-6 editsdprofile">
                    <label className="primary_label" htmlFor="name">Custom Name</label>
                    <div className="input-group">

                      <div className="input-group-prepend">
                        <span className="input-group-text pl-0 min_h_45_px min_h_35" id="basic-addon2">{action_config?.FRONT_URL.slice(0,20).concat("...")}/profile/</span>

                      </div>
                      <input type="text"
                        className="primary_inp"
                        placeholder="Enter unique name only... EX.Nila,Ronaldo..."
                        id="CustomUrl" disabled="true"value={CustomUrl}  onChange={onChange} required 
                        autoComplete="off"

                       
                         />
                  {ValidateError && ValidateError.CustomUrl && <span className="error_msg">{ValidateError.CustomUrl}</span>}

                    </div>
                  </div>


                </div>

                <div className="form-row">

                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="EmailId" value={EmailId}  onChange={onChange} required
                      name="email"
                      autoComplete="off"
                   
                      placeholder="Your email for marketplace notifications" />
                     {ValidateError && ValidateError.EmailId && <span className="error_msg">{ValidateError.EmailId}</span>}
        

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Bio</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="Bio" value={Bio} onChange={onChange}   autoComplete="off"
                    placeholder="Tell about yourself in a few words" />
                  </div>
                </div>


                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Social Media Links</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 form_ino_che">
                    <input type="text"
                      className="form-control primary_inp"
                      autoComplete="off"
                      id="Twitter" value={Twitter} onChange={onChange}
                       placeholder="Twitter account address" />
{/* 
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        autoComplete="off"
                      />
                      <label className="custom-control-label" htmlFor="customCheck1"></label>
                    </div> */}
              

                  </div>
                  <div className="form-group col-md-6 form_ino_che">
                    <input type="text"
                      className="form-control primary_inp"
                      id="Youtube" value={Youtube}  onChange={onChange}
                      placeholder="Youtube channel address"
                      autoComplete="off"
                    />

                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                        autoComplete="off"
                      />
                      <label className="custom-control-label" htmlFor="customCheck2"></label>
                    </div> */}
                    

                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 form_ino_che">
                    <input type="text"
                      className="form-control primary_inp"
                      placeholder="Facebook account address"
                      id="Facebook" value={Facebook}  onChange={onChange}
                      autoComplete="off"
                    />
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox"
                        className="custom-control-input"
                        id="customCheck3"
                      />
                      <label className="custom-control-label" htmlFor="customCheck3"></label>
                    </div> */}

                  </div>
                  <div className="form-group col-md-6 form_ino_che">
                    <input type="text"
                      className="form-control primary_inp"
                      placeholder="Instagram address"
                      autoComplete="off"
                      id="Instagram" value={Instagram}  onChange={onChange}
                    
                    />
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox"
                        className="custom-control-input"
                        id="customCheck4"
                      />
                      <label className="custom-control-label" htmlFor="customCheck4"></label>
                    </div> */}

                  </div>
                </div>
                <div className="mt-3">
                    <Button className="create_btn" onClick={FormSubmit} >Update Profile</Button>
                </div>
                {/* <div className="mt-3">
                    <Button className="create_btn" disabled={disable} onClick={kycpage} ></Button>
                </div> */}
                <br/>
                 <Button className="create_btn" onClick={kycpage} >Kyc</Button>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
