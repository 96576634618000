import React, { useEffect, useState, useRef } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Token_List_Func } from "../../actions/axioss/nft.axios"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import bannerimg from "../../assets/img/banner_img.png"
import pfimg from "../../assets/img/profile.png"
import { Getpromotedtoken } from "../../actions/axioss/nft.axios"
import config from "../../views/config/config"
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { isEmpty } from "actions/common";
import collection from "../../assets/images/collection_02.png"
// import { AcceptBidRef } from "views/separate/AcceptBidRef";
export default function Badge(props) {
  const [filter, setFilter] = useState({ auction: "Recent", collection: "new", sale: "LatestDrops" });
    const [HotAuctionData, SetHotAuction] = useState({
        All: [],
        "tab": "All"
    });
    const [CreateCollectionState, SetCreateCollectionState] = useState({
        All: [],
        "tab": "All"
    });
    const [category, setCategory] = useState("All")
    const [TopCreator, SetTopCreator] = useState([])
    var [promotedToken, setPromotedtoken] = useState({});
    console.log("ssssssss",promotedToken);
    useEffect(() => {
        // Getandselldata();
        // Getandsellnft();
        // TopCreatorFunc()
        HotAuction(1, 'All')
        // HotSales('All')
        // Collectionlist('All')
        // CollectionByCreate('All')
        GetPromtion();
    }, [])
    const GetPromtion = async () => {
        // var resp = await Getpromotedtoken()
        var protoken = await Getpromotedtoken();
        console.log('DGJFGJFJD',protoken)
        if (protoken?.success == 'success') {
            setPromotedtoken(protoken?.data[0])
        };
    }
    
    let renderer = ({days, hours, minutes, seconds, completed }) => {
      console.log('dfjhkgfjhdhgfhg')
      // console.log("days, hours, minutes, seconds, completed",days, hours, minutes, seconds, completed);
      if (completed) {
        return <span>Auction Completed!</span>;
      } else {
        return (
          <span>
            {" "}
            <span className="hourds">{formatTime(days)} d</span>{" "}
            <span className="semicolan">:</span>{" "}
            <span className="hourds">{formatTime(hours)} h</span>{" "}
            <span className="semicolan">:</span>{" "}
            <span className="hourds">{formatTime(minutes)} m</span>{" "}
            <span className="semicolan">:</span>{" "}
            <span className="hourds">{formatTime(seconds)} s</span>{" "}
          </span>
        );
      }
    };
    const formatTime = (time) => {
      return String(time).padStart(2, '0')
    }


    useEffect(() => {
        // if(filter?.auction)
        if (HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter) {
            HotAuction('hi', HotAuctionData.tab)
        }

    }, [filter])

    console.log("HotAuctionData", HotAuctionData)
    const HotAuction = async (data, tabs) => {
        var SendDATA = {
            TabName: tabs,
            limit: 4,
            ProfileUrl: "",
            page: 1,
            from: "Auction",
            filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        console.log("rsesesedrded", Resp);
        if (Resp?.success == "success") {

            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction
                },
            })
        }
        else {
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                    "tab": tabs,
                    "filter": filter.auction

                },
            })
        }


    }
  return (
    <>
    {!isEmpty(promotedToken) ?
    <div className="card banner_card">
    {/* <img src={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.NFTOrginalImage}`} alt="banner_img" className="img-fluid banner_img" />: */}
    
      {/* console.log("sddadrrr",item); */}
      <Link  to={`/info/${promotedToken && promotedToken?.CollectionNetwork}/${promotedToken && promotedToken?.ContractAddress}/${promotedToken && promotedToken?.NFTOwner}/${promotedToken && promotedToken?.NFTId}`}  >
      
      <div className="card_img">
    <img src={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.NFTOrginalImage}`} alt="banner_img" className="img-fluid banner_img" />
    {
    ((new Date(promotedToken?.EndClockTime).getTime() >=
                          Date.now())?
    <div className="timer_count_sec">
          <div className="leftsec">
            <p className="mb-0"></p>
            <p className="mb-0">{promotedToken.CollectionNetwork}</p>
            <p className="mb-0"></p>
          </div>
          
          <div>
            {console.log('uidsgfoiksdgf',promotedToken)}
            {/* <p>Action Ends in</p> */}
            {promotedToken?.EndClockTime && <Countdown
            // date={"2023-03-24T18:30:00.000Z"}
            date={promotedToken?.EndClockTime}
             renderer={renderer}
             
                        
                          >
                          </Countdown>}
          </div> 

        </div>
        :
     <span className="timer_count_sec">Auction Ended</span>
                        )
            }
    </div>    
    </Link>  

 <p></p>
    <div className="follower_sec">
        <div className="imgsec">
            <div>
        <img src={`${config.IMG_URL}/nft/${promotedToken?.NFTCreator}/Original/NFT/${promotedToken?.NFTOrginalImage}`} alt="banner_img" className="img-fluid followers_img" />
        </div>
        <div>
        <p className="names">{promotedToken.Creator_DisplayName}</p>
        {/* <p className="count">896 Followers</p> */}
        </div>
      
        </div>
        <Link  to={`/info/${promotedToken && promotedToken?.CollectionNetwork}/${promotedToken && promotedToken?.ContractAddress}/${promotedToken && promotedToken?.NFTOwner}/${promotedToken && promotedToken?.NFTId}`}  >

        <div className="button create_btn">
        {/* <Link className="button create_btn" to={`/info/${promotedToken && promotedToken?.CollectionNetwork}/${promotedToken && promotedToken?.ContractAddress}/${promotedToken && promotedToken?.NFTOwner}/${promotedToken && promotedToken?.NFTId}`}  > */}
           
        <button className="button create_btn">Bid Now</button>  
        </div>
        </Link>  
    </div>
        
    </div>
    :
    <div className="card banner_card">
      {/* <img src={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.NFTOrginalImage}`} alt="banner_img" className="img-fluid banner_img" />: */}
      
        {/* //g("sddadrrr",item); */}
        
        <div className="card_img">
      <img src={collection} alt="banner_img" className="img-fluid banner_img" />
  
      <div className="timer_count_sec">
            <div className="leftsec">
              <p className="mb-0"></p>
              <p className="mb-0">BSC</p>
              <p className="mb-0"></p>
            </div>
            <div>
              {/* {//g('uidsgfoiksdgf',promotedToken,promotedToken?.EndClockTime)} */}
              {/* <p>Action Ends in</p> */}
             
              <Countdown
              date={"2023-12-24T18:30:00.000Z"}
              // date={promotedToken?.EndClockTime}
              //  renderer={renderer}
               
                          
                            >
                            </Countdown>
            </div> 
  
          </div>
      </div>    
  
  
   <p></p>
      <div className="follower_sec">
          <div className="imgsec">
              <div>
          <img src={pfimg} alt="banner_img" className="img-fluid followers_img" />
          </div>
          <div>
          <p className="names">Antony Macoy</p>
          <p className="count">896 Followers</p>
          </div>
        
          </div>
          <div>
          <button className="button create_btn">Bid Now</button>  
          </div>
  
      </div>
          
      </div>
    }
    </>
  );
}

