import { useSelector } from 'react-redux';
import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import config from '../views/config/config'
import Web3 from 'web3';
import Web3Utils from 'web3-utils';
import { NftbalanceUpdate } from './axioss/nft.axios';
import { network } from 'views/config/networkconfig';

var web3s = new Web3(config.RPC_URL)
// console.log("web3s@123",web3s);


export default function useContractProviderHook() {
    const { accountAddress, web3, web3p, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
    console.log("sellerFees", sellerFees);
    console.log("buyerFees", buyerFees);
    const toWeiFun = async (data) => {
        console.log("datatta", data);
        console.log("web3333333", Web3.utils.toWei(String(data)));
        return await Number(Web3.utils.toWei(String(data)))
    }
    const fromWeiFun = async (data) => { return await Number(Web3.utils.fromWei(String(data))) }

    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        console.log("aasas", ...data);
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data

            );
            return contract_value;
        }
    }

    const GetApproveStatus = async (data, Addr) => {
        try {
            let ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            let contract_Method_Hash = await
                ConnectContract
                    .methods
                    .isApprovedForAll(accountAddress, network[Wallet_Details?.currentChainId]?.tradeContract)
                    .call()
            console.log("datainGetApproveStatus", contract_Method_Hash, ConnectContract)
            return contract_Method_Hash

        }
        catch (e) {
            console.error("GetApproveStatus_error", e)
            return 'error'
        }
    }
    const SetApproveStatus = async (data, Addr) => {
        try {
            console.log("appprove", data, Addr);
            let ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            let contractobj = await
                ConnectContract
                    .methods
                    .setApprovalForAll(network[Wallet_Details?.currentChainId]?.tradeContract, true)
            let gasprice = await web3.eth.getGasPrice();

            let gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            console.log("gaspricegasprice", gasprice, accountAddress, gas_estimate);
            let contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(config.TradeContract, true)
                    .send({
                        from: accountAddress,
                        gasLimit: parseInt(gas_estimate),
                        gasPrice: gasprice,
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            let need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;




        }
        catch (e) {
            console.error("SetApproveStatus_error", e)
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        try {
            var receipt = await web3.eth.getTransactionReceipt(HashValue);
            // console.log("sdsadshadsadhfsa",receipt,HashValue)
            if (receipt) {
                return receipt
            }
            else {
                get_receipt(HashValue)
            }
        } catch (e) {
            console.log("errorrs", e);

        }

    }
    const minting_721_1155 = async (...data) => {
        console.log("Multipledata", data);
        try {
            const ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
            var contractobj = await
                ConnectContract
                    .methods
                    .minting(...data)
            var gasprice = await web3.eth.getGasPrice();
            console.log("gaspricegasprice", gasprice);
            var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            console.log("gas_estimate", gas_estimate);

            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .minting(...data)
                    .send({
                        from: accountAddress,
                        gasLimit: parseInt(gas_estimate),
                        gasPrice: gasprice,
                    })

                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            console.log("contract_Method_Hash", contract_Method_Hash);
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log("asdds", receipt, contract_Method_Hash.transactionHash);
            var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[1].topics[2])
            console.log('gettttttreccc', receipt, contract_Method_Hash, TokenCOunts)
            if (TokenCOunts) {
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    tokenCounts: TokenCOunts
                }
                return need_data
            }
        }
        catch (e) {
            console.log("Contract Error", e)
            return false
        }


    }
    const approve_721_1155 = async (token_address, ...data) => {
        console.log("contact isuue", token_address, ...data);
        try {
            const ConnectContract = await contrat_connection(DETH, token_address)
            var contractobj = await
                ConnectContract
                    .methods
                    .approve(...data)
            var gasprice = await web3.eth.getGasPrice();
            console.log("gaspricegasprice", gasprice);
            var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            console.log("gas_estimate", gas_estimate);
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({
                        from: accountAddress,
                        gasLimit: parseInt(gas_estimate),
                        gasPrice: gasprice,
                    })

                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("errorrrapprove_721_1155", e);
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(data).call();

            return Number(web3.utils.fromWei(String(bidAMt)))
        }
        catch (e) {

            return 0
        }
    }


    var buy_bid_price_calculation = (val, decimal) => {
        console.log("val", val, decimal)
        var toMid = val * 1000000
        var serfee = (toMid / 100000000) * (web3p.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        var totfee = serfee + toMid
        console.log("val,totfee", val, totfee)
        var tot2cont = web3.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        var aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
    const cancel_order_721_1155 = async (data) => {
        try {
            var ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
            var contractobj = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
            var gasprice = await web3.eth.getGasPrice();
            console.log("gaspricegasprice", gasprice);
            var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            console.log("gas_estimate", gas_estimate);
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
                    .send({
                        from: accountAddress,
                        gasLimit: parseInt(gas_estimate),
                        gasPrice: gasprice,


                    })

                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (e) {
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {
            var price = web3.utils.toWei(data);
            // var weii = price*1e6
            // var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/config.decimalvalues)*1000000)
            // var roy_per = (weii/100000000)*((roy)*1000000)
            // var mulWei = ((weii) - (ser_per+roy_per));
            // var getVal = Number(mulWei)/1e6;
            //console..log("you will get lower",price,weii,ser_per,roy_per,getVal);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            //console(data, getVal, sellerFees, my_val, my_val_royal)
            return getVal

        }
        catch (e) {
            return false
        }
    }

    const GetGasFees = async (encoded, contractaddress, value) => {
        try {
            var gasPrice = await web3.eth.getGasPrice();
            // console.log('skjfffffffssssss@124',chain,TradeAddress);
            var gasdata;
            if (value) {
                gasdata = await web3.eth.estimateGas({
                    from: accountAddress,
                    to: contractaddress,
                    value: value,
                    data: encoded,
                });
            }
            else {
                gasdata = await web3.eth.estimateGas({
                    from: accountAddress,
                    to: contractaddress,
                    data: encoded,
                });
            }

            console.log('hdgdgkdggd', gasPrice, gasdata)
            return ({ gasdata: gasdata, gasPrice: gasPrice });
        }
        catch (err) {
            console.log('errorrrss', err);
            return ({ gasdata: 282984, gasPrice: 5500000000 });
        }
    }


    const place_order_721_1155 = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
            let encoded = await
                ConnectContract.methods
                    .orderPlace(...data).encodeABI();
            var gasfeecalculated = await GetGasFees(encoded, network[Wallet_Details?.currentChainId]?.tradeContract);
            console.log("gasfeecalculatedsss", gasfeecalculated);
            var contract_Method_Hash = await
                ConnectContract.methods
                    .orderPlace(...data)
                    .send({
                        from: accountAddress,
                        gasLimit: parseInt(gasfeecalculated?.gasdata * 1.5, 10),
                        gasPrice: gasfeecalculated?.gasPrice,
                    })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("errrororrr", e);
            return false
        }

    }


    const buy_721_1155 = async (Send, CoinName, ...data) => {
        try {
            const ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
            console.log('ConnectContractConnectContract', Send, CoinName, data)
            if (CoinName === network[Wallet_Details?.currentChainId]?.currencySymbol) {
                let contractobj = await
                    ConnectContract
                        .methods
                        .saleToken(...data)
                let gasprice = await web3.eth.getGasPrice();

                let gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                console.log("gas_estimate", gas_estimate, gasprice);
                let contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleToken(...data)
                        .send({
                            from: accountAddress,
                            value: Send,
                            gasLimit: parseInt(gas_estimate),
                            gasPrice: gasprice,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                console.log("dddddddddd", CoinName, ...data);
                var contractobj = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                var gasprice = await web3.eth.getGasPrice();
                console.log("gaspricegasprice", gasprice);
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                console.log("gas_estimate", gas_estimate);
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                        .send({
                            from: accountAddress,
                            gasLimit: parseInt(gas_estimate),
                            gasPrice: gasprice,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("errrrrrrrrrrrrrr", e);
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {
        console.log("allowance_721_1155", data, network[Wallet_Details?.currentChainId]?.tradeContract);
        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, network[Wallet_Details?.currentChainId]?.tradeContract)
                    .call()
            return contract_Method_Hash

        }

        catch (e) {
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            console.log('dattttaaaa', data)
            if (web3 != null) {
                const ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
                var contractobj = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)

                var gasprice = await web3.eth.getGasPrice();
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({
                            from: accountAddress,
                            gasLimit: parseInt(gas_estimate),
                            gasPrice: gasprice,
                        })

                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }
        }
        catch (e) {

            return false
        }

    }


    const Trsanfer = async (ContractAddress, ContractType, Quantity, Add, Id) => {
        try {
            const ConnectContract = await contrat_connection((ContractType === "721" || ContractType === 721) ? ERC721 : ERC1155, ContractAddress)
            if (ContractType == "721" || ContractType == 721) {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .safeTransferFrom(accountAddress, Add, Id)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .safeTransferFrom(accountAddress, Add, Id, Quantity, [])
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            console.log("ekywyuryuewyruewrew accept", contract_Method_Hash)
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("ekywyuryuewyruewrew accept", e)

            return false
        }

    }

    const Burn = async (ContractAddress, ContractType, Quantity, Id) => {
        try {
            const ConnectContract = await contrat_connection((ContractType === "721" || ContractType === 721) ? ERC721 : ERC1155, ContractAddress)
            if (ContractType == "721" || ContractType == 721) {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .burnNFT(Id)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                console.log("burn data", Id, Quantity, accountAddress)

                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .burnNFT(Id, Quantity)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            console.log("ekywyuryuewyruewrew accept", contract_Method_Hash)
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("ekywyuryuewyruewrew accept", e)

            return false
        }

    }

    const Current_NFT_Balance = async (ownerdet, data) => {
console.log("nnnfffttt",ownerdet, data);
        // try {
        //     var currbalance;
        //     if ((data.ContractType === "721" || data.ContractType === 721)) {
        //         const ConnectContract = await contrat_connection(ERC721, data.ContractAddress)
        //         currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
        //         console.log("address balance test", currbalance, ownerdet.NFTOwner)
        //         if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return true; }
        //         else {
        //             let payload = {
        //                 NFTId: ownerdet.NFTId,
        //                 NFTOwner: ownerdet.NFTOwner,
        //                 NFTBalance: "0",
        //             }
        //             var balupd = await NftbalanceUpdate(payload);
        //         }
        //         console.log('ballllaanneceeee', currbalance)
        //     }
        //     else {
        //         const ConnectContract = await contrat_connection(ERC1155, data.ContractAddress)
        //         currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
        //         console.log('ballllaanneceeee', currbalance)
        //         if (currbalance !== null && currbalance !== undefined) {
        //             let payload = {
        //                 NFTId: ownerdet.NFTId,
        //                 NFTOwner: ownerdet.NFTOwner,
        //                 NFTBalance: currbalance,
        //             }
        //             let balupd = await NftbalanceUpdate(payload);
        //             console.log("check other balan 3", payload)


        //         }
        //         console.log('ballllaanneceeee', currbalance)
        //     }
        //     return String(currbalance);
        // }
        // catch (e) {
        //     console.log('errorrrrCurrent_NFT_Balance', e)
        //     return 0

        // }


        // kamesh_ 


        try {
            var currbalance;
            if (data?.ContractType === "721" || data?.ContractType === 721) {
                const ConnectContract = await contrat_connection(ERC721, data?.ContractAddress)

                currbalance = await ConnectContract.methods.ownerOf(ownerdet?.NFTId).call();
                // console.warn(currbalance)
                console.log("dataindsddasxxxx", ownerdet, ERC721, data?.ContractAddress, currbalance)

                if ((String(currbalance).toLowerCase()) == (String(ownerdet?.NFTOwner).toLowerCase())) {

                    return currbalance;
                }
                else {
                    let payload = {
                        NFTId: ownerdet?.NFTId,
                        NFTOwner: ownerdet?.NFTOwner,
                        NFTBalance: "0",
                        Currentowner: currbalance,
                        type: '721'
                    }

                    await NftbalanceUpdate(payload);


                    return String(currbalance);


                }

            }
            
            if (data?.ContractType === "1155" || data?.ContractType === 1155) {
                const ConnectContract = await contrat_connection(ERC1155, data.ContractAddress)
                currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
                console.log('ballllaanneceeee', currbalance)
                if ((currbalance !== null && currbalance !== undefined) && ownerdet.NFTBalance != currbalance) {
                    console.log("dataincontrac1155", ownerdet.NFTBalance, currbalance)
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: currbalance,
                        Currentowner: currbalance,
                        type: '1155'

                    }
                    console.log("datainbalancecheck1155", payload)
                    let result = await NftbalanceUpdate(payload);
                    console.log("checkotherbalan3ERC1155", result)
                    return String(currbalance);

                }
                console.log('ballllaanneceeee', currbalance)
                return String(currbalance);
            }

        }
        catch (e) {
            // toast.warning(e.message)
            console.error('balacecheckerroer', e)
            return ""
        }
    }


    const calculateAdminProfit = async (amount) => {
        console.log("amouinntntnt", amount);
        let buyingFee = await toWeiFun(amount) * await buyerFees / await toWeiFun(100)
        console.log("buyingFee", buyingFee);
        let sellingFee = await toWeiFun(amount) * await sellerFees / await toWeiFun(100)
        console.log("sellingFee", sellingFee);
        // var ServiceFees=Number (fromWeiFun(buyingFee))+Number(fromWeiFun(sellingFee))
        let TotalEarn = await fromWeiFun(buyingFee) + await fromWeiFun(sellingFee)
        console.log("TotalEarn", TotalEarn);

        let serFee = await fromWeiFun(buyerFees) + await fromWeiFun(sellerFees)
        console.log("Adminservicefee", serFee);
        let obj = { TotalEarns: TotalEarn, serviceFee: serFee }
        console.log("obj", obj);

        return obj
    }

    const lazyminting_721_1155 = async (value, Type, ...data) => {
        console.log("vxzjVXJvh", data, Type);
        console.log("vvalueh", value);
        if (String(Type) == '721') {
            console.log("lksmkmgoikgikaikogsdoikg");
            try {

                const ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
                var contractobj = await
                    ConnectContract
                        .methods
                        .lazyMinting(...data)
                // .send({ from: accountAddress, value })
                var gasprice = await web3.eth.getGasPrice();
                console.log("gaspricegasprice", gasprice);
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress, value })
                console.log("gas_estimate", gas_estimate);
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .lazyMinting(...data)
                        .send({
                            from: accountAddress,
                            value,
                            gasLimit: parseInt(gas_estimate),
                            gasPrice: gasprice,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })

                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                console.log("asdds", receipt);
                var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[0].topics[3])
                console.log("receipt.logs[1]", TokenCOunts);
                console.log('gettttttreccc', receipt, contract_Method_Hash, TokenCOunts)
                if (TokenCOunts) {
                    var need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        tokenCounts: TokenCOunts
                    }
                    return need_data
                }

            }

            catch (e) {
                console.log("Contract Error", e)
                return false
            }

        }
        else {
            try {
                console.log("dsvbadjhvbhddouble");
                const ConnectContract = await contrat_connection(Market, network[Wallet_Details?.currentChainId]?.tradeContract)
                var contractobj = await
                    ConnectContract
                        .methods
                        .lazyMinting(...data)
                var gasprice = await web3.eth.getGasPrice();
                console.log("gaspricegasprice", gasprice);
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress, value })
                console.log("gas_estimate", gas_estimate);
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .lazyMinting(...data)
                        .send({
                            from: accountAddress,
                            value,
                            gasLimit: parseInt(gas_estimate),
                            gasPrice: gasprice,
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })

                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                console.log("asdds", receipt, contract_Method_Hash.transactionHash);

                // var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[0].topics[3])
                //     console.log("receipt.logs[1]",TokenCOunts);
                //     console.log('gettttttreccc', receipt, contract_Method_Hash, TokenCOunts)
                if (receipt) {
                    console.log("data topics console", receipt?.logs[0].data);
                    // amount =  web3.utils.toBN(data.logs[0].data).toString()
                    var route = String(receipt.logs[0].data)
                    console.log("route", route);
                    var sliceee = route.slice(2)
                    console.log("sliceee", sliceee.length, sliceee);
                    let lengthuh = sliceee.length / 2
                    console.log("lengthuh", lengthuh);
                    console.log("sdfsf", web3.utils.hexToNumber("0x" + sliceee.slice(0, lengthuh)));
                    let need_data = {
                        status: receipt.status,
                        HashValue: receipt.transactionHash,
                        //     tokenCounts: TokenCOunts
                        tokenCounts: web3.utils.hexToNumber("0x" + sliceee.slice(0, lengthuh)),

                    }
                    return need_data
                }


            }
            catch (err) {
                console.log("lazyminting_1155", err);
            }
        }
    }

    contrat_connection
    return {
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        lazyminting_721_1155,
        allowance_721_1155,
        accept_721_1155,
        Trsanfer,
        Burn,
        Current_NFT_Balance,
        calculateAdminProfit,
    };




}
