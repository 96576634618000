import React,
{ useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";
// import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import TokenCard from "./separate/TokenCard"
import UserCard from './separate/UserCard'
import { useParams } from 'react-router-dom';
import { SearchAction } from "actions/axioss/nft.axios"





const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {


  const classes = useStyles();
  const { ...rest } = props;
  var LikeForwardRef = useRef();
  const history = useHistory();

  const [LikedTokenList, setLikedTokenList] = useState([]);
  function LikeList(data) {
    setLikedTokenList(data)
  }
  const { key } = useParams()

  const [noitems, setnoitems] = useState(false);

  const [KeyVal, SetKeyVal] = useState(key);
  const [TabName, SetTabName] = useState("Items");
  const [Tokens, SetTokens] = useState({
    'Items': { loader: true, page: 1, list: [] },
  });

  const Tabname = (newValue) => {
    SetTabName(newValue);
  };


  useEffect(() => {
    setnoitems(true)
    Explore('', '', key);
    console.log("Sda come here")
  }, [KeyVal, key]);


  useEffect(() => {
    if (typeof Tokens[TabName] == "undefined") {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      setnoitems(true)

      SetTokens(Tokens);
      Explore(1, TabName);
    }
  }, [TabName]);


  console.log("asdsadasdsa", TabName, Tokens)


  const Explore = async (data, tab, keys) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 1000,
      page: page ?? 1,
      from: "Explore",
      keyword: keys ? keys : key,
      from: 'Over All Search'
    };
    console.log("SendDATA searchpage", SendDATA)
    var Resp = await SearchAction(SendDATA);

    if (Resp) {
      setnoitems(false)

      console.log("Resp?.token?.data", Resp?.token?.data)
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list ?? [], ...(TabName === 'Items' ? Resp?.token?.data ?? [] : TabName === 'Users' ? Resp?.user?.msg ?? [] : TabName === 'Drops' ? Resp?.drop?.msg ?? [] : TabName === 'Artist' && Resp?.artist?.msg)],
            loader:
              TabName === 'Items' && Resp?.token?.data?.length === 0 ? false
                : TabName === 'Users' && Resp?.user?.msg.length === 0 ? false
                  : TabName === 'Drops' && Resp?.drop?.msg.length === 0 ? false
                    : TabName === 'Artist' && Resp?.artist?.msg.length === 0 ? false
                      : true,
            page: Tokens[TabName].page,
          },
        },
      });
    }
  };


  const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
  };







  return (
    <div className="inner_header search_pages">



      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>

        <div className="container-fluid container-theme mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav items_tab_outer text-center">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2 text-center" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="items-tab" data-toggle="tab" href="#items" role="tab" aria-controls="all" aria-selected="true" onClick={() => Tabname("Items")}>
                    <span className="tab_head p-2">Items</span>
                  </a>

                  <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="following" aria-selected="false" onClick={() => Tabname("Users")}>
                    <span className="tab_head p-2">Users</span>
                  </a>

                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">

                <div className="tab-pane fade show active" id="items" role="tabpanel" aria-labelledby="items-tab">
                  {noitems ?
                    <div className="text-center py-5 ">
                      <p className="not_found_text">Loading.</p>
                      <p className="not_found_text_sub">Please wait a moment</p>
                      <div className="mt-3">

                      </div>
                    </div> : <>
                      {Tokens["Items"]?.list.length > 0 ?



                        <div className="proposal_panel_overall">


                          <div className=" myitemcards masonry m-0 ma_no_gap">





                            <div className="row w-100">
                              {Tokens["Items"]?.list.map((item, index) => (
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">


                                  <TokenCard product={item}
                                    index={index}
                                    LikeList={LikeList}
                                    LikedTokenList={LikedTokenList}
                                    setLikedTokenList={setLikedTokenList}
                                    LikeForwardRef={LikeForwardRef} />
                                </div>


                              ))}
                            </div>




                          </div>



                        </div>

                        : <div className="text-center py-5">
                          <p className="not_found_text">No Items found</p>
                          <div className="mt-3">
                            <button className="create_btn" onClick={() => history.push("/explore/All")}>Browse Marketplace</button>
                          </div>
                        </div>}
                    </>
                  }

                </div>


                <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                  {noitems ?
                    <div className="text-center py-5 ">
                      <p className="not_found_text">Loading.</p>
                      <p className="not_found_text_sub">Please wait a moment</p>
                      <div className="mt-3">

                      </div>
                    </div> :
                    <>	{Tokens["Users"]?.list.length > 0 ?
                      <div className="proposal_panel_overall">


                        <div className="followers_overall mt-5">
                          <div className="row">
                            {Tokens["Users"]?.list.map((item, index) => (

                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <UserCard
                                  data={item}
                                />
                              </div>
                            ))}

                          </div>
                        </div>
                      </div> :
                      <div className="text-center py-5">
                        <p className="not_found_text">No Users found</p>
                        <div className="mt-3">
                          <button className="create_btn" onClick={() => history.push("/explore/All")}>Browse Marketplace</button>
                        </div>
                      </div>}
                    </>}
                </div>

              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}




