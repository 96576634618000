import React from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link,} from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// mysid
import TokenCard from '../views/separate/TokenCard'

import '@metamask/legacy-web3'
import { toast } from 'react-toastify';



toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Ico(props) {


  const { ...rest } = props;



  




 




  return (
 
    <div>

      <div className="home_header">

 
   

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 explore">

          <div className="items_bg mt-5">

            {
              <img src={require("../assets/images/banner.jpg")} className="img-fluid" alt="logo" />
            }
          </div>
          <div className="container-fluid container-theme container-lg ico pb-5">
            <div className="d-flex align-items-center">

              <div className="d-block w-100 ico_border image_title text-center">
                <div className="title m-0">

                  <div className="d-block responsinge">
                    <div className="image user_collect_img_tab">
                      {
                        <img src={require("../assets/images/Wen.png")} className="img-fluid" alt="logo" />
                      }
                    </div>
                    
                
                    <>
                    
                     
                    
                       
                       
                       <button className='btn newbtn'>
                       <span class="gradbtn grad-bordbtn">
                       
                         <Link className="">View Profile</Link>
                       
                       </span>
                     </button>
                      
                      </>
                      
                      <p></p>
                    
                    <div className="para ml-3">
                      
                        <p className="linear">collection name</p> 
                     
                      
                    </div>
                  </div>
                </div>
                <div className="no_of_nft ml-auto mt-xl-4 mt-lg-4 mt-3">
                  <p className="heading">No. of NFT'S:&emsp;0</p>
                  {/* <p className="linear"></p> */}
                </div>
              </div>
            </div>
            <div className="tab-content explore_tab_content mt-0 pt-5" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className=" m-0">
                    <div className="eploregrid">
                      {/* token card */}
                      
                       
                     
                              <div className=" mb-4">
                                {/* col-lg-3 col-md-6 col-sm-6 */}
                         
                                <TokenCard
                                 
                                />
                               
                              </div>
                           
                      
                    </div>
                </div>

              </div>

            </div>
            </div>
          </div>
          <div className="ico-button m-auto text-center pb-4 ico_button">
          
          </div>
          
              <div className="text-center mt-4 w-100 ">
          
                <div id="spinButs" className="overflow-hidden">
                  <Button className="create_btn create_newbtn mt-3 mx-auto btn-block" id="onmore"  style={{ display: "flex" }}>
                    Load More <span>
                  
                    </span>

                  </Button>
                </div>
              </div>
        </section>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  );
}
