import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link, useLocation, useNavigate, useHistory } from "react-router-dom";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import { useLocation, useHistory } from 'react-router-dom';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Bannercard from "components/Bannercard/Bannercard.js";
import { Token_List_Func, TopBuyerApi, TopSellerApi } from "../actions/axioss/nft.axios"
import { getuser } from "../actions/axioss/user.axios";
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { Category } from "../actions/axioss/nft.axios";
import { getcms } from '../actions/axioss/user.axios';
// import getcms from '../actions/axioss/'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from 'react-owl-carousel';
// import Dropdown from 'react-bootstrap/Dropdown';
import { getnft } from "../actions/axioss/nft.axios";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// mysid
import { address_showing } from '../actions/common';
import '@metamask/legacy-web3'

import scroll from "../assets/img/scroll.png"
import pfimg from "../assets/img/profile1.png"
import TokenCard from "./separate/TokenCard";
import { TopCreatorApi } from '../actions/axioss/nft.axios'
import config from "../views/config/config"
const dashboardRoutes = [];



export default function Home(props) {
  const { Param_category } = useParams()
  const { Categorys } = useSelector((state) => state.LoginReducer);
  console.log("category", Categorys);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const location = useLocation();
  const { push } = useHistory();
  const [TabName, SetTabName] = useState("All");

  const [cmsdata, setrest] = useState();

  const [state, set] = useState([])
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [loadingtext, setloadingtext] = useState(false);
  const [creatordata, setcreatordata] = useState();
  console.log("creatordata", creatordata);
  const [userdata, setuserdata] = useState()
  const [promoteddata, setpromoteddata] = useState()
  const [filters, setFiltershow] = useState("Top Creators");
  const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });

  const [filter, setFilter] = useState({ auction: "Recent", collection: "new", sale: "LatestDrops" });
  const [HotAuctionData, SetHotAuction] = useState({
    All: [],
    "tab": "All"
  });
  const [CreateCollectionState, SetCreateCollectionState] = useState({
    All: [],
    "tab": "All"
  });
  const [category, setCategory] = useState("All")
  var [promotedToken, setPromotedtoken] = useState({});
  const [Test, SetTest] = useState("true");
  const options = {
    dots: true,
    infinite: true,
    // autoplay:true,
    smartSpeed: 400,
    margin: 15,
    nav: false,
    speed: 1500,
    items: 1,

  }


  const options1 = {
    dots: true,
    infinite: true,
    // autoplay:true,
    smartSpeed: 400,
    margin: 15,
    nav: true,
    speed: 1500,
    items: 1,
    loop: false,

  }


  const { ...rest } = props;



  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $('header').addClass('fixedTop');
    } else {
      $('header').removeClass('fixedTop');
    }
  });


  const [TopCreator, SetTopCreator] = useState([])
  const [TopBuyer, SetTopBuyer] = useState([])
  useEffect(() => {

    TopCreatorFunc()
    // TopBuyerFunc()
  }, []);

  const TopCreatorFunc = async (data) => {
    if(data){setFiltershow(data)}
    console.log("asas", data);
    var resp = await TopCreatorApi()
    console.log('resssspppppp', resp)
    SetTopCreator(resp?.data)
  }
  const TopBuyerFunc = async (data) => {
    setFiltershow(data)
    var resp = await TopBuyerApi()
    console.log('restopbuyer', resp)
    SetTopCreator(resp?.data)
  }
  const TopSellerFunc = async (data) => {
    setFiltershow(data)
    var resp = await TopSellerApi()
    console.log('restopbuyer', resp)
    SetTopCreator(resp?.data)
  }
  useEffect(() => {
    console.log("path", location.pathname, location.pathname.split("/")[2], Categorys)

    if (location.pathname.split("/")[2]) {
      if (TabName == location.pathname.split("/")[2]) return
      else {
        setloadingtext(true)
        SetTabName(String(location.pathname.split("/")[2]))
      }
    }

  }, [location])






  console.log("userdata", userdata);
  console.log(creatordata, "NFTCreator......")


  const Tabnames = (newValue) => {
    SetTest("true")
    SetTabName(newValue);
  };
  useEffect(() => {
    setloadingtext(true)
    getuserdata();
    getCategory();
    Explore();
  }, []);

  useEffect(() => {
    console.log("called useeffect")
    SetTest("true")
    if (typeof Tokens[TabName] == "undefined") {
      console.log("called if")
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      setloadingtext(true)

      Explore(1, TabName);
    }
    else {
      console.log("called else")

      setloadingtext(false)
      SetTest('false')
    }


    console.log("tabname", TabName)
  }, [TabName]);

  const Explore = async (data, tab) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 32,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
    };
    console.log("Explore data to backend", SendDATA)
    let Resp = await Token_List_Func(SendDATA);
    // setarray(Resp.data)
    console.log("Check datad", Resp, SendDATA)



    if (Resp?.data?.length > 0) {
      var arr = [];
      Resp.data.map((item, idx) => {
        arr.push(item.NFTCreator)
        // console.log(arr, "NFTCreator......");
      })
      setcreatordata(arr)
      setloadingtext(false)

      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp?.data],
            // loader:
            //   Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
            //   Resp.data.length === 0
            //     ? false
            //     : true,
            loader: (Resp?.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
            page: Tokens[TabName].page,
          },
        },
      });
      // SetTokens({
      //   ...Tokens,
      //   ...{
      //     [TabName]: {
      //       list: [...Tokens[TabName].list, ...Resp?.data],
      //       // loader:
      //       //   Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
      //       //   Resp.data.length === 0
      //       //     ? false
      //       //     : true,
      //       loader: (Resp?.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
      //       page: Tokens[TabName].page,
      //     },
      //   },
      // });
      SetTest("false")
    } else {
      setloadingtext(false)

      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
          },
        },
      });
      SetTest('false')
    }
  };

  function LikeList(data) {
    setLikedTokenList(data)
  }

  const getuserdata = async () => {
    var resp = await getuser();
    console.log("sssddeee", resp);
    setuserdata(resp)

  }

  const getCategory = async () => {
    var resp = await Category();
    console.log("sfdddddddddd", resp);
    set(resp?.data)
  }

  useEffect(() => {

    HotAuction(1, 'All')
    GetPromtion();
  }, [])
  const GetPromtion = async () => {

    var protoken = await Getpromotedtoken();
    console.log("promoted",protoken);
    if (protoken?.success == 'success') {
      setPromotedtoken(protoken?.data[0])
    };
  }

  useEffect(() => {

    if (HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter) {
      HotAuction('hi', HotAuctionData.tab)
    }

  }, [filter])

  console.log("HotAuctionData", HotAuctionData)
  const HotAuction = async (data, tabs) => {
    var SendDATA = {
      TabName: tabs,
      limit: 4,
      ProfileUrl: "",
      page: 1,
      from: "Auction",
      filter: filter.auction,
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log("rsesesedrded", Resp);
    if (Resp?.success == "success") {

      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          "tab": tabs,
          "filter": filter.auction
        },
      })
    }
    else {
      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          "tab": tabs,
          "filter": filter.auction

        },
      })
    }


  }

  useEffect(() => {

    getcmslistdata();
  }, [])



  const getcmslistdata = async () => {
    console.log("sadsada");
    let Resp = await getcms();
    console.log("contant", Resp);
    setrest(Resp)
  }



  return (

    <div>
      <div className="home_header homepage bidex_page">
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo "><Link to="/">
              <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}

          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        {
          <div className="topbannersec">
            <div className="container custom_container">

              <div className="row">

                <div className="col-lg-6 leftsec">
                  <p className="first_head">Together We Are</p>
                  <p className="sec_head">Making a</p>
                  <p className="third_head">Difference</p>
                  {cmsdata?.map((item, idx) => {
                    console.log("itemhome", item);
                    return (
                      <>
                        {
                          item.question == "Home page_top" ?
                            <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p> : <></>

                        }
                      </>
                    )
                  })}
                  <div className="btnsec mt-5">
                    <button className="button create_btn" onClick={() => { push("/explore") }}>Explore</button>
                    <button className="button create_btn_white " onClick={() => { push("/create") }}>Create</button>
                  </div>
                </div>
                <div className="col-lg-6 rightsec">

                  <div className="cardsec">
                    <Bannercard />
                  </div>
                  <div className="usersec">
                    <div>
                      {console.log(Tokens?.All?.list, "dsaddasdasd")}
                      <p className="counts">{Tokens?.All?.list.length}</p>
                      <p className="counts_name">Artworks</p>
                    </div>

                    <div>
                      <p className="counts">{creatordata?.length}</p>
                      <p className="counts_name">Artist</p>
                    </div>
                    <div>
                      <p className="counts">{userdata?.data?.length}</p>
                      <p className="counts_name">Users</p>
                    </div>
                    <div className="scroll_img">
                      <p className="counts_name">Scrolldown</p>
                      <img src={scroll} alt="Scroll" className="img-fluid" />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        }
        <div className="container custom_container">
          {HotAuctionData?.All?.length > 0 ?
            <div className="bannerdown_card_sec">
              <div className="row row_2_order">
                <div className="col-lg-9">
                  <OwlCarousel className='owl-theme' loop margin={10} nav {...options1}>
                    <div class='item'>
                      <div className="row">
                        {HotAuctionData?.All?.length > 0 ? HotAuctionData?.All?.slice(0, 3).map((val, index) => {

                          return (
                            <div className="col-sm-6 col-md-4 mb-4">
                              <TokenCard
                                product={val}


                              />
                            </div>

                          )



                        }) :
                          <></>}

                      </div>
                    </div>
                    {/* {HotAuctionData?.All?.length>8 */}
                    {HotAuctionData?.All?.length > 3 ?
                      <div class='item'>
                        <div className="row">
                          {HotAuctionData?.All?.slice(3, 6).map((val, index) => {
                            return (
                              <div className="col-sm-6 col-md-4 mb-4">
                                <TokenCard
                                  product={val} />
                              </div>

                            )
                          })}

                        </div>
                      </div> : <></>}
                    {HotAuctionData?.All?.length > 6 ?
                      <div class='item'>
                        <div className="row">
                          {HotAuctionData?.All?.slice(6, 9).map((val, index) => {
                            console.log("assasasas", val);
                            return (
                              <div className="col-sm-6 col-md-4 mb-4">
                                <TokenCard
                                  product={val} />
                              </div>

                            )



                          })}
                        </div>
                      </div> : <></>}
                    {HotAuctionData?.All?.length > 9 ?
                      <div class='item'>
                        <div className="row">
                          {HotAuctionData?.All?.slice(9, 12).map((val, index) => {
                            return (
                              <div className="col-sm-6 col-md-4 mb-4">
                                <TokenCard
                                  product={val}
                                />
                              </div>

                            )



                          })}

                        </div>
                      </div> : <></>}
                  </OwlCarousel>
                </div>
                <div className="col-lg-3 rightsec">
                  <p className="head_title">Live Auctions</p>
                  {cmsdata?.map((item, idx) => {
                    return (
                      <p className="head_desc">{item.question == "Home page_top" ? <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p> : <></>}</p>)
                  })}

                </div>
              </div>



            </div> : <></>}

            {TopCreator?.length > 0 &&
          <div className="creators_sec">
            <div className="row">
              {console.log("vghx", filters)}
              <div class="dropdown trans_drop">
                <Link class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" >
                  <span>{filters}</span>
                </Link>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" onClick={() => { TopCreatorFunc("Top Creators") }} >Top Creators</a>
                  <a class="dropdown-item" onClick={() => { TopBuyerFunc("Top Buyers") }} >Top Buyers</a>
                  <a class="dropdown-item" onClick={() => { TopSellerFunc("Top Sellers") }}>Top Sellers</a>
                </div>
              </div>
            </div>

            <div className="cardsec">

              <div className="row">



                {TopCreator?.length > 0 ?
                  TopCreator?.map((item, index) => {
                    return (
                      <>
                        <div className="col-sm-6  col-lg-4 col-xl-3 mb-4">

                          <div className="creators_card">
                            <div className="imgsec">
                              <NavLink to={"/my-item/" + item.WalletAddress} className="names">
                                <div>

                                  {item?.Profile ? <img src={
                                    item?.Profile
                                      ? `${config.IMG_URL}/user/${item?.WalletAddress}/profile/${item?.Profile}`
                                      : <></>
                                  } alt="banner_img" className="img-fluid creators_card_img" /> : <img src={pfimg}></img>}
                                </div>
                              </NavLink>
                              <div>
                                <NavLink to={"/my-item/" + item.CustomUrl} className="names">{item?.DisplayName
                                  ? item?.DisplayName
                                  : address_showing(
                                    item?.WalletAddress
                                  )}</NavLink>
                                <p className="count"></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }) : <p className="nodata_card">No Data</p>
                }




              </div>





            </div>




          </div> }

          <div className="collectionsec">
            <p className="title_head">Explore</p>

            <div className="tabsec">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" class1={TabName == "All" ? "active" : ""} onClick={() => Tabnames("All")}>All</a>
                </li>
                {state?.map((item, index) => {
                  // console.log()
                  return (
                    <li class="nav-item">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false" class2={TabName == `${item.name}` ? "active" : ""} onClick={() => Tabnames(`${item.name}`)}>{item.name}</a>
                    </li>
                  )
                })}
              </ul>

              <div class="tab-content" id="pills-tabContent">
                {console.log("dataitem", TabName, Tokens[TabName]?.list)}
                <div class="tab-pane fade show active" id="All" role="tabpanel" aria-labelledby="pills-home-tab">
                  <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>

                    {Tokens[TabName]?.list.length > 0 ?
                      <div class='item' >
                        <div className="row">

                          {console.log("dataitem111", TabName)}



                          {Tokens[TabName] && Tokens[TabName]?.list.slice(0, 8).map((val, index) => {

                            return (
                              val.Category &&
                              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">

                                <TokenCard

                                  product={val}
                                  index={index}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef} />
                              </div>
                            )



                          })}
                        </div>
                      </div> : <p className="nodata_card">No Data</p>}

                    {/* {Tokens[TabName]?.list.length>5 &&
    <>     
    <div class='item'>
    <div className="row">
    <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
    </div>
    </div>
    </div>
   
    </>
}  */}


                    {Tokens[TabName]?.list.length > 8 ?
                      // <div class='item'>
                      <div class='item'>
                        <div className="row">
                          {Tokens[TabName]?.list.slice(8, 16).map((val, index) => {
                            return (
                              val.Category &&
                              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                                <TokenCard product={val}
                                  index={index}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef} />

                              </div>
                            )



                          })}
                        </div>
                      </div>
                      // </div>
                      : <></>}

                    {Tokens[TabName]?.list.length > 16 ?
                      // <div class='item'>
                      <div class='item'>
                        <div className="row">
                          {Tokens[TabName]?.list.slice(16, 24).map((val, index) => {
                            return (
                              val.Category &&
                              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                                <TokenCard
                                  product={val}
                                  index={index}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef} />

                              </div>
                            )



                          })}
                        </div>
                      </div>
                      // </div>
                      : <></>}

                    {Tokens[TabName]?.list.length > 24 ?
                      // <div class='item'>
                      <div class='item' >
                        <div className="row">
                          {Tokens[TabName]?.list.slice(24, 32).map((val, index) => {
                            return (
                              val.Category &&
                              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                                <TokenCard
                                  product={val}
                                  index={index}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef} />
                              </div>
                            )



                          })}
                        </div>
                      </div>
                      // </div>
                      : <></>}


                  </OwlCarousel>
                </div>
              </div>
            </div>

          </div>


          {/* <div className="collectionsec">
  <p className="title_head">Recently Added NFTs</p>

 
  <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
    <div class='item'>
    <div className="row">
    {[...Array(8)].map((val,index)=>{
     return(
        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
        <TokenCard/>
      </div>
     )

      
     
    })}
    </div>
    </div>
    <div class='item'>
    <div className="row">
    {[...Array(8)].map((val,index)=>{
     return(
        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
        <TokenCard/>
      </div>
     )

      
     
    })}
    </div>
    </div>
    <div class='item'>
    <div className="row">
    {[...Array(8)].map((val,index)=>{
     return(
        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
        <TokenCard/>
      </div>
     )

      
     
    })}
    </div>
    </div>
    <div class='item'>
    <div className="row">
    {[...Array(8)].map((val,index)=>{
     return(
        <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
        <TokenCard
        />
      </div>
     )

      
     
    })}
    </div>
    </div>
   
 
</OwlCarousel>
   
    </div> */}



        </div>







        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  )
}
