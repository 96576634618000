import React, {
    forwardRef, useImperativeHandle, useMemo, useEffect, useState
} from 'react'
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery"
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../../actions/common";
import config from '../config/config'
import { BuyAccept } from "../../actions/axioss/nft.axios";


import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
    nftNameValidation,
    NFTImageUpload,
    CreateNFT,
} from "../../actions/axioss/nft.axios";
import { Gettoken, updatetoken, getAllChain } from "../../actions/axioss/nft.axios"
import { checkChain } from 'views/hooks/useWallet';
import { network } from '../config/networkconfig'
toast.configure();



export const PurchaseNowRef = forwardRef((props, ref) => {

    const { currency } = useSelector(state => state.LoginReducer)
    const { web3, web3p, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    console.log("fafafafffggh", accountAddress);
    const { buyerFees, sellerFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
    const ContractCall = useContractProviderHook()
    const history = useHistory()
    const dispatch = useDispatch();
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [owner, SetOwner] = useState("");
    const [item, setItem] = useState();
    const [lazy, setlazy] = useState({})
    const [Btn, SetBtn] = useState('start')
    console.log("bbtttnn",Btn);
    const [App_Btn, SetApp_Btn] = useState(owner?.CoinName != network[Wallet_Details.currentChainId]?.currencySymbol ? 'init' : "start")
    const [Error, SetError] = useState('')
    const [NFTQuantity, SetNFTQuantity] = useState(1)
    const [TokenBalance, SetTokenBalance] = useState('0')
    const [show10, setShow10] = useState(false);
    const [proceedtopayment, setProceedtopayment] = useState(false);
    const [cointype,SetCoinType] = useState('')
    const decimal = currency?.filter(item => item.label === owner?.CoinName)?.pop()?.decimal ?? 18
    console.log('aaaaaaabbbb', buyerFees, currency, owner?.CoinName, currency?.filter(item => item.label === owner?.CoinName))
    const token_address = currency?.filter(item => item.label === owner?.CoinName)?.pop()?.address ?? config.DEADADDRESS
    console.log("token_address", token_address);


    console.log("ASDAD", lazy);
    const { CoinName } = owner

    // useEffect(() => {
    //     if (CoinName) {
    //         SetApp_Btn(owner?.CoinName != config.COIN_NAME ? 'init' : "start");
    //     }
    // }, [CoinName])
    useEffect(()=>{
        if(owner?.CoinName && currency?.length>0){
            let address = currency?.filter(item => item.label === owner?.CoinName)?.pop()?.address;
            console.log('sgkhfskg',address,currency,owner)
            SetCoinType(address?.includes('0x00000000000') ? 'coin' : 'token')
            SetApp_Btn(address?.includes('0x00000000000') ? 'start' : 'init')
        }
    },[owner,currency])


    useEffect(() => {
        gettoken();
    }, [owner])

    const YouWillGet = useMemo(() => {
        if (owner?.NFTPrice) {

            return ContractCall?.buy_bid_price_calculation(
                (owner?.NFTPrice * NFTQuantity).toString(), decimal.toString())
        }
    },
        [owner?.NFTPrice, NFTQuantity])
        console.log("YouWillGet",YouWillGet);
    var chainid = null;
    {

        useImperativeHandle(
            ref,
            () => ({
                async Purchase_click(data) {
                    var { owner, item } = data;
                    console.log("hdvskjhavdk", data);
                    chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
                    var currentChainId = await web3.eth.getChainId();
                    console.log('jhgkfjgkjfkh', chainid, currentChainId, item)
                    if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
                        // console.log("Nftbalance on buynow", Nftbalance);
                        if (item.status == "Lazymint") {
                            setItem(item)
                            SetOwner(owner)
                            window.$("#PurchaseNow_modal").modal("show")
                        }
                        else {
                        let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)

                            // if (Nftbalance != owner.NFTBalance) {
                                if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
                                $('#closePruchase').trigger("click");
                                window.$("#PurchaseNow_modal").modal("hide")
                                toast.warning("You won't buy at this moment please refresh you data");

                                setTimeout(() => {
                                    history.push("/")
                                }, 1000);
                            } else {
                                console.log("ownerttypes item", owner, item)
                                setItem(item)
                                SetOwner(owner)
                                window.$("#PurchaseNow_modal").modal("show")
                            }


                        }

                    } else {
                        var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
                        var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
console.log('chainnnnnnn',proceed,sendchain,chainid)

                        if (proceed) {
                            var Allnetwork = await getAllChain()
                            console.log("sdfsdjgnfjdgnkegv",Allnetwork,Number(chainid));
                            var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
                            var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
                            console.log("sdadasdasdas", curr[0]);
                            if (Allnetwork?.status) {
                                proceed.chainList = Allnetwork?.data;
                            }
                            toast.success(network[chainid]?.chain + " Network Switched Successfully", { autoClose: 1000 })
                            dispatch({
                                type: "Account_Section",
                                Account_Section: { AccountDetails: proceed }
                            })
                            dispatch({
                                type: "Register_Section",
                                Register_Section: {
                                    currency: FinalCurrency,

                                },
                            });
                        }
                        else {
                            toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
                            window.$("#PurchaseNow_modal").modal("hide")
                        }
                    }

                }
            }),
        )

    }

    const gettoken = async () => {
        console.log("accountAddress", accountAddress);
        var Resptoken = await Gettoken({ NFTId: owner.NFTId });
        console.log("thththt", Resptoken);
        setlazy(Resptoken.data[0])
    }

    console.log("jhbibdifbi", lazy);
    const Validation = async () => {
        var error = {};
        if (isEmpty(NFTQuantity)) return "Token Quantity Required"
        else if (owner.NFTBalance < NFTQuantity) return error.NFTQuantity = "NFT Quantity should be less than " + owner.NFTBalance
        if (owner.CoinName != 'ETH' && ((owner.TokenPrice * NFTQuantity) > TokenBalance)) return "Insufficient Balance"
        else return await ContractCall.Contract_Base_Validation()
    }

    const FormSubmit = async () => {
        console.log("dbjkbksdjfbdsjkbfjkdbs");
        console.log("ifconsolesecond asdqew e", token_address);
        const id = toast.loading('Purchasing Token on processing')
        SetError('')
        SetBtn('process')
        var error = await Validation()
        if (error) {
            console.log("ifconsloefirst");
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
            SetBtn('error')
            SetError(error)
        }
        else {
            console.log("ifconsolesecond", token_address, network[Wallet_Details?.currentChainId]?.tradeContract, web3p?.utils.toWei(YouWillGet.toString()));
            let cont = await ContractCall.approve_721_1155(token_address, network[Wallet_Details?.currentChainId]?.tradeContract, web3p?.utils.toWei(YouWillGet.toString()))
            console.log("contss",cont);
            if (cont) {
                console.log("ifconsolesecond");
                toast.update(id, { render: 'Purchasing Token Successfully', type: 'success', isLoading: false, autoClose: 1000 })
                SetBtn('done')
                SetApp_Btn('start')
            }
            else {
                console.log("forth else");
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                SetBtn('try')
            }

        }
    }

    useEffect(() => {
        (async () => {
            const TokenBalance = await ContractCall.Token_Balance_Calculation(token_address, accountAddress)
            console.log('tokkkeeeenballl', TokenBalance, token_address)
            SetTokenBalance(TokenBalance ? TokenBalance : 0)
        })()
    }, [TokenBalance, owner?.CoinName])


    console.log("ownerpurchase", owner);
    console.log("fffffffffff",item?.ContractAddress);
    const _Buy = async () => {
        SetApp_Btn('process')
        // console.log('ghgdhdg',NFTQugetServiceFeesantity)
        const id = toast.loading('Purchasing Token on processing')
        var error = await Validation();
        console.log('errrrrrrrrr', error);
        SetError(error)
        if (isEmpty(error)) {
            console.log("asdsdadqewqeqwe",web3p?.utils.toWei(YouWillGet.toString()), cointype == 'coin' ? network[Wallet_Details?.currentChainId]?.currencySymbol : owner.CoinName, owner.NFTOwner, [owner.NFTId, web3p?.utils.toWei(String(owner.NFTPrice * NFTQuantity)), NFTQuantity, item.ContractType], item.ContractAddress);
            let cont = await ContractCall.buy_721_1155(web3p?.utils.toWei(YouWillGet.toString()), cointype == 'coin' ? network[Wallet_Details?.currentChainId]?.currencySymbol : owner.CoinName, owner.NFTOwner, [owner.NFTId, web3p?.utils.toWei(String(owner.NFTPrice * NFTQuantity)), NFTQuantity, item.ContractType], item.ContractAddress)
            if (cont) {

                let adminProfit = await ContractCall.calculateAdminProfit(owner.NFTPrice)
                console.log("adminProfit", adminProfit);

                let newOwner = {
                    HashValue: cont.HashValue,
                    NewTokenOwner: accountAddress,
                    NFTQuantity: NFTQuantity,
                    NFTId: owner.NFTId,
                    NFTOwner: owner.NFTOwner,
                    PutOnSale: owner.PutOnSale,
                    PutOnSaleType: owner.PutOnSaleType,
                    activity: "Buy",
                    TP: owner.NFTPrice,
                    CN: owner.CoinName,
                    click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
                    ADMINPROFIT: adminProfit.TotalEarns,
                    Adminservicefee: adminProfit.serviceFee,
                    Network: item.CollectionNetwork
                }
                let Resp = await BuyAccept({ newOwner: newOwner, item: item })
                console.log("Resp", Resp);
                if (Resp.success == 'success') {
                    toast.update(id, { render: 'Approving Token Successfully', type: 'success', isLoading: false, autoClose: 1000 })
                    SetApp_Btn('done')
                    window.$("#PurchaseNow_modal").modal("hide")
                    history.push('/my-item/' + payload.CustomUrl);
                }
                else {
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                    SetApp_Btn('try')
                }
            }
            else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                SetApp_Btn('try')
            }
        }
        else {
            toast.update(id, { render: 'Validation failed', type: 'error', isLoading: false, autoClose: 1000 })
        }
    }

    const onChange = (e) => {
        console.log('vallll', e.target.value)
        SetNFTQuantity(e.target.value)
    }
    console.log("lazyjddjdj", lazy);

    // console.log("multiple lazymintsdfdsjfnsdjnfsdvn nsfvj ndcknzdjncdk", [config.IPFS + String(data.ipfsimage), "Status", "lazy", RandomName,"other"], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash, nounce, RandomName, SignatureHash)
    // await Contract
    //     .methods
    //     .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName,"other"], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
    //     .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
    //     .on('transactionHash', async (transactionHash) => {
    //         Hash = transactionHash
    //     })

    async function LazyMintCall() {
        SetApp_Btn('process')
        const id = toast.loading("LazyMinting Processing");
        console.log("Minting Process", web3)
        const _data = lazy;
        const value = owner.CoinName == "BNB" ? web3.utils.toWei(String(YouWillGet)) : 0
        const _amount = owner.CoinName == "BNB" ? owner.NFTPrice == "" || owner.NFTPrice == undefined ? 0 : web3.utils.toWei(String(owner.NFTPrice)) : 0
        const CoinName = owner.CoinName == "BNB" ? "ETH" : "CAKE"
        _data.activity = "Mint";

        try {
            console.log("jhdiufhewijwei", lazy)
            const cont = await ContractCall.lazyminting_721_1155(
                value, lazy.ContractType,
                [config.IPFS + String(lazy.MetaData), "Status", "lazy", lazy.Randomname],
                [lazy.NFTCreator, accountAddress, lazy.ContractAddress],
                [
                    (NFTQuantity ? NFTQuantity : owner?.NFTBalance).toString(),

                    lazy.ContractType,
                    web3?.utils.toWei(lazy.NFTRoyalty),
                    web3.utils.toWei(
                        (owner.PutOnSaleType == "FixedPrice"
                            ? (owner.NFTPrice * NFTQuantity)
                            : "0"
                        ).toString()
                    ),
                    lazy.NFTQuantity,
                    lazy._nonce,
                    web3.utils.toWei(
                        (owner.PutOnSaleType == "FixedPrice"
                            ? owner.NFTPrice
                            : "0"
                        ).toString()
                    ),
                    // 0
                ],
                CoinName,
                lazy.signature
            );

            console.log("sdjhbjhb", cont);
            if (cont) {
                console.log("createlazy", cont);
                var HashValue = cont.HashValue;
                var NFTId = cont?.tokenCounts?.toString();
                var click = `${config.FRONT_URL}/info/${lazy.CollectionNetwork
                    }/${lazy.ContractAddress
                    }/${accountAddress}/${cont?.tokenCounts?.toString()}`;
                // console.log("Ngcvda",NFTId);
                var status = "Minted"
                console.log("final data after mintin", _data)
                item.NFTId = NFTId;
                var Resptoken = await updatetoken({ NFTIdeee: lazy.NFTId, HashValue: HashValue, NFTId: NFTId, click: click, status: status });
                console.log("teaaea", Resptoken);
                let adminProfit = await ContractCall.calculateAdminProfit(owner.NFTPrice)
                console.log("adminProfit", adminProfit);
                let newOwner = {
                    HashValue: cont.HashValue,
                    NewTokenOwner: accountAddress,
                    NFTQuantity: NFTQuantity,
                    NFTId: NFTId,
                    NFTOwner: owner.NFTOwner,
                    PutOnSale: owner.PutOnSale,
                    PutOnSaleType: owner.PutOnSaleType,
                    activity: "Buy",
                    TP: owner.NFTPrice,
                    CN: owner.CoinName,
                    click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
                    ADMINPROFIT: adminProfit.TotalEarns,
                    Adminservicefee: adminProfit.serviceFee,
                    Network: item.CollectionNetwork
                }
                let Resp = await BuyAccept({ newOwner: newOwner, item: item })

                console.log("LazyMintCall", Resp);
                if (Resp) {
                    toast.update(id, {
                        render: "Successfully Minted",
                        type: Resp.data,
                        isLoading: false,
                        autoClose: 1000,
                    });
                    history.push("/explore/All");

                }
                //   setMintButton("done");
                //   window.$("#closemint").click()
                //   window.$("#create_item_modal").modal("hide");
                //   history.push("/explore/All");
                //   // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
                // } 
                else {
                    toast.update(id, {
                        render: "Transaction Failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 1000,
                    });
                    // setMintButton("try");
                }
            }

            else {
                toast.update(id, {
                    render: "Transaction Failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 1000,
                });
                //   setMintButton("try");
            }

        } catch (err) {
            console.log("comntaract", err);
        }
    }






    return (
        <div>
            <div className="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="buy_modalLabel">Checkout</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closePruchase"

                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <div className="row mx-0">
                                <div className="col-12 col-md-3 px-4">
                                    <p className="buy_title_sm">Seller</p>
                                </div>
                                <div className="col-12 col-md-9 px-4">
                                    <p className="buy_title_md text-md-right word_brak_text">
                                        <span  >{owner?.DisplayName ? owner?.DisplayName : String(owner?.NFTOwner).slice(0, 6).concat("..")}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-12 col-md-3 px-4">
                                    <p className="buy_title_sm" >Buyer</p>
                                </div>
                                <div className="col-12 col-md-9 px-4">
                                    <p className="buy_title_md text-md-right word_brak_text">
                                        <span   > {payload?.DisplayName ? payload?.DisplayName : String(payload?.WalletAddress).slice(0, 6).concat("..")}</span></p>
                                </div>
                            </div>
                            <form className="bid_form" action="#">
                                {
                                    <div className="mb-3 px-4 ">

                                        <label htmlFor="qty">Quantity
                                            <span>{owner?.NFTBalance}</span>
                                        </label>

                                        <div className="mb-3 input_grp_style_1">
                                            <input
                                                type="text"
                                                className="form-control primary_inp text-center"
                                                id="NFTQuantity"
                                                maxLength={6}
                                                value={NFTQuantity}
                                                onChange={(e) => SetNFTQuantity(Number(e.target.value))}
                                                name="NumOnly"
                                                disabled={owner?.NFTBalance == '1' || owner?.NFTBalance == 1 ? true : false}


                                            />

                                        </div>
                                        {Error && (<span className="text-danger img-file">{Error}</span>)}
                                    </div>}
                            </form>


                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Your balance</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">
                                        {cointype != "coin" ? TokenBalance : web3p?.utils.fromWei(coinBalance.toString())} {owner?.CoinName}</p>
                                </div>
                            </div>
                            {/* {
                         <div className="row mx-0 pb-3">
                         <div className="col-12 col-sm-6 px-4">
                             <p className="buy_desc_sm">Your Token balance</p>
                         </div>
                         <div className="col-12 col-sm-6 px-4 text-sm-right">
                             <p className="buy_desc_sm_bold">
                          
                                 symbol</p>
                         </div>
                     </div>
                        } */}
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Price</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">

                                        {owner?.NFTPrice} {owner?.CoinName}</p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Service fee</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">

                                        {web3p?.utils.fromWei(String(buyerFees))}% <span>{owner?.CoinName}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">Royalty fee</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">
                                        {(item?.NFTRoyalty)}%
                                        <span>{owner?.CoinName}</span></p>
                                </div>
                            </div>
                            <div className="row mx-0 pb-3">
                                <div className="col-12 col-sm-6 px-4">
                                    <p className="buy_desc_sm">You will pay</p>
                                </div>
                                <div className="col-12 col-sm-6 px-4 text-sm-right">
                                    <p className="buy_desc_sm_bold">



                                        <span>{YouWillGet} {owner?.CoinName}</span></p>
                                </div>
                            </div>
                            <form className="px-4">
                                <div className="text-center">


                                    <Button
                                        type="button"
                                        className="create_btn btn-block"
                                        onClick={() => {
                                            window.$("#PurchaseNow_modal").modal("hide")
                                            window.$("#PurchaseStep_modal").modal("show")
                                            // FormSubmit();
                                        }}
                                    >
                                        proceed

                                    </Button>
                                    <Button className="btn cancel btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"    >
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                        </div>
                        <div className="modal-body">
                            <form>
                            {cointype == 'coin' ? null :

                                    <div className="text-center">


                                        <Button
                                            type="button"
                                            className={"create_btn btn-block"}
                                            disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                            onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}

                                        >{Btn == 'start' && 'Approve'
                                            || Btn == 'try' && 'Try-Again'
                                            || Btn == 'error' && 'Error'
                                            || Btn == 'done' && 'Done'
                                            || Btn == 'process' && 'In-Progress'
                                            }

                                        </Button>
                                    </div>
                                }
                                <div className="text-center my-3">
                                    <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    {/* {console.log("ZZZZZZZZZZZZZZ", Btn, App_Btn, (Btn != 'done' && App_Btn == 'init' || App_Btn == 'error' || App_Btn === "process" || App_Btn === "done"))} */}
                                    {lazy.status == "Lazymint" ?
                                        <Button
                                            className={"create_btn btn-block"}
                                            type="button"
                                            disabled={Btn != 'done' && App_Btn == 'init' || App_Btn == 'error' || App_Btn === "process" || App_Btn === "done" ? true : false}
                                            onClick={App_Btn == 'start' || App_Btn === "try" ? LazyMintCall : null}
                                        >
                                            Proceed to payment
                                        </Button> :

                                        <Button
                                            className={"create_btn btn-block"}
                                            type="button"


                                            disabled={Btn != 'done' && App_Btn == 'init' || App_Btn == 'error' || App_Btn === "process" || App_Btn === "done" ? true : false}
                                            onClick={App_Btn == 'start' || App_Btn === "try" ? _Buy : null}

                                        >
                                            {App_Btn == 'start' && 'Proceed to payment'
                                                || App_Btn == 'try' && 'Try-Again'
                                                || App_Btn == 'error' && 'Error'
                                                || App_Btn == 'done' && 'Done'
                                                || App_Btn == 'process' && 'In-Progress'
                                                || App_Btn == 'init' && 'Proceed to payment'
                                            }
                                        </Button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

