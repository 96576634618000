
import React ,{useState , useEffect , useRef} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import sales from "../../src/assets/images/sales.png";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "styled-components";
// import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ActivityCard from './separate/activityTab'

import { useSelector } from "react-redux";

import {userRegister , Token_MyList_Func } from '../actions/axioss/user.axios'
import action_config from './config/config';
import { address_showing } from "../../src/actions/common";


import { Button } from "@material-ui/core";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}






export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;



  const [category,setCategory] = React.useState('owned');
  const [show,setShow] = React.useState(false);
  const [cursor,setcursor] = React.useState('');
  const [OpenPopup, SetOpenPopup] = useState('')
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [value, setValue] = React.useState('All');
  const { payload,isAdmin } = useSelector(state => state.LoginReducer.User)
  const [userProfile, setUserProfile] = useState({})
  const [Tokens, SetTokens] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })
  const [Follow, SetFollow] = useState('unfollow');

  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  useEffect(() => {
    if (typeof Tokens[value] == 'undefined') {
        Tokens[value] = { page: 1, list: [], loader: false };
        SetTokens(Tokens);
        Explore(1, value);
    }
   
}, [accountAddress,value])

// useEffect(() => {
//     Explore();
// }, [accountAddress])


const Explore = async (data, tab) => {
  var page = data ? data : (Tokens[value]?.page)
  var SendDATA = {
      TabName: "activity",
      limit: 12,
      // CustomUrl: customurl,
      NFTOwner:accountAddress,
      page: page ?? 1,
      from: 'myItem',
      cursor:cursor,
      filter:value
  }

  console.log("to data",SendDATA)
  let Resp = await Token_MyList_Func(SendDATA)
  console.log('sfgfhgfs',value,tab,Resp,Tokens)
  if (Resp?.success == 'success' && Resp.data.length > 0) {
      setcursor(Resp?.cursor)

      SetTokens({
          ...Tokens, ...{
              [value]: {
                  list: [...Tokens[value].list, ...Resp.data],
                  loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                  page: Tokens[value].page
              }
          }
      })
  }
}

const LoadMore = () => {
  Tokens[value].page = Tokens[value].page + 1;
  SetTokens(Tokens);
  Explore(Tokens[value].page);

}
  


  return (
    <div className="inner_header">



      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader activity"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex justify-content-between innertitle_center">
                  <h2 className="inner_title">Activity</h2>
           
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container-fluid container-theme">
        <div className="customDrop">

            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="customDropdown ">
                       
                      Filter</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 250 }}>
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("All")}>
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 " >All</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("myActivity")}>
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 " >My Activity</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"  >
                                    <div className="media" onClick={()=>setValue("Mint")}>
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Mint</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("Bid")}>
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("Accept")}>
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid Accept</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("Buy")}>
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Purchase</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> 
                                  
                                  <li className="px-3"  onClick={()=>setValue("PutOnSale")} >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Put on sale</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media" onClick={()=>setValue("CancelOrder")}>
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Cancel price</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  {/* <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Token Like</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> */}
                                  {/* <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Follow</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> */}
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                    
                        </div>
               
             
              
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                  {
                      <div className="followers_overall py-3">
                        
                        <div className="row">
                          {Tokens[value]?.list.length>0 ?
                        (
                            Tokens[value]?.list.map((item,index) => (
                          
                              <ActivityCard
                                item={item}
                              /> ))):<h2 className="no_datas">No Items Found</h2>
                            
                          }


                        </div>

                      </div>
                         
                        }
                      {/* } */}

                  </div>
              

                </div>


              </div>
           
        </div>

        <div className="text-center mt-4 w-100 ">
               
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}
                   onClick={()=>LoadMore()} >
                     <span>
                     Load More
                    
                    </span>

                  </Button>
                </div>
              </div>
      </div>
      <Footer />
    </div>
  );
}







