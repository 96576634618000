import React, {
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import Card from "../../assets/images/carddet.jpg"

import Convert from '../separate/Convert'

import Convert1 from '../../views/separate/Convert1'

import randomInteger from 'random-int';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CreateOrder, getAllChain, updatetokenHash } from "../../actions/axioss/nft.axios";
import useContractProviderHook from "./../../actions/contractProviderHook";
import tokencard from "../../views/separate/TokenCard"
import ImgAudVideo from "../../views/separate/ImgAudVideo";
import config from "../../views/config/config"
import { network } from '../config/networkconfig'
import { checkChain } from 'views/hooks/useWallet';
toast.configure();



export const CancelOrderRef = forwardRef((props, ref) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [Btn, SetBtn] = useState('start')
    const [show9, setShow9] = useState(true);
    const handleClose9 = () => setShow9(false);
    const ContractCall = useContractProviderHook()

    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)
    const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
    const [Randomname, setRandomName] = useState("")
    const [_nonce, setNonceHash] = useState("")
    const [signature, setSignatureHash] = useState("")
    const [signcalls, setSign] = useState(false)
    const [item, setItem] = useState();
    const [owner, SetOwner] = useState("");
    const [types, setTypes] = useState("")
    var chainid = null;
    console.log("sdasdadf", item);
    useImperativeHandle(
        ref,
        () => ({
            async CancelOrder_Click(data) {
                var { owner, types, item } = data;
                // var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
                // if(Nftbalance !== owner.NFTBalance){
                //   toast.warning("You won't buy at this moment please refresh you data");
                //   setTimeout(() => {
                //     history.push("/")
                //   }, 1000);
                // }
                chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
                console.log('shgkdfjhgskjhgs', Wallet_Details.currentChainId, chainid, item)
                var currentChainId = await web3.eth.getChainId();
                if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
                    console.log("ownerttypes item", owner, types, item)
                    setItem(item)
                    SetOwner(owner)
                    setTypes(types)

                    window.$("#cancel_order_modal").modal("show")
                }
                else {
                    var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
                    var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
                    if (proceed) {
                        var Allnetwork = await getAllChain()
                        var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
                        var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
                        console.log("sdadasdasdas", curr[0]);
                        if (Allnetwork?.status) {
                            proceed.chainList = Allnetwork?.data;
                        }
                        toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
                        dispatch({
                            type: "Account_Section",
                            Account_Section: { AccountDetails: proceed }
                        })
                        dispatch({
                            type: "Register_Section",
                            Register_Section: {
                                currency: FinalCurrency,

                            },
                        });

                    }
                    else {
                        toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
                        window.$("#cancel_order_modal").modal("hide")
                    }
                }
            }
        }),
    )

    const FormSubmit = async () => {
        SetBtn('process')
        const id = toast.loading('Cancel Your order')
        var error = await ContractCall.Contract_Base_Validation()
        if (error) {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
            SetBtn('error')
        }
        else {
            if (types == "Cancel") {
                let cont = await ContractCall.cancel_order_721_1155(owner.NFTId)
                if (cont) {
                    await Back_end(id, cont.HashValue)
                    handleClose9()
                }
                else {
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                    SetBtn('try')
                }
            }
            else {
                await Back_end(id, '')
            }

        }
    }

    const Back_end = async (id, HashValue) => {
        owner.HashValue = HashValue
        owner.NFTPrice = 0
        owner.CoinName = ''
        owner.NFTId = owner.NFTId
        owner.PutOnSale = 'true'
        owner.PutOnSaleType = 'UnlimitedAuction'
        owner.activity = types == "Cancel" ? "CancelOrder" : "CancelAuction";
        owner.NFTOwner = accountAddress
        owner.Category = item.Category
        owner.EmailId = payload.EmailId
        owner.ContractType = item.ContractType
        owner.ContractAddress = item.ContractAddress
        owner.CollectionNetwork = item.CollectionNetwork


        let Resp = await CreateOrder(owner)
        if (Resp.success == 'success') {
            toast.update(id, { render: "Cancelled Your Order Successfully", type: "success", isLoading: false, autoClose: 1000 })
            SetBtn('done')
            window.$("#cancel_order_modal").modal("hide")
            history.push(`/my-item/${payload.CustomUrl ? payload.CustomUrl : payload.CustomUrl}`)
        }
        else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
            SetBtn('try')
        }
    }

    const signcall = async () => {
        const id = toast.loading("Signature Call", { closeButton: true, theme: "light" });
        // var generator = require('generate-password');
        if (web3) {
            var randomNum = randomInteger(10000000, 100000000);
            console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
            var password = "Bidex"
            // var password = generator.generate({
            //     length: 10,
            //     numbers: true
            // });
            console.log("asdasdasdas", password);
            if (web3) {
                var web3RpcPro = new Web3(web3.providers);
                console.log("asddfsfsdvbvbvcb", web3RpcPro, accountAddress);
                const to = accountAddress
                const _amount = 0
                console.log("ajhghjas", _amount);
                const _nonce = Date.now();
                console.log("qwewqewqreqwrqrq", password);

                var tot = _nonce + Number(randomNum);
                console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
                const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
                console.log("asdsadasfdafaf", result);
                const signhash = await web3.eth.personal.sign(result, to);
                console.log("Signature", result, signhash)
                if (signhash) {
                    // SetSignTxt('done')
                    toast.update(id, {
                        render: "Approved Successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 2500,
                        closeButton: true,
                        theme: "light"
                    });
                    Back_end_Lazymint(signhash, password, tot);
                    // lazymint({ _nonce: tot, Randomname: password, signature: signhash })
                }
            }
        }
    }

    const Back_end_Lazymint = async (signhash, RandomName, NonceHash) => {
        const id = toast.loading('Cancel Your order')
        owner.NFTPrice = 0
        owner.CoinName = ''
        owner.PutOnSale = 'true'
        owner.PutOnSaleType = 'UnlimitedAuction'
        owner.activity = types == "Cancel" ? "CancelOrder" : "CancelAuction";
        owner.NFTOwner = accountAddress
        owner.Category = item.Category
        owner.EmailId = payload.EmailId
        owner.ContractType = item.ContractType
        owner.ContractAddress = item.ContractAddress
        owner.CollectionNetwork = item.CollectionNetwork
        var Resptoken = await updatetokenHash({ NFTIdeee: owner.NFTId, HashValue: signhash, RandomName: RandomName, NonceHash: NonceHash });
        console.log("ResptokenResptokenResptoken", Resptoken);
        let Resp = await CreateOrder(owner)
        if (Resp.success == 'success') {
            toast.update(id, { render: "Cancelled Your Order Successfully", type: "success", isLoading: false, autoClose: 1000 })
            SetBtn('done')
            console.log("sfnwejfowejo");
            window.$("#cancel_order_modal").modal("hide")
            history.push(`/my-item/${payload.CustomUrl ? payload.CustomUrl : payload.CustomUrl}`)
        }
        else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
            SetBtn('try')
        }
    }
    

    return (
        <div className="modal fade primary_modal" id="cancel_order_modal" tabIndex="-1" role="dialog" aria-labelledby="cancel_order_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="cancel_order_modalLabel">Cancel Order</h5>
                        <div className="change_price_img_div">

                            {/* <img src={Card} /> */}
                            <ImgAudVideo
                                file={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
                                origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
                                thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                                type={
                                    item?.CompressedFile
                                        ? item?.CompressedFile?.includes(".webp")
                                            ? "image"
                                            : item?.CompressedFile.includes(".webm")
                                                ? "video"
                                                : "audio"
                                        : item?.CompressedFile
                                }
                            />
                        </div>
                        <p className="text-gray font_we_600 font_12">You are about to delete Instant Sale for
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item?.TokenName} </span>
                            {/* for */}
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>


                                {

                                    <span className="word_brak_text_inline_new" title="">
                                        {item?.NFTPrice}{""} {item?.CoinName}

                                    </span>


                                }
                            </span>
                        </p>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled="">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-0 pt-0">
                        <form className="px-4 bid_form" >

                            {/* <label htmlFor="bid">
                    
                         {item?.NFTPrice}{ ""} {item?.CoinName}
                            </label> */}
                            {console.log("asasdaeqweqw", item)}
                            {item?.status !== "Lazymint" ?
                                <div className="text-center mt-3">
                                    <Button
                                        type="button"
                                        className="primary_btn btn-block"
                                        disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                        onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}

                                    >
                                        {Btn == 'start' && 'Start'
                                            || Btn == 'try' && 'Try-Again'
                                            || Btn == 'error' && 'Error'
                                            || Btn == 'done' && 'Done'
                                            || Btn == 'process' && 'In-Progress'
                                        }
                                    </Button>
                                    <Button className="btn  btn-block cancel"
                                        disabled={Btn === "process" || Btn === "done" ? true : false}
                                        data-dismiss="modal" aria-label="Close">
                                        Cancel</Button>

                                </div>
                                :
                                <div className="text-center mt-3">
                                    <Button
                                        type="button"
                                        className="primary_btn btn-block"
                                        disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                        onClick={Btn == 'start' || Btn === "try" ? signcall : null}

                                    >
                                        {Btn == 'start' && 'Start'
                                            || Btn == 'try' && 'Try-Again'
                                            || Btn == 'error' && 'Error'
                                            || Btn == 'done' && 'Done'
                                            || Btn == 'process' && 'In-Progress'
                                        }
                                    </Button>
                                    <Button className="btn  btn-block cancel"
                                        disabled={Btn === "process" || Btn === "done" ? true : false}
                                        data-dismiss="modal" aria-label="Close">
                                        Cancel</Button>

                                </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

