/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FooterLogo from "../../assets/img/logo.png";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Link, useLocation } from "react-router-dom";
import topfb from "../../assets/images/fackbookfoot.png"
import toptwit from "../../assets/images/twitterfoot.png"
import toptele from "../../assets/images/telefoot.png"
import topredit from "../../assets/images/reditfoot.png"
import topinsta from "../../assets/images/instafoot.png"
import { useDispatch, useSelector } from 'react-redux'
import { Sociallinks, Newsletter } from "actions/axioss/user.axios";
import config from "views/config/config";
import { isEmpty, NumANdDotOnly, NumberOnly } from "actions/common";
import { getcms } from '../../actions/axioss/user.axios';

import { toast } from 'react-toastify';
toast.configure();


const useStyles = makeStyles(styles);

export default function Footer(props) {

  const classes = useStyles();

  const wallet = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  const [cmsdata, setrest] = useState();
  console.log("sdgfdgdfgh", cmsdata);

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  var [link, setLink] = useState([]);
  var [email, setEmail] = useState('');
  var [Error, SetError] = useState({});




  useEffect(() => {
    var a = Getlink();
    console.log("hello", a)
    getcmslistdata();
  }, [])

  const Getlink = async () => {

    let link_res = await Sociallinks();
    console.log("link_res", link_res)
    setLink(link_res?.msg ?? [])
  }


  const NewsLetter = async () => {
    const id = toast.loading("Subscribing...");
    console.log("tst eamil", email)
    var err = {};
    if (!email) err.email = "Email Id Required";
    if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
    console.log('fhbdfhbdf', err)
    SetError(err);
    if (isEmpty(err)) {
      let resp = await Newsletter({ email: email });
      if (resp.success === 'success') {
        toast.update(id, { render: "succeffully Subcribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000 })
        setEmail('');
      }
      else {
        toast.update(id, { render: "Already User", type: 'error', isLoading: false, autoClose: 1000 })
        SetError({ email: resp.msg });
        setEmail('')
      }
    }
    else {
      toast.update(id, { render: err.email, type: 'error', isLoading: false, autoClose: 1000 })
    }
  }

  const getcmslistdata = async () => {
    console.log("sadsada");
    let Resp = await getcms();
    console.log("foot", Resp);
    setrest(Resp)
  }

  return (
    <>


      <footer className={footerClasses + " fotoeheade "}>

        <div className="container-fluid container-theme container-lg">
          <div className="row footer_flexsd">
            <div className="col-lg-3 col-md-12 col-12">
              <img src={FooterLogo} alt="BidexNFT" className="olbfooter" />
              {cmsdata?.map((item, idx) => {
                return (
                  <p className="copyrightsolb">{item.question == "Home page_top" ? <p dangerouslySetInnerHTML={{ __html: item?.answer }}></p> : <></>}</p>)
              })}
            </div>
            <div className="col-lg-1 col-md-12 col-12"></div>
            <div className="col-lg-8 col-md-12 col-12">



              <div className="row footerlogooolb footer_row">
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu placerat magna. Suspendisse augue sem,</p> */}
                <div className="col-lg-4 col-md-4 col-sm-6 p-0">
                  <div className="marketplaceolb">
                    <h2>Marketplace</h2>
                    <ul>
                      <Link to="/explore/All">

                        <li>Explore</li>
                      </Link>
                      <Link to={`/activity`}>

                        <li>Activity</li>
                      </Link>

                      <Link to={`/my-item/${payload?.CustomUrl}`}>
                        <li>My item</li>
                      </Link>
                    </ul>
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 p-0">
                  <div className="marketplaceolb">
                    <h2>Company</h2>
                    <ul>
                      <Link to="/cms/termsofservice">
                        <li>Terms & Conditions</li>
                      </Link>
                      <Link to="/cms/privacypolicy">
                        <li>Privacy policy</li>
                      </Link>
                      <Link to="/how-it-works">
                        <li>FAQ</li>
                      </Link>
                      <Link to="/certificates">
                        <li>Certificates</li>
                      </Link>
                      {/* <Link to="/contactAddress">
                        <li>Contact Address</li>
                      </Link> */}
                    </ul>
                  </div>
                </div>
                {/* {(link && link?.length > 0) && */}

                <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                  <div className="marketplaceolb">
                    <div className="mt-3 contact_add " >
                      <h2>Contact Address</h2>
                      {cmsdata?.map((val) =>
                        val.question === "Contact Address" &&
                        <p className="address_fill" dangerouslySetInnerHTML={{ __html: val?.answer }}></p>

                      )}

                    </div>
                    <h2>Join Our News Letter</h2>
                    <div className="footeremail">
                      <input type="email" value={'' || email} onChange={(event) => { setEmail(event.target.value) }} />
                      <button className="subscribe" onClick={NewsLetter}>Subscribe</button>
                    </div>


                    {/* <div className="sociallinksolb">
          {link.map((mlink)=>{
            return(
              <>
            {
                                    (mlink.website).toLowerCase()==="twitter" &&
                                    <a href={mlink.link} target="blank"><img src={toptwit} /></a>}&nbsp;
                                    { (mlink.website).toLowerCase()==="facebook" &&
                                    <a href={mlink.link} target="blank"><img src={topfb} /></a>}&nbsp;
                                    {(mlink.website).toLowerCase() ==="telegram" &&
                                    <a href={mlink.link}target="blank"><img src={toptele} /></a>}&nbsp;
                                    
            </>
            )
          })}
        
        </div> */}

                    <ul>


                    </ul>
                  </div>
                </div>
                {/* } */}
                {/* <p className="footerfoote">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu placerat magna. Suspendisse augue sem,</p> */}
              </div>

            </div>
          </div>
          <hr />
          <div className="copyrightsec">
            <p>Copyright© Bidex NFT INC. All rights reserved</p>
          </div>


        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
