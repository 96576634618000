import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useParams } from "react-router-dom";
import Web3 from 'web3';
import Profile from 'assets/images/no_profile2.png'
import Select from 'react-select'


import '@metamask/legacy-web3';

import { toast } from 'react-toastify';

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;



  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Add Collections</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <h2 className="user_profileim">Profile</h2>
              <div className="holder">
                <div className="profile_edit_panel">
              
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  
                 

                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input
                    type="file"
                    name="photo"
                    id="profileImg"
                    required="true"
                    className="photo"
              
                  />
                
                </div>


              </div>
              <div className="mt-3">
                <h2 className="user_profileim">Cover Image</h2>
                <div className="profile_banner">
               
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  
                


                  <div className="profile_edit_banner">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    id="coverImg"
                    className="photo"
                    
                  
                  />
                </div>


              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  
                 
                    <div className="form-group col-md-6 collectadds">
                      <label className="primary_label" htmlFor="name">Collection Name</label>
                      <input type="text"
                       autoComplete="off"
                        className="form-control"
                        id="collectionName"
                        placeholder="Enter your Collection Name"
                        />
                 
                    </div>
                  
                  
                 
                    <div className="form-group col-md-6 collectadds">

                      <label className="primary_label" htmlFor="collectionType">Category</label>
                      <Select
                      
                        className=""
                        classNamePrefix="react-select"
                        name="collectionType"
                        isSearchable={false}
                        maxMenuHeight={220}
                     
                        label="Select or type name"
                        placeholder="Select or type name"
                      />
                  

                    </div>
                  

          
 
                    <div className="form-group col-md-6 collectadds">
                      <label className="primary_label" htmlFor="customurl">Custom Url</label>
                      <input type="text"
                         autoComplete="off"
                        className="form-control"
                        id="customurl"
                        placeholder="Enter your Custom Url"
                     />
                   

                      
                    </div>
                  


                  <GridItem xs={12} sm={3} md={3}>


                  </GridItem>




                </div>
                <div className="mt-3">
         
                    <Button className="create_btn" >Add Collections</Button>
               
               
          
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
