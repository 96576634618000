import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";
// import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ReactHTMLParser from 'react-html-parser'
import { getFaqList } from '../actions/axioss/user.axios';

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Faq(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel100');
  const [faqlist, setfalist] = useState([])

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const [faqList, setFaqList] = useState([]);
  console.log("faq", faqList);
  useEffect(() => {
    getFaqDetails();

  }, []);

  const getFaqDetails = async () => {
    console.log("lksajflkasldk;")
    var resp = await getFaqList();
    console.log("resp userlist", resp)
    if (resp?.status)
      setFaqList(resp.data);
  }




  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader faq height_pages"}>
        <div className="bg_red_1">
          <div className="container-fluid container-theme">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center innertitle_center">
                  <h2 className="inner_title">Frequently Asked Questions</h2>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container-fluid container-theme page_ht mt-3">
          <GridContainer>
            {faqList.length > 0 && faqList.map((data, i) => {
              return (
                <GridItem xs={12} sm={12} md={12}>
                  <div className="faq_panel">


                    <Accordion expanded={expanded === `panel${i + 1}`} onChange={handleChangeFaq(`panel${i + 1}`)} className="mt-5">
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <div className="accordian_head"><h2>{data?.question}</h2></div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordian_para">
                          <p dangerouslySetInnerHTML={{ __html: data.answer }}></p>

                        </div>
                      </AccordionDetails>
                    </Accordion>


                  </div>
                </GridItem>
              )
            })}


          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
