//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import range from "../../src/assets/images/dollar.png";
import collsect from "../../src/assets/images/dollar.svg";
import sales from "../../src/assets/images/statistics.svg";
import exchane from "../../src/assets/images/exchange.svg";
import filter from "../../src/assets/images/sort.png";
// mysid
import TokenCard from './separate/TokenCard'

import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';
import { Token_List_Func } from "../actions/axioss/nft.axios"




toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Explore(props) {

  const classes = useStyles();
  const location = useLocation();



  const { ...rest } = props;
  const { Param_category } = useParams()
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);




  useEffect(() => {
    console.log("path", location.pathname, location.pathname.split("/")[2], Categorys,)

    if (location.pathname.split("/")[2]) {
      if (TabName == location.pathname.split("/")[2]) return
      else {
        setloadingtext(true)
        SetTabName(String(location.pathname.split("/")[2]))
      }
    }

  }, [location])


  const [TabName, SetTabName] = useState("All");



  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [loadingtext, setloadingtext] = useState(false);

  const [Tokens, SetTokens] = useState({
    All: { loader: true, page: 1, list: [] },
  });
  const Tabname = (newValue) => {
    SetTabName(newValue);
  };
  useEffect(() => {
    setloadingtext(true)

    Explore();
  }, []);
  useEffect(() => {
    console.log("called useeffect")

    if (typeof Tokens[TabName] == "undefined") {
      console.log("called if")
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      setloadingtext(true)

      Explore(1, TabName);
    }
    else {
      console.log("called else")

      setloadingtext(false)
    }


    console.log("tabname", TabName)
  }, [TabName]);

  const Explore = async (data, tab) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
    };
    console.log("Explore data to backend", SendDATA)
    let Resp = await Token_List_Func(SendDATA);
    console.log("Check datad", Resp, SendDATA)
    if (Resp?.data) {
      setloadingtext(false)

      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp?.data],
            // loader:
            //   Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
            //   Resp.data.length === 0
            //     ? false
            //     : true,
            loader: (Resp?.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
            page: Tokens[TabName].page,
          },
        },
      });
    } else {
      setloadingtext(false)

      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
          },
        },
      });
    }
  };
  const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    setloadingtext(false)
    Explore(Tokens[TabName].page);
  };
  function LikeList(data) {
    setLikedTokenList(data)
  }



  console.log("Tokens", Tokens)
  return (

    <div>

      <div className="home_header explore">


        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><Link to="/">
              <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 explore">
          <div className="container-fluid container-theme container-lg">
            <div className="flex_block_mob scrollheight align-items-center justify-content-center mb-5">
              <h1 className="title_text_white">Explore</h1>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="row">

                    <div className="col-lg-10 col-sm-10 col-12 col-md-10 explore_enf">

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 121.74"><title>categories</title><path d="M5,0H49.39a5,5,0,0,1,3.53,1.47A5,5,0,0,1,54.4,5V49.19a5,5,0,0,1-5,5H5a5,5,0,0,1-3.55-1.47l-.09-.11A5,5,0,0,1,0,49.19V5A5,5,0,0,1,5,0ZM73.49,67.53h44.37a5.05,5.05,0,0,1,5,5v44.17a5,5,0,0,1-5,5H73.49A5,5,0,0,1,70,120.27l-.09-.11a4.94,4.94,0,0,1-1.38-3.44V72.55A5,5,0,0,1,70,69l.1-.1a5,5,0,0,1,3.44-1.38ZM5,67.53H49.39A5,5,0,0,1,52.92,69a4.94,4.94,0,0,1,1.48,3.54v44.17a5,5,0,0,1-5,5H5a5,5,0,0,1-3.55-1.47l-.09-.11A5,5,0,0,1,0,116.72V72.55a5,5,0,0,1,5-5ZM73.49,0h44.37a5,5,0,0,1,5,5V49.19a5,5,0,0,1-5,5H73.49a5,5,0,0,1-5-5V5A5,5,0,0,1,70,1.47l.1-.09A5,5,0,0,1,73.49,0Z" /></svg>
                            {location.pathname.split("/")[2]}</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">



                                  <li className="px-3">
                                    <div className="media">

                                      <div className="media-body">
                                        <Link to={`/explore/All`}>
                                          <div >

                                            <p className="mt-0 banner_desc_user mb-0 not_banner_dessc"  >All</p>


                                          </div></Link>



                                      </div>
                                    </div>

                                  </li>
                                  {Categorys && Categorys.map((item) => {
                                    return (
                                      <Link to={`/explore/${item?.label}`}>
                                        <li className="px-3">
                                          <div className="media">
                                            {console.log("explore", item.label)}
                                            <div className="media-body">

                                              <div >
                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc"  >{item?.label}</p>
                                              </div>

                                            </div>
                                          </div>

                                        </li>
                                      </Link>
                                    )
                                  })}




                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>

                      {/* ----------   COLLLECTIONS SECTION------- */}
                      {/* <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                           <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="953.68" height="873.15" viewBox="0 0 953.68 873.15">
  <path d="M1043,553.31c0,81.65-2,163.37.6,244.94,2.21,69.68-46.44,140.55-133.42,134.23-81.75-5.93-164.51-9.36-244.79,15.41-11.47,3.54-23.45,7.41-33.24,14-24.36,16.42-47.13,35.21-71.38,51.8-53.81,36.83-121.47,25.32-158-28.2q-146.83-214.95-292.07-431C79.28,508,83.35,447,124.42,411.8c83.52-71.63,171.91-137,277.61-173.06,62.56-21.32,126.27-39.28,189.61-58.26,8-2.4,16.9-2.32,25.4-2.47,95.89-1.68,197.47-14.95,293.37-16,82.64-.94,129.64,43.39,131.24,126.41m-46.55-2.51c-.8-55.59-26.08-78.74-81.89-78-92.12,1.17-189.93,14.47-282,15.79-56.48.81-78.64,23.09-78,80.35q2.82,247.89,6,495.76c.65,55,25.4,80.1,80.25,79.44,92.12-1.11,184.23-3,276.34-4.39,58.27-.9,81.26-25.39,80.9-84.63-.49-80.72-.12-161.44-.12-242.16M280.7,352.7c-61.38,34-68.53,55.83-37.59,119.57q86.17,177.45,172.44,354.86c4.7,9.67,9.78,19.15,14.68,28.72l3.06-1.29Q357.15,604.11,280.7,352.7Zm117.7-61.07c-54.7,11.79-75.35,44.48-59.78,95.89q39.09,129,78.9,257.83,28.77,93.58,57.89,187.07c-15.93-115.65-37.46-230-55.19-344.92C410.21,422.65,386.37,359,398.4,291.63Zm-25.24,568.3,5-3.23L173.32,432.16c-35.76,21.83-49.43,58.66-27.46,91.73C220.7,636.52,297.26,748,373.16,859.93Zm135-135.27V264.72c-53.14,9.92-73.85,39.8-65.37,87,12.85,71.59,24.72,143.36,37.23,215C488.86,617.65,498.09,668.5,508.12,724.66Z" transform="translate(-89.96 -161.97)"/>
</svg>

                            Collections </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  
                                    
                                     
                                        <li className="px-3" >
                                          <div className="media">
                                            <div className="media-body">
                                              <div>
                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >collection name</p>

                                              </div>

                                            </div>
                                          </div>

                                        </li>

                                      
                                    

                                </ul>
                   
                              </Scrollbars>


                            </div>
                          ]}
                        />
                      </div> */}
                      {/* ---------- END OF COLLLECTIONS SECTION------- */}


                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={sales} className="filteradd" />
                            Sales Type </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={() => { SetTabName("FixedPrice") }}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Fixed Price</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={() => { SetTabName("TimedAuction") }}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={() => { SetTabName("UnlimitedAuction") }}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>

                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={collsect} className="filteradd" />
                            Price Range </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 100 }} className="nto_scrol_div">

                                <ul className="explorebg">

                                  <li className="px-3"
                                  >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={() => { SetTabName("PriceLowToHigh") }}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={() => { SetTabName("PriceHighToLow") }}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>

                                </ul>

                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      {/* <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={exchane} className="filteradd" />
                            Filter & Sort </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={()=>{SetTabName("Recent")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Recently</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={()=>{SetTabName("FixedPrice")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div  onClick={()=>{SetTabName("TimedAuction")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={()=>{SetTabName("UnlimitedAuction")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"
                                    >
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={()=>{SetTabName("PriceLowToHigh")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div onClick={()=>{SetTabName("PriceHighToLow")}}>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                              
                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div> */}
                    </div>

                  </div>
                </div>

              </div>


            </div>
            <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className=" m-0">
                    {loadingtext ?

                      <div className="proposal_panel_overall text-center">
                        <div className="text-center py-5 col-12 notfound">
                          <div className="text-center py-3  no_items_row">
                            <p className="not_found_text">Loading</p>
                            <p className="not_found_text_sub">Please wait a moment</p>
                            <div className="mt-3">

                            </div>
                          </div>
                        </div>




                      </div> :
                      <div className="explore_gris row">


                        {Tokens[TabName]?.list.length > 0 ?
                          (
                            Tokens[TabName]?.list.map((item, index) => (

                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">

                                <TokenCard product={item}
                                  index={index}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef}
                                />

                              </div>
                            )))

                          : (<> <div className="text-center py-5 col-12  ">
                            <div className="text-center py-3  no_items_row">
                              <div className="mt-3">
                                <span class="address_text">No Items Found</span>
                              </div>
                            </div>
                          </div></>)
                        }




                      </div>}
                  </div>

                </div>

              </div>



            </div>

            <div className="text-center mt-4 w-100 ">
              {Tokens[TabName]?.loader && (
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn create_btn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" style={{ display: "flex" }}
                    onClick={LoadMore}>
                    Load More <span>

                    </span>

                  </Button>
                </div>)}
            </div>
          </div>
        </section>


        <div id="logo_overlay" className="logo_ovelay">
          <Footer />
        </div>
      </div>
    </div>

  );
}
