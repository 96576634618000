import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Config from "../config/config";
import erc20Abi from '../../Abi/erc20.json'
import marketAbi from '../../Abi/market.json'
import { network } from "views/config/networkconfig";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

export const connectWallet = async (type, propschainid, status) => {
  console.log("Wallet Type", type,status)
  var accountDetails = {}
  var web3Obj = {}

  if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase') {

    web3Obj = await MetamaskWallet(type, propschainid)
  }
  if (type == 'WalletConnect') {
    web3Obj = await WalletConnect(type, propschainid, status)
  }
  if (web3Obj) {
    console.log("get Web3", web3Obj)
    try {
      const chainId = await web3Obj.eth.getChainId();
      console.log('sudghgsdjsd', network[String(chainId)]?.rpcUrl,chainId)
      var web3p = new Web3(network[String(chainId)]?.rpcUrl)
      const accounts = await web3Obj?.eth?.getAccounts();
      console.log("get Web3 accounts", String(chainId), network[String(chainId)]?.rpcUrl, accounts)
      accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
      // console.log("accountDetails.accountAddress",accountDetails.accountAddress)
      accountDetails.coinBalance = await web3Obj.eth.getBalance(accountDetails.accountAddress);
      accountDetails.web3p = web3p;
      // console.log("accountDetails.coinBalance",accountDetails)
      console.log("accountDetails", accountDetails);

      // var conObj = new web3Obj.eth.Contract(
      //   erc20Abi, Config.erc20Address
      // )
      accountDetails.web3 = web3Obj;
      accountDetails.tokenBalance = 0;
      var wenbln = await WenlamboValue(accountDetails.accountAddress, web3Obj, chainId);
      console.log('fjkghdfskjghfsd', wenbln)
      accountDetails.Wen_Bln = wenbln.balance;
      accountDetails.token = wenbln.token;
      accountDetails.currentChainId = String(Config.CHAIN_ID);
      accountDetails.chainDetails = network[String(Config.CHAIN_ID)]
      console.timeLog('jsgffsdgs', accountDetails)
      //  await conObj.methods.balanceOf(accountDetails.accountAddress).call()
      return accountDetails;
    }
    catch (e) {
      console.log("findeeeeeeee", e)
      return accountDetails;
    }
  }
}

export const MetamaskWallet = async (type, propschainid) => {
  //var accountDetails = {}
  console.log("metaaa",type,propschainid);
  var web3
  try {
    if (window.ethereum && type == 'MetaMask') {
      console.log("axhciashodjasdjas");
      web3 = new Web3(window.ethereum);
      if (window.ethereum.isMetaMask == true) {
        console.log("njfdelidjiwajdil");
        const chainId = await web3.eth.getChainId();
        console.log("gggggg",chainId);
        var correctchainid = (!Config.chain_Id_List.includes(chainId)) ? Config.CHAIN_ID : ((!propschainid) ? chainId : propschainid)
        //  (!propschainid)?netid:propschainid
        console.log("accountDetails type id@che", chainId, web3, correctchainid)
        console.log("ccccc", parseInt(chainId), parseInt(correctchainid));
        if (parseInt(chainId) != parseInt(correctchainid)) {
          console.log("rrrrrrrr");
          var getAccountDetails = await chainIdCheck(correctchainid, web3)
        }
        else var getAccountDetails = true
        console.log('kadhfkjdsghjsd', getAccountDetails);
        if (getAccountDetails) {
          await window.ethereum.enable().then(async () => {
            // User has allowed account access to DApp...
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
          });
        }
        else {
          console.log("qweqweqweqweqwe");

          return false;
        }
      }
      else {
        console.log("qweq weqweq qweqwe qweqw");

        return false;
      }
      // console.log("return web3;",web3);
      // return web3;

    }
    else if (window.BinanceChain && type == 'BinanceWallet') {
      web3 = new Web3(window.BinanceChain);
      const chainId = await web3.eth.getChainId();
      ////console("accountDetails type id",chainId,web3)
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
      }
      await window.BinanceChain.enable().then(async () => {
        // User has allowed account access to DApp...
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
      });
    }
    else if (window.ethereum && type == 'Coinbase') {
      web3 = new Web3(window.ethereum);
      const chainId = await web3.eth.getChainId();
      ////console("accountDetails type id",chainId,web3)
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
      await window.ethereum.enable().then(async () => {
        // User has allowed account access to DApp...
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0].toString().toLowerCase();
        localStorage.setItem("accountInfo", account)
        localStorage.setItem('walletConnectType', type)
      });

    }
    // Legacy DApp Browsers
    else if (window.web3) {
      console.log("wwwwwwwwwwwwwwwwwwww");
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }
    // Non-DApp Browsers
    else {
      console.log("hhhhhhhhhhhhhhhhhhh");
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }

  } catch (e) {
    console.log("accountDetails type id1 last",e)
  }
  // console.log("return web3;",web3);
  return web3;

}

// export const WalletConnect = async (type) => {
//   var web3
//   //Create WalletConnect Provider
//   ////console("Wallet connect");
//   const provider = new WalletConnectProvider({
//     rpc: {
//       56: "https://bsc-dataseed1.ninicoin.io",
//     },
//     network: 'binance',
//     chainId: 56,
//   }
//   );
//   await provider.enable().then(function (error, result) {
//     // //console('error: ' + error);
//     // //console("accountInfo", result);

//   })
//     .catch(e => {
//       //try again
//     });
//   web3 = new Web3(provider);
//   const accounts = await web3.eth.getAccounts();
//   ////console("Account : ", accounts[0]);
//   const account = accounts[0].toString().toLowerCase();
//   localStorage.setItem("accountInfo", account)
//   localStorage.setItem('walletConnectType', type)
//   ////console("accountInfo", account);
//   // localStorage.setItem("provider",JSON.stringify(provider))
//   return web3;
// }

export const WalletConnect = async (type, chainid, status) => {

  const CHAIN_ID = chainid ? chainid : Config.CHAIN_ID;
  console.log("asdasdasdasd", Config.CHAIN_ID, status);
  try {
    if (status == undefined) {
      console.log("sjhdfasgfjkasgfkjashdjkh");
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [Config.CHAIN_ID], // required
        optionalChains: Config.chain_Id_List,
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()

      let web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });

      // const chainId = await web3.eth.getChainId();

      // var correctchainid = (!Config.chain_Id_List.includes(CHAIN_ID)) ? Config.CHAIN_ID : ((!chainid) ? chainId : chainid)
      // console.log("correctchainidcorrectchainid_ifffffff", correctchainid, chainId, accounts);
      // if (parseInt(chainId) != parseInt(correctchainid)) {
      //   chainIdCheck(correctchainid, web3, provider)
      //   return true
      // }
      let account = accounts[0].toString();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
      return web3;
    }
    else {
      console.log("sdbhfjasdbfkjhask");
      // var web3 = new Web3(provider);
      // const chainId = await web3.eth.getChainId();
      // var correctchainid = (!Config.chain_Id_List.includes(CHAIN_ID)) ? Config.CHAIN_ID : ((!chainid) ? chainId : chainid)
      // console.log("correctchainidcorrectchainid", correctchainid, accounts);
      console.log("zxdcfasxdcasdas", "0x" + chainid.toString(16));
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        // chains: Config.chain_Id_List, // required
        chains: [chainid], // required
        optionalChains: Config.chain_Id_List,
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()
      console.log("bnzxvcbzxvbncvzx", provider);
      const accounts1 = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + chainid.toString(16) }],
      });
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      var web3 = new Web3(provider);

      // if (parseInt(chainId) != parseInt(correctchainid)) {
      //   chainIdCheck(correctchainid, web3, provider)
      //   return true
      // }
      console.log("accounts", accounts);
      var account = accounts[0].toString();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)

      return web3;
    }
  }
  catch (err) {
    console.log(err)
  }

}

const chainIdCheck = async (chainid, web3, provider) => {
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  console.log("ghujhughgjk", chainid, Config.CHAIN_ID);
  const ChainNameAdd = chainid == Config.CHAIN_ID ? Config.tickerNameEthereum : Config.tickerNamePolygon;
  const hexString = chainid.toString(16);
  console.log("hexString", hexString, chainid, web3.utils.toHex(chainid).toString(), ChainNameAdd);
  provider = provider ? provider : window.ethereum;
  if (provider) {
    try {
      // check if the chain to connect to is installed
      console.log("kljdajdpaksd;asld");
      await provider.request({
        "method": 'wallet_switchEthereumChain',
        "params": [
          {
            "chainId": web3.utils.toHex(chainid).toString(),
            // "chainName": ChainNameAdd,
            // "rpcUrls": [network[String(chainid)].rpcUrl],
            // "chainId": "0x61",
            // "chainName": "BNB Smart Chain Testnet",
            // "rpcUrls": ["https://data-seed-prebsc-1-s1.binance.org:8545"],
          },
        ],
      });
      return true;
    } catch (error) {
      console.log('ghdfhsgdfsjhgfshg0', error, web3.utils.toHex(chainid).toString(),
        ChainNameAdd,
        network[String(chainid)].rpcUrl,)
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          console.log("kjjkdljlkjlkjlkj");
          await provider.request({
            "method": 'wallet_addEthereumChain',
            "params": [
              {
                chainId: web3.utils.toHex(chainid).toString(),
                // chainName: ChainNameAdd,
                // rpcUrls: [network[String(chainid)].rpcUrl],
                // "chainId": "0x61",
                // "chainName": "BNB Smart Chain Testnet",
                // "rpcUrls": ["https://data-seed-prebsc-1-s1.binance.org:8545"],
                // "blockExplorerUrls": [
                //   "https://testnet.bscscan.com/"
                // ]
              },
            ],
          });
          return true;
        } catch (addError) {
          console.error("kjjkdljlkjlkjlkjaddError",addError);
        }
      }
      else {
        return false
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    return false;

  }
}

export const getServiceFees = async (chainid, currweb3) => {
  console.log('chainidchainid', chainid, currweb3)
  if (currweb3) {
    var rpcObj = currweb3;
    // console.log('sjfghsdfgs',network[chainid]?.rpcUrl,network[chainid])
    var fees = {}
    console.log("dnjsnfjfsdcsc", chainid, network[chainid]?.rpcUrl, rpcObj);
    if (rpcObj) {
      try {
        var marketObj = new rpcObj.eth.Contract(
          marketAbi,
          network[chainid]?.tradeContract
        );
        console.log('marketObjmarketObj', marketObj, network[chainid]?.tradeContract)
        var servicefees = await marketObj.methods.getServiceFee().call()
        console.log("servicefees", servicefees);
        fees.buyerFees = servicefees[0]
        fees.sellerFees = servicefees[1]
        fees.feesweb3 = rpcObj;

        return fees;
      }
      catch (e) {
        console.log("service fees catch blok running", e)
      }
    }
  }

}

const WenlamboValue = async (currAddr, web3, networkid) => {
  console.log("tokenaddress", networkid, typeof (networkid), network, network[networkid], network[networkid].tokenAddress)
  try {
    var bidvalue = new web3.eth.Contract(
      erc20Abi, network[networkid].tokenAddress
    );
    var bidbln = await bidvalue
      .methods
      .balanceOf(currAddr)
      .call();
    console.log("balanceof", typeof (bidbln))
    var bidbln1 = (bidbln / Config.decimalvalues).toFixed(Config.toFixed)
    return { balance: bidbln1, token: network[networkid].tokenSymbol }
  }
  catch (e) {
    console.log("error wenlambo value", e)
  }
}

export const checkChain = async (currentid, tochangeid, status) => {
  console.log("currentid,tochangeid", currentid, tochangeid)

  if (String(currentid) == String(tochangeid)) return true
  else {
    console.log("chian check i/p", localStorage?.walletConnectType, Number(tochangeid))

    var changestatus = await connectWallet(localStorage?.walletConnectType, tochangeid, status)
    console.log("changestatus usewalle", changestatus)
    if (changestatus?.accountAddress) {
      return changestatus;
    }
    else {
      return false
    }
  }

}