import React, {
    forwardRef,
    useImperativeHandle,useState
} from 'react';
import '@metamask/legacy-web3';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'

import { toast } from 'react-toastify';
toast.configure();

export const ShareNowRef = forwardRef((props, ref) => {

    const [title,setTitle] = useState("");
    const [url,seturl] = useState("");
    const [quote,setquote] = useState("");



    useImperativeHandle(
        ref,
        () => ({
            async Share_Click( data) {
              var {title,url,quote} =data;
              console.log("report item",title,url,quote) 
              setquote(quote)
              seturl(url)
              setTitle(title)
              window.$("#share_modal").modal("show")

              
            }
     
    
         
        }),
        
    )
    return (
        <div>
            <div className="modal fade primary_modal" id="share_modal" tabIndex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="share_modalLabel">Share link to this page</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body  px-0">

                            <div className="row justify-content-center mx-0">
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">

                                        <TwitterShareButton
                                       
                                       title={title}
                                       url={url }
                                          
                                        >
                                            <i className="fab fa-twitter"></i>
                                            <p>Twitter</p>


                                        </TwitterShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">

                                        <TelegramShareButton
                                         title={title}
                                         url={url}
                                          >

                                            <i className="fab fa-telegram-plane"></i>
                                            <p>Telegram</p>

                                        </TelegramShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">
                                        <FacebookShareButton
                             quote={quote}
                             // title={`${productdetail.TokenId}  NFT`}
                             url={url}
                                        >
                                            <i className="fab fa-facebook-f"></i>
                                            <p>Facebook</p>
                                        </FacebookShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">
                                        <WhatsappShareButton
                                           title={title}
                                           url={url}
                                         >
                                            <i className="fab fa-whatsapp"></i>
                                            <p>Whatsapp</p>
                                        </WhatsappShareButton>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

