import React, { useEffect } from "react";
import Countdown from "react-countdown";
import Lakers from "../../assets/images/lakers.png"
import Profile from "../../assets/images/masonary_03.png"
import BNB from "../../assets/images/card_bnb.png"
import Star from "../../assets/images/staricons.png"
import Clock from "../../assets/images/clock.png"
import { toast } from 'react-toastify';


import ImgAudVideo from "views/separate/ImgAudVideo";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, Link, useHistory } from "react-router-dom";
import config from "../config/config";
import { address_showing } from "actions/common";
// import {LikeRef} from './seperatemodals/LikeRef'


export default function TokenCard({ product, index, LikeList, LikedTokenList, setLikedTokenList, LikeForwardRef, from }) {
  // console.log("likrreeeecardd",product?.CustomUrl, product, index, from)// LikeList, LikedTokenList, setLikedTokenList, LikeForwardRef,
  console.log("vaproduct", product)
  // useEffect(()=>{

  // },[product])
  const hoursMinSecs = { hours: 1, minutes: 20, seconds: 40 }
  let renderer = ({ days, hours, minutes, seconds, completed }) => {

    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };
  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  const history = useHistory();


  return (

    <>

      {/* <div className="tokencards_pos">
    {console.log("owner image url",`${config.IMG_URL}/user/${product?.CustomUrl}/profile/${product?.Owner_Profile}`)}
       <div className="card_bg">
       <div className="band_name mb-3">
            <img src={Lakers} />
            <div className="band_nameMba">
                    <p>OLB</p>
            </div>
        </div>
    <Link to={`/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`}  >

     <div className="card_img_high">
     <ImgAudVideo
                file={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                origFile={`${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.OriginalFile}`}
                thumb={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                type={
                  product?.CompressedFile
                    ? product?.CompressedFile?.includes(".webp")
                      ? "image"
                      : product?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : product?.CompressedFile
                }
              />
     
     </div>
     </Link>
    
     <Link to={`/my-item/${product?.CustomUrl}`}>
           
            <div className="miketrout">
                    <img src={(product?.Owner_Profile)?
                      `${config.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Owner_Profile}`
                      :Profile} />
              <div className="miketroutMba">
              <h3>{ String(product?.NFTName).slice(0,12).concat("..")}</h3>
                    
                  
                    {product?.CustomUrl?<h5>{String(product?.CustomUrl).slice(0,8).concat("...")}</h5>:""}
             </div>
                </div></Link>
                <div className="staricons">
                  {(product?.PutOnSaleType == "FixedPrice" || product?.PutOnSaleType == "TimedAuction") &&
            <div className="bnbgs"><img src={BNB} /><span>{product?.NFTPrice}{" "}{product?.CoinName}</span></div>}
            <div className="bnbgs"><img src={Star} /><span>{product?.NFTBalance}/{product?.NFTQuantity}</span></div>
        </div>
        <div className="timercontent mb-0">
                        {product?.PutOnSaleType == "FixedPrice" && <span className="cournts">Fixed Price</span>}
                        {product?.PutOnSaleType == "UnlimitedAuction" && <span className="cournts">Unlimited Auction</span>}

                        {product?.PutOnSaleType === "TimedAuction" && 
                        
                        ((new Date(product?.EndClockTime).getTime() >=
                          Date.now())?
                           <>
                         <span className="cournts">
                          <img src={Clock}/>
                           <Countdown
                            date={product?.EndClockTime}
                            // autoStart={true}
                            renderer={renderer}
                        
                          >
                          </Countdown></span>
                          </>
                          :
                          <span className="cournts">Auction Ended</span>
                        )
                          }</div>    
       </div>
   
               
             
                  
   </div> */}

      <div className="tokencard_style">
        <Link to={`/info/${product && product?.CollectionNetwork}/${product && product?.ContractAddress}/${product && product?.NFTOwner}/${product && product?.NFTId}`}  >
          <div className="card_img_high">
            <ImgAudVideo className="card_img"
              file={`${config.IMG_URL}/nft/${product && product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
              origFile={`${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.OriginalFile}`}
              thumb={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
              type={
                product?.CompressedFile
                  ? product?.CompressedFile?.includes(".webp")
                    ? "image"
                    : product?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                  : product?.CompressedFile
              }
            />
            <div className="timercontent mb-0">
              {product?.PutOnSaleType == "FixedPrice" && <span className="cournts">Fixed Price</span>}
              {product?.PutOnSaleType == "UnlimitedAuction" && <span className="cournts">Unlimited Auction</span>}

              {product?.PutOnSaleType == "TimedAuction" &&

                ((new Date(product?.EndClockTime).getTime() >=
                  Date.now()) ?
                  <>
                  
                    <div className="timer_card">
                    {/* <Link to={`/my-item/${product?.CustomUrl}`}> */}
                      <div className="miketrout">
                        <img src={(product?.Owner_Profile) ?
                          `${config.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Owner_Profile}`
                          : Profile} />
                        <div className="miketroutMba">
                          <p className="name">{String(product?.NFTName).slice(0, 12).concat("..")}</p>

                          <p className="coins">23.56 eth</p>
                        </div>
                      </div>
                      {/* </Link> */}
                      <div>

                        <Countdown
                          date={product?.EndClockTime}
                          // autoStart={true}
                          renderer={renderer}

                        >
                        </Countdown>


                      </div>
                    </div>
                    {/* <div className="cournts">
                         <div className="miketrout">
                   <img src={(product?.Owner_Profile)?
                     `${config.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Owner_Profile}`
                     :Profile} />
             <div className="miketroutMba">
             <p className="name">{ String(product?.NFTName).slice(0,12).concat("..")}</p>
             
           <p className="coins">23.56 eth</p>
            </div>
               </div>
               <div>
                           <Countdown
                            date={product?.EndClockTime}
                            // autoStart={true}
                            renderer={renderer}
                        
                          >
                          </Countdown>
                          </div></div> */}
                  </>
                  :
                  <span className="cournts">Auction Ended</span>
                )
              }</div>
          </div>
        </Link>
        <Link to={`/my-item/${product?.CustomUrl}`}>
          <div className="card_details">
            <div className="miketrout">
              <img src={(product?.Owner_Profile) ?
                `${config.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Owner_Profile}`
                : Profile} />
              <div className="miketroutMba">
                <p className="name">{String(product?.NFTName).slice(0, 12).concat("..")}</p>
                <p className="coins">{product?.NFTPrice}{" "}{product?.CoinName}</p>
                {/* {product.PutOnSaleType=="UnlimitedAuction" ? <></> :  <p className="coins">{product?.NFTPrice}{product?.CoinName}</p>} */}
              </div>
            </div>
            <div className="staricons">
              {(product?.PutOnSaleType == "FixedPrice" || product?.PutOnSaleType == "TimedAuction") &&
                <p className="bnbgs">{product?.NFTPrice}{" "}{product?.CoinName}</p>}
              <p className="bnbgs_count">{product?.NFTBalance}of{product?.NFTQuantity}</p>
            </div>
          </div></Link>

        <div className="levelsec">
          <p></p>
          <p> Royalty{product?.NFTRoyalty}</p>

        </div>


      </div>
    </>




  )
}