import React, {
    forwardRef,
    useEffect,
    useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { GetLikeDataAction , AddLikeAction } from '../../actions/axioss/user.axios';



import { toast } from 'react-toastify';
toast.configure();

export const LikeRef = forwardRef((props, ref) => {
  console.log('likrreeeefff',props,ref)
  const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)

  useEffect(() => {
    getLikesDataCall()
}, [accountAddress]);

async function getLikesDataCall () {
  // var currAddr = await getCurAddr();
  if(payload?.WalletAddress !== "") {
      var senddata = {
        WalletAddress: payload?.WalletAddress
      }
      var check = await GetLikeDataAction(senddata);
      console.log("get Like List",check)
      if(check && check.msg) {
          return props?.LikeList(check.msg);
      }
  }
}

useImperativeHandle(
  ref,
  () => ({
      async getLikesData() {
           getLikesDataCall();
      },
      async hitLike(data) {
          console.log("like ref data",data,payload?.WalletAddress)
          if (payload.WalletAddress !== "") {
          {
              var likeData = {
                  // actions:"like",
                  WalletAddress: payload.WalletAddress,
                  NFTId: data.NFTId,
                  WalletAddress : payload.WalletAddress,
                  OwnerCustomUrl: data?.CustomUrl ?? '',
                  activity:"Liked by"
              }
              console.log("like Action",likeData)
              var resp = await AddLikeAction(likeData);
              console.log("resp like",resp)
              if(resp && resp.likeData && resp.likeData.msg && resp.likeOrUnlike) {
                  if(resp.likeData.success === 'success') {
                    console.log("likesuccess")
                      //toast.success(resp.data.toast.msg, toasterOption);
                      if(
                          resp.likeData.msg
                          && typeof resp.likeOrUnlike != 'undefined'
                      ) {
                          $('.'+data.tokenCounts+'-likecount').html(resp.likeOrUnlike);
                      }
                  }
              }
               getLikesDataCall();
              return resp.likeOrUnlike;
          }
          
          }
      
      }
  }),
)




  
    return (
      <div></div>
    )
})

