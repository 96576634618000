import axios from 'axios'
import config from '../../views/config/config'
import {axiosFunc, AppenData} from '../common'





var token ;

export const GetNftCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer',parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
}
//NFT Name Validation Function
export const nftNameValidation   =   async   (data)  =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/ValidateTokenName`,
        'headers': {
            'Authorization': token
          },
        data    :   data
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
//nftCreate axios
export const nftCreate    =   async   (data)  =>  {
    console.log("UserRegister is Calling",data)
    var formdata = AppenData(data) 
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/nftcreate`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
         
    }
    
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//Nft Image Upload Function
export const NFTImageUpload   =   async   (data)  =>  {
    console.log("ipfs call",data)
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/nftimageupload`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
         
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

// Create New NFT
export const CreateNFT    =   async   (data)  =>  {
    console.log("data create nft",data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/createnft`,
        'headers': {
            'Authorization': token
          },
        data    :   data,
        
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

// Get Category Function
export const Category    =   async   (data)  =>  {
    
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/getcategory`,
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//GET All NFT
export const Token_List_Func    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/Tokenlistfunc`,
        params  :   data
        }
    let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

//Get NFT info
export const Token_Info_Func    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/info`,
        params  :   data
        }
        console.log("info explore",senddata)
    let Resp    =   await axiosFunc(senddata)
    console.log("Resp Token_Info_Func",Resp.data)

    return Resp.data
}

//Buy And Accept 
export const BuyAccept =   async(data) =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/BuyAccept`,
        'headers': {
            'Authorization': token
          },
        data    :   data
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//put on Sale
export const CreateOrder    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/CreateOrder`,
        'headers': {
            'Authorization': token
          },
        data    :   data,
        
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

//Bid Function
export const BidApprove =   async(FormValue) =>  {
    var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/nft/BidAction`,
            'headers': {
                'Authorization': token
              },
            data    :   FormValue
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const TopCreatorApi  =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/TopCreatorApi`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const TopBuyerApi  =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/TopBuyerApi`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const TopSellerApi  =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/TopSellerApi`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const HotAuctionHotSales =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/HotAuctionHotSale`
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const CreateCollectionFunc = async(data) =>  {
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/CreateCollectionFunc`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
         
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}


export const CollectionByCreator =   async   (data)  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/CollectionByCreator`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const Activity_List_Func = async(data) => {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/Activity_List_Func`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const CollectionBySymbol = async(data) => {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/CollectionBySymbol`,
        params   :   data ?? {}
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

// Search 

export const SearchAction    =   async   (data)  =>  {
    var senddata    = {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/SearchAction`,
        params  :   data
        }
     let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

//GetLikeStatus

export const GetLikeStatus    =   async   (data)  =>  {
    var senddata    = {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/GetLikeStatus`,
        params  :   data
        }
     let Resp    =   await axiosFunc(senddata)
    console.log("isliked",Resp)
    return Resp.data
}


// Transfer

export const TransferNFT    =   async   (data)  =>  {
    console.log("transfer db call",data)
    var senddata    = {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/BuyAccept`,
        'headers': {
            'Authorization': token
          },
        data  :   data
        }
     let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

export const BurnNFT    =   async   (data)  =>  {
    console.log("transfer db call",data)
    var senddata    = {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/BurnUpdate`,
        'headers': {
            'Authorization': token
          },
        data  :   data
        }
     let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

export const Getpromotedtoken = async() =>{
    var senddata  =  {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/getpromotedtoken`,
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const NftbalanceUpdate = async(data) =>{
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/findupdatebalance`,
        'headers': {
            'Authorization': token
          },
        data    :   data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const getnft=async(data)=>{
    console.log("saddddd",data,config.BACK_URL);
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/nft/getnft`,
        'headers': {
            'Authorization': token
          },
        data    : data
       
        } 
        let Resp    =   await axiosFunc(senddata)
    console.log('res contact',Resp)
    return Resp.data
}

export const Gettoken = async(data) =>{
    console.log("fsfsf",data);
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/gettoken`,
        'headers': {
            'Authorization': token
          },
        data   :    data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const updatetoken = async(data) =>{
    console.log("updatetokenf",data);
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/updatetoken`,
        'headers': {
            'Authorization': token
          },
        data   :    data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const updatetokenHash = async (data) => {
    console.log("updatetokenf", data);
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/updatetokenHash`,
        'headers': {
            'Authorization': token
        },
        data: data  ,
    }
    let Resp = await axiosFunc(senddata)
    console.log("IdUpdatesssss", Resp.data)
    return Resp.data
}

export const tokenowneradd =async(data)=>{
    var senddata  =  {
        method  :   'POST',
        url     :   `${config.BACK_URL}/nft/tokenowneradd`,
        'headers': {
            'Authorization': token
          },
        data   :    data
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const lazymintaxios =async(data)=>{
    console.log("tokentoken",token);
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/lazymint`,
        'headers': {
            'Authorization': token
          },
        data    :   data,
        
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const signcallaxios =async(data)=>{
    console.log("sign",data);
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/nft/signcallaxios`,
        'headers': {
            'Authorization': token
          },
        data    :   data,
        
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

export const getAllChain = async () => {
    try {
      let respData = await axios({
        'method': 'get',
        'url': `${config.BACK_URL}/nft/getchains`,
      });
    console.log("Check cahinToken", respData.data)
    return respData.data;
    
    }
    catch (err) {
    }
  }