import React, {
    forwardRef, useImperativeHandle, useMemo, useEffect, useState
} from 'react'
import { Button } from '@material-ui/core';
import $ from "jquery"
import '@metamask/legacy-web3'
import Card from "../../assets/images/carddet.jpg"
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, NumANdDotOnly, NumberOnly } from "../../actions/common";
import { TransferNFT } from "../../actions/axioss/nft.axios";
import { Gettoken, updatetoken, getAllChain } from "../../actions/axioss/nft.axios"
import useContractProviderHook from "./../../actions/contractProviderHook";
import config from '../config/config'
import { network } from '../config/networkconfig'
import { checkChain } from 'views/hooks/useWallet';
import { toast } from 'react-toastify';

toast.configure();



export const TransferRef = forwardRef((props, ref) => {

    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees } = useSelector(state => state.LoginReducer.ServiceFees);
    const ContractCall = useContractProviderHook()
    const { push } = useHistory()
    const dispatch = useDispatch();
    const { payload } = useSelector(state => state.LoginReducer.User)
    // const [lazy, setlazy] = useState({})
    const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
    const [Address, SetAddress] = useState('')
    const [Quantity, SetQuantity] = useState("")
    const [Btn, SetBtn] = useState('start')
    const [Error, SetError] = useState('')
    const [item, setItem] = useState();
    const [owner, SetOwner] = useState("");
    const [disablestate, setdisablestate] = useState(false)
    var chainid = null;

    useEffect(() => {
        gettoken();
    }, [owner])


    const gettoken = async () => {
        console.log("accountAddress", accountAddress);
        var Resptoken = await Gettoken({ NFTId: owner.NFTId });
        console.log("thththt", Resptoken);
        // setlazy(Resptoken.data[0])
    }


    useImperativeHandle(
        ref,
        () => ({
            async Transfer_Click(data) {
                var { owner, file, type, thumb, item } = data;
                console.log("transfer click", owner, item)
                chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
                console.log('shgkdfjhgskjhgs', Wallet_Details.currentChainId, chainid, item)
                var currentChainId = await web3.eth.getChainId();
                if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
                    

                    if (item.status == "Lazymint") {
                        SetQuantity(owner.TokenQuantity)
                        setItem(item)
                        SetOwner(owner)
                        toast.warning("cannot Transfer before minting the token")
                    } else {
                        // if (Nftbalance != owner.NFTBalance) {
                            let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)
                            if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
                            toast.warning("You won't buy at this moment please refresh you data");
                            setTimeout(() => {
                                push("/")
                            }, 1000);
                        }
                        SetQuantity(owner.TokenQuantity)
                        setItem(item)
                        SetOwner(owner)
                        window.$("#Transfer_modal").modal("show");
                    }

                } else {
                    var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
                    var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
                    if (proceed) {
                        var Allnetwork = await getAllChain()
                        console.log('sghfsgfsgs', Allnetwork)
                        var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
                        var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
                        console.log("sdadasdasdas", curr[0]);
                        if (Allnetwork?.status) {
                            proceed.chainList = Allnetwork?.data;
                        }
                        toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
                        dispatch({
                            type: "Account_Section",
                            Account_Section: { AccountDetails: proceed }
                        })
                        dispatch({
                            type: "Register_Section",
                            Register_Section: {
                                currency: FinalCurrency,

                            },
                        });
                    }
                    else {
                        toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
                        window.$("#Transfer_modal").modal("hide")
                    }
                }

            }



        }),

    )


    const Validation = async () => {
        console.log("owner", owner, payload)
        if (isEmpty(Address)) return "User  Required"
        if (isEmpty(Quantity)) return "Quantity  Required"
        if (Quantity > owner.NFTQuantity) return "Token Quantity Must be less than " + owner.NFTQuantity
        if (Address === accountAddress) return "You Can't Transfer Token to Your Self"
        if (await ContractCall.Contract_Base_Validation()) return await ContractCall.Contract_Base_Validation()
    }

    const FormSubmit = async () => {
        setdisablestate(true)
        const id = toast.loading('Transferring Your Price')
        SetError('')
        SetBtn('process')
        var error = await Validation()
        if (error) {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
            SetBtn('error')
            SetError(error)
        }
        else {
            console.log("to transfer", item.ContractAddress, item.ContractType, Quantity, Address, owner.NFTId)
            let cont = await ContractCall.Trsanfer(item.ContractAddress, item.ContractType, Quantity, Address, owner.NFTId)
            console.log("transfer hash ", cont?.HashValue, cont)
            if (cont) {
                let newOwner = {
                    HashValue: cont?.HashValue,
                    NewTokenOwner: Address,
                    NFTQuantity: Quantity,
                    NFTId: owner.NFTId,
                    NFTOwner: owner.NFTOwner,
                    TokenOwner_Name: payload.CustomUrl,
                    OldTokenOwner_Name: owner.CustomUrl,
                    PutOnSale: owner.PutOnSale,
                    PutOnSaleType: owner.PutOnSaleType,
                    activity: "Transfer",
                    // New_EmailId : bidder.EmailId,
                    // Old_EmailId : payload.EmailId,
                    TP: 0,
                    CN: '',

                }
                let Resp = await TransferNFT({ newOwner: newOwner, item: item })
                if (Resp.success == 'success') {
                    toast.update(id, { render: "Trasferring Your token Successfully", type: "success", isLoading: false, autoClose: 1000 })
                    SetBtn('done')
                    push(`/user/${payload.CustomUrl}`)
                }
                else {
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                    SetBtn('try')
                }
            }
            else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                SetBtn('try')
            }
        }
    }


    return (
        <div className="modal fade primary_modal" id="Transfer_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="accept_modalLabel">Transfer Token</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close9">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-0">
                        <div className="img_accept text-center">
                            {/* {
                              
                                <video
                                id="my-video"
                                className="img-fluid accept_img"
                                autoPlay muted playsInline loop
                                preload="auto"
                                >   
                                <source 
                                src=""
                                
                                 type="video/mp4" />
                                </video>
                                }
 {
                          
 <>
                           
                            <audio controls controlsList="nodownload"
                             poster={'assets/images/audio.png'}        
                              alt='audio'
                               playsInline loop muted
                               type="audio/mp3"
                               autostart="off"
         
                               src=""
                             >
                             </audio></>
                             }
                              {
                                <img
                                src=""
                                alt="Collections" className="img-fluid accept_img" />
                                } */}

                            {/* <img src={Card} /> */}

                        </div>
                        <p className="text-center accept_desc px-3" >
                            <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Transfer</span>
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item?.NFTName}</span>
                            <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} ></span><br />
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>

                                {/* <span className="word_brak_text_inline_new">name</span>
                                     <span className="word_brak_text_inline_new">owner</span>
                            */}
                            </span>
                        </p>
                        <form className="bid_form" action="#">
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <div className="mx-0 pb-3"></div>
                                <label htmlFor="qty">Enter User</label>
                                <div className="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        id="Address"
                                        onChange={(e) => { SetBtn('start'); SetAddress((e.target.value).toLowerCase()) }}

                                        placeholder="0x0..."
                                        autoComplete="off"
                                        disabled={disablestate}
                                    />

                                </div>
                            </div>
                            {<div className="bor_bot_modal mb-3 px-4 ">
                                {/* <div className="mx-0 pb-3"></div> */}
                                <label htmlFor="qty">Enter No Of Tokens</label>
                                <div className="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        id="Qunatity"
                                        value={Quantity}
                                        disabled={Quantity == 1 || disablestate ? true : false}
                                        onChange={(e) => { SetBtn('start'); SetQuantity(e.target.value) }}

                                        placeholder="0"
                                        autoComplete="off"

                                    />

                                </div>
                            </div>}
                        </form>


                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="create_btn btn-block"
                                    disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                    onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}
                                >
                                    {Btn == 'start' && 'Start'
                                        || Btn == 'try' && 'Try-Again'
                                        || Btn == 'error' && 'Error'
                                        || Btn == 'done' && 'Done'
                                        || Btn == 'process' && 'In-Progress'
                                    }
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

