import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo
} from 'react';
import Web3 from 'web3';
import $ from "jquery"
import { Button } from '@material-ui/core';
import Convert from '../separate/Convert'
import {
  useHistory,
  useLocation
} from "react-router-dom";
import Card from "../../assets/images/carddet.jpg"
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";

import useContractProviderHook from "./../../actions/contractProviderHook";
import { isEmpty, NumANdDotOnly, NumberOnly } from "../../actions/common";
import { BidApprove, getAllChain } from "../../actions/axioss/nft.axios";
import config from '../config/config'
import { network } from '../config/networkconfig'
import Select from 'react-select'
import { Gettoken } from "../../actions/axioss/nft.axios"
import { toast } from 'react-toastify';
import { checkChain } from 'views/hooks/useWallet';
toast.configure();

export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {

  const { currency } = useSelector(state => state.LoginReducer)
  console.log('dfbjhbf', currency);
  const { web3, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
  const { buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);
  const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
  const ContractCall = useContractProviderHook()
  const history = useHistory()
  const { payload } = useSelector(state => state.LoginReducer.User)
  const dispatch = useDispatch();
  const [Btn, SetBtn] = useState('start')
  const [Error, SetError] = useState({})
  const [TokenQuantity, SetTokenQuantity] = useState('1')
  const [TokenBal, SetTokenBal] = useState(0)
  const [show7, setShow7] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);
  const [FormValue, SetFormValue] = useState({})
  const [item, setItem] = useState();
  const [owner, SetOwner] = useState("");
  const [bid, SetBid] = useState("");
  const [bidder, SetBidder] = useState("");
  const [disablestate, setdisablestate] = useState(false)
  const [lazy, setlazy] = useState({})
  var chainid = null;
  console.log("FormValue", FormValue,payload);

  useEffect(() => {
    gettoken()
  }, [item?.NFTId])
  // useEffect(()=>{
  //   if(payload?.kyc != "Approved"){
  //   history.push('/bidex')
  //   toast.success("your kyc is rejected,please try again")
  //   }
  
  //     },[])
  useImperativeHandle(
    ref,
    () => ({
      async placeBid_Click(data) {
        var { bidder, bid, owner, item } = data;

        console.log("ownerttypes item", isEmpty(bidder), bidder, bid, owner, item)
        chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
        console.log('shgkdfjhgskjhgs', Wallet_Details.currentChainId, chainid, item)
        var currentChainId = await web3.eth.getChainId();
        if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
       
          // console.log("Nftbalance", Nftbalance);
          // console.log("owner.NFTBalance",owner.NFTBalance);
          if (item.status == "Lazymint") {
            var formdata = {
              TokenBidderAddress: accountAddress,
              // TokenOwner_Name   : owner.ProfileUrl,
              Category: item.Category,
              NFTQuantity: isEmpty(bidder) ? 1 : bidder.NFTQuantity,
              TokenBidAmt: isEmpty(bidder) ? 0 : bidder.TokenBidAmt,
              NFTId: item.NFTId,
              ContractAddress: item.ContractAddress,
              ContractType: item.ContractType,
              CollectionNetwork: item.CollectionNetwork,
              CoinName: isEmpty(bidder) ? currency?.filter(item => item.label !== 'BNB')?.length > 0 ? currency.filter(item => item.label !== 'BNB')[0].label : 'BUSD' : bidder.CoinName
            }

            console.log("data place bid", formdata)
            SetFormValue(formdata)

            setItem(item)
            SetOwner(owner)
            SetBid(bid)
            SetBidder(bidder)
            // setTypes(types)

            window.$("#place_bid_modal").modal("show")
          }
          else {
            let  Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)
            // if (Nftbalance != owner.NFTBalance) {
              if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
              toast.warning("You won't buy at this moment please refresh you data");
              setTimeout(() => {
                window.$("#place_bid_modal").modal("hide")
                history.push("/")
              }, 1000);
            }
            var formdata = {
              TokenBidderAddress: accountAddress,
              // TokenOwner_Name   : owner.ProfileUrl,
              Category: item.Category,
              NFTQuantity: isEmpty(bidder) ? 1 : bidder.NFTQuantity,
              TokenBidAmt: isEmpty(bidder) ? 0 : bidder.TokenBidAmt,
              NFTId: item.NFTId,
              ContractAddress: item.ContractAddress,
              ContractType: item.ContractType,
              CollectionNetwork: item.CollectionNetwork,
              CoinName: isEmpty(bidder) ? currency?.filter(item => item.label !== 'BNB')?.length > 0 ? currency.filter(item => item.label !== 'BNB')[0].label : 'BUSD' : bidder.CoinName
            }

            console.log("data place bid", formdata)
            SetFormValue(formdata)

            setItem(item)
            SetOwner(owner)
            SetBid(bid)
            SetBidder(bidder)
            // setTypes(types)

            window.$("#place_bid_modal").modal("show")
          }
        } else {
          var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
          var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
          if (proceed) {
            var Allnetwork = await getAllChain()
            var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
            var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1) && (item.label !== network[Wallet_Details.currentChainId]?.currencySymbol))
            console.log("sdadasdasdas", curr[0]);
            if (Allnetwork?.status) {
              proceed.chainList = Allnetwork?.data;
            }
            toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
            dispatch({
              type: "Account_Section",
              Account_Section: { AccountDetails: proceed }
            })
            dispatch({
              type: "Register_Section",
              Register_Section: {
                currency: FinalCurrency,

              },
            });
          }
          else {
            toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
            window.$("#place_bid_modal").modal("hide")
          }
        }


      },

      async Cancel_Click(data) {
        var { owner, bidder, item } = data;
        chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
        console.log('shgkdfjhgskjhgs', Wallet_Details.currentChainId, chainid, item)
        var currentChainId = await web3.eth.getChainId();
        if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
          console.log("data cancel bid", owner, bidder, item)
          setItem(item)
          SetOwner(owner)
          // SetBid(bid)
          SetBidder(bidder)
          window.$("#cancel_modal").modal("show")
        }
        else {
          var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
          var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
          if (proceed) {
            var Allnetwork = await getAllChain()
            if (Allnetwork?.status) {
              proceed.chainList = Allnetwork?.data;
            }
            toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
            dispatch({
              type: "Account_Section",
              Account_Section: { AccountDetails: proceed }
            })
          }
          else {
            toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
            window.$("#cancel_modal").modal("hide")
          }
        }

      }


    }),

  )



  const gettoken = async () => {
    console.log("accountAddress", accountAddress);
    var Resptoken = await Gettoken({ NFTId: item?.NFTId });
    console.log("naaaa", Resptoken);
    setlazy(Resptoken.data[0])
  }



  //// functionalitites for place Bid

  useEffect(() => {
    BalCal(FormValue?.CoinName)
  }, [FormValue, FormValue?.CoinName])

  const BalCal = async (data) => {

    let TokenBal = await ContractCall.Token_Balance_Calculation(Token_details?.token_address, accountAddress)
    console.log("ba;ance calculation ,", Token_details?.token_address, TokenBal, data)
    SetTokenBal(TokenBal)
  }

  const Token_details = useMemo(() => {
    var data = currency?.filter(item => item.label === FormValue?.CoinName)?.pop() ?? currency?.filter(item => item.label !== "BNB")?.pop()
    console.log("data usememo", data)

    return {
      decimal: data?.decimal ?? 18,
      token_address: data?.address ?? config.DEADADDRESS,
      label: data?.label ?? ""
    }
  }, [FormValue, FormValue?.CoinName])

  const YouWillGet = useMemo(() => {
    console.log("YouWillGet", FormValue, FormValue?.TokenBidAmt, FormValue?.NFTQuantity, Token_details.decimal)

    if (FormValue?.TokenBidAmt && FormValue?.NFTQuantity) {
      console.log("buy_bid_price_calculation i/p", FormValue?.TokenBidAmt, FormValue?.NFTQuantity,Token_details?.decimal)
      return ContractCall.buy_bid_price_calculation((FormValue?.TokenBidAmt * FormValue?.NFTQuantity).toString(), Token_details?.decimal.toString())

    }
  },
    [Token_details, FormValue, FormValue?.TokenBidAmt, FormValue?.NFTQuantity])


  const Validation = async () => {
    console.log("balance cehck", Number(owner.NFTBalance) < Number(FormValue.NFTQuantity), owner.NFTBalance, FormValue.NFTQuantity)
    console.log('validddddd', (!((item.ContractType === '1155' || item.ContractType === 1155) && (owner.NFTBalance > FormValue.NFTQuantity))), (owner.NFTBalance > FormValue.NFTQuantity), owner.NFTBalance, FormValue.NFTQuantity)
    var Error = {}
    if (isEmpty(FormValue.TokenBidAmt) || (FormValue.TokenBidAmt == 0)) Error.TokenBidAmt = "Must Enter Bid Amount"
    if (isEmpty(FormValue.CoinName)) Error.TokenBidAmt = "Select a Token"
    if (isEmpty(FormValue.NFTQuantity)) Error.NFTQuantity = "Must Select Atleast One Token"
    else if (Number(owner.NFTBalance) < Number(FormValue.NFTQuantity)) Error.NFTQuantity = "Token Quantity Must be less than token Available"
    if (ContractCall.Contract_Base_Validation()) Error.Wal = await ContractCall.Contract_Base_Validation()
    if (!isEmpty(bid)) {
      if (FormValue.TokenBidAmt <= bid.TokenBidAmt) Error.TokenBidAmt = "Must Be greater Than " + bid.TokenBidAmt
    }
    return Error
  }

  const FormSubmit = async () => {
    const id = toast.loading('Approving Token on processing')
    SetError({})
    SetBtn('process')
    var error = await Validation()
    console.log('RFGSFGRG', error)
    if (!isEmpty(error)) {
      toast.update(id, { render: Object.values(error)[0], type: 'error', isLoading: false, autoClose: 1000 })
      SetBtn('error')
      SetError(error)
    }
    else {
      setdisablestate(true)
      console.log("fngdjnfgn", Token_details.token_address,);
      let allow = web3.utils.fromWei((await ContractCall.allowance_721_1155(Token_details.token_address, accountAddress)) ? String(await ContractCall.allowance_721_1155(Token_details.token_address, accountAddress)) : '0')
      console.log('fhfhfa', Token_details, accountAddress, Number(allow),Number(YouWillGet))
      let cont = await ContractCall.approve_721_1155(Token_details.token_address, network[Wallet_Details?.currentChainId]?.tradeContract, web3.utils.toWei(String(Number(YouWillGet) + Number(allow))))
      if (cont) {
        var _data = FormValue
        _data.HashValue = cont.HashValue
        // _data.TokenOwner = owner.TokenOwner
        _data.from = isEmpty(bidder) ? 'Bid' : 'Edit'
        _data.activity = isEmpty(bidder) ? 'Bid' : 'Edit'
        _data.EmailId = payload.EmailId
        _data.click = `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`
        console.log("data after bid to DB", _data)
        let Resp = await BidApprove(_data)
        if (Resp.success == 'success') {
          toast.update(id, { render: 'Approving Token Successfully', type: 'success', isLoading: false, autoClose: 1000 })
          SetBtn('done')
          window.$("#place_bid_modal").modal("hide")
          history.push(`/my-item/${payload?.CustomUrl}`)
        }
        else {
          toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
          SetBtn('try')
        }
      }
      else {
        toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
        SetBtn('try')
      }

    }
  }

  const onChange = async (e, data) => {
    let oii = (data === "price") ? e : e.target
    SetBtn('start')
    const { value, id, name } = oii
    console.log("e, data", e, data, value, id, name)

    let val = (data === "price") ? "CoinName" : id
    SetFormValue({ ...FormValue, ...{ [val]: data === "inp" ? (name == "NumDotOnly" ? NumANdDotOnly(value) : NumberOnly(value)) : value } })
    if (data === "price") {
      BalCal(value)
    }
  }


  //// functionalitites for cancel bid

  const CancelBidSubmit = async () => {
    SetBtn('process')
    const id = toast.loading('Cancel Your order')
    var error = await ContractCall.Contract_Base_Validation()
    //console("adasdasdasdsadasdasdasd",error)
    try {
      if (error) {
        toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
        SetBtn('error')
      }
      else {

        var FormValue = {
          TokenBidderAddress: accountAddress,
          // NFTQuantity: bidder.NFTQuantity,   ---- Because no bidder data is sent from info page pending data is directly subtracted in api
          NFTQuantity: 0,

          NFTId: item.NFTId,
          ContractAddress: item.ContractAddress,
          ContractType: item.ContractType,
          CollectionNetwork: item.CollectionNetwork,
          from: 'Cancel',
          activity: 'CancelBid',
          Category: item.Category,
          EmailId: payload.EmailId,
          click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`


        }
        console.log("cancel bid formvalue", FormValue, bidder)
        let Resp = await BidApprove(FormValue)
        if (Resp.success == 'success') {
          toast.update(id, { render: 'Cancelled Bid Successfully', type: 'success', isLoading: false, autoClose: 1000 })
          // window.$("#cancel_modal").modal("show")
          $('#CloseCancel').trigger("click");
          SetBtn('done')
          history.push(`/my-item/${payload?.CustomUrl}`)
        }
        else {
          toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
          SetBtn('try')
        }
      }
    } catch (err) {
      console.log("err cancel bid", err)
    }

  }




  return (
    <div>
      <div id="Validation_PlaceABid" ></div>
      {/* place_bid Modal */}
      <div className="modal fade primary_modal" id="place_bid_modal" tabIndex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2 "><span className="text_red mr-2">{item?.NFTName}</span>by
                <span className="text_red ml-2"
                  title={"Bidder : "} >
                  {String(owner?.NFTOwner).slice(0, 6).concat("...")}
                </span></p>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <form className="px-4 bid_form">
                <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1 putonslae">
                  <input
                    type="text"
                    maxLength="10"
                    id="TokenBidAmt"
                    value={FormValue.TokenBidAmt}
                    name="NumDotOnly"
                    onChange={(e) => onChange(e, 'inp')}
                    className=""
                    placeholder="Enter your bid amount"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                    disabled={disablestate}
                  // value={tokenBidAmt}
                  />
                  <div className="input-group-append">
                    {/* <span className="pl-0" id="basic-addon2">symbol</span>
                     */}
                    <Select
                      value={{ label: FormValue?.CoinName, value: FormValue?.CoinName }}
                      defaultValue={{ label: FormValue?.CoinName, value: FormValue?.CoinName }}
                      className="select_custom"
                      onChange={(e) => { onChange(e, 'price') }}
                      options={currency?.filter(item =>item.label != network[Wallet_Details.currentChainId]?.currencySymbol)}
                      id='CoinName'
                      isSearchable={false}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
                {/* { <span className="text-danger">bidamt</span>} */}
                {<label htmlFor="qty">Enter quantity <span className="label_muted pl-2">({owner?.NFTBalance} available)</span></label>}
                {<div className="mb-3 input_grp_style_1">
                  <input

                    className="form-control"
                    type="text"
                    id="NFTQuantity"
                    value={FormValue.NFTQuantity}
                    onChange={(e) => onChange(e, 'inp')}
                    name="NumOnly"
                    disabled={owner.NFTBalance == '1' || owner.NFTBalance == 1 || disablestate ? true : false}
                    placeholder="Enter your bid quantity"

                  // value={NoOfToken}
                  />
                </div>}

                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{web3?.utils.fromWei(coinBalance)} BNB</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{TokenBal} {FormValue?.CoinName} </p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{web3?.utils.fromWei(String(buyerFees))}% <span>{FormValue.CoinName}</span></p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      {YouWillGet} {FormValue?.CoinName}

                    </p>
                  </div>
                </div>

                <div className="text-center">
                  {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                  <Button className="create_btn btn-block"
                    disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                    onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}>
                    {Btn == 'start' && (isEmpty(bidder) ? 'Place a bid' : 'Edit Bid')
                      || Btn == 'try' && 'Try-Again'
                      || Btn == 'error' && 'Error'
                      || Btn == 'done' && 'Done'
                      || Btn == 'process' && 'In-Progress'
                    }
                  </Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div className="modal fade primary_modal" id="proceed_bid_modal" tabIndex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className={"create_btn btn-block "}

                  >
                    Approve
                  </Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div className="modal fade primary_modal" id="accept_modalcopy" tabIndex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content model_acceptbid">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {/* {
                 
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay controls playsInline loop
                    preload="auto"
        
                  >

                    <source
                     
                      type="video/mp4" />

                  </video>}

                {
         
                  <>
                  

                    {

                      <>
                        <audio controls
                          muted
                          alt='audio'
                          className="img-fluid"
                          playsInline loop
                          type="audio/mp3"
                          autostart="off"
                          id="audio_play"
                          src=""
                        >
                        </audio>
                      </>
                    }
                  </>
                }
                {
                 

                  <img
                    src=""

                    alt="Collections" className="img-fluid " />
                } */}
                <img src={Card} />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">name</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold word_braek_txt_new pl-2">0x123456789</span>
              </p>
              <p className="info_title text-center">

                symbol for 0 edition(s)</p>

              <div>
                <label className="primary_label" htmlFor="price_new">Quantity</label>
                <input type="text"
                  className="form-control primary_inp"
                  id="putonsalequantity"
                  placeholder="Enter your quantity"

                />

              </div>
              <div className="row mx-0 pb-3 pt-2">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee in % </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">0%</p>
                </div>
              </div>

              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Royalty fee in %

                  </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    0 %

                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">

                    symbol</p>
                </div>
              </div>
              <form className="">
                <div className="text-center">

                  <Button className="primary_btn btn-block"

                  >accept

                  </Button>

                  <Button className="cancel btn btn-block"

                    data-dismiss="modal" aria-label="Close">Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      {/* cancel bid Modal */}
      <div className="modal fade primary_modal" id="cancel_modal" tabIndex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Cancel bid</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id='CloseCancel'>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              {/* <div className="img_accept text-center">
                {
                  
               
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay controls playsInline loop
                    preload="auto"
            
                  >

                    <source
                      src=""

                      type="video/mp4" />

                  </video>}

                {
                 
                  <>

                    {

                      <>
                        <audio controls
                          muted
                          alt='audio'
                          className="img-fluid"
                          playsInline loop
                          type="audio/mp3"
                          autostart="off"
                          id="audio_play"
                          src=""
                        >
                        </audio>
                      </>
                    }
                  </>
                }
                {
                  

                  <img
                    src=""alt="Collections" className="img-fluid " />
                }
              </div> */}
              <p className="text-center accept_desc p-3">
                <span className="buy_desc_sm">You are about to cancel bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item?.NFTName}</span>
              </p>
              {/* <p className="info_title text-center">
           
                symbol for 1 edition(s)</p> */}
              <form className="px-4">
                <div className="text-center">
                  <Button className="primary_btn btn-block"
                    disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                    onClick={Btn == 'start' || Btn === "try" ? CancelBidSubmit : null}
                  > {Btn == 'start' && 'Start'
                    || Btn == 'try' && 'Try-Again'
                    || Btn == 'error' && 'Error'
                    || Btn == 'done' && 'Done'
                    || Btn == 'process' && 'In-Progress'
                    }</Button>
                  <Button className="cancel_btn btn-block" data-dismiss="modal" aria-label="Close"
                    disabled={Btn === "process" || Btn === "done" ? true : false}
                  >Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end cancel bid modal */}

      <div className="modal fade primary_modal" id="accept_price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i class="fas fa-check"></i>


                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                  >approve

                  </Button>
                </div>
                <div className="media approve_media">
                  <i class="fas fa-check"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Accept the Bid</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                  >

                    mint
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

