import React,{ useEffect, useState } from "react";
import Noimgeee from "../../assets/img/banner_img.png"
export default function ImgAudVideo({
  file,
  type,
  classname,
  thumb,
  origFile,
  from,
  noimg,
  pagetype   // added to play full in info page 
}) {

console.log("pagetype",file)

  
  var [Check,setCheck] = useState(false)
  const Audioaction =()=>{
    var aud = document.getElementById("nftaudio");
    if(Check==false){
      aud.play();
      setCheck(!Check)
    }
    else{
      aud.pause();
      setCheck(!Check)
    }
  }
  return ( file ? (
    type === "image" ? (
      <img
        src={file}
        alt="img"
        className={classname}
        onContextMenu="return false;"
        onError={event => {
          event.target.src = origFile
        }}
      />
    ) : type === "video" ? (
     !(pagetype && pagetype == "info")?
      <img src={thumb}></img>:
      <video
        loop={true}
        muted
        autoPlay={true}
        controls
        poster={thumb}
        onContextMenu="return false;"
        type="video/*"
        // src={file}
        // onError={event => {
        //   event.target.src = origFile
        // }}
        src={origFile}

      >

      </video>
    ) : type === "audio" ? (
      !(pagetype && pagetype == "info")?
      <img src={thumb} alt="favicon" onClick={Audioaction} className={classname} />:

      <>
        {" "}
        <div>
        <img src={thumb} alt="favicon" onClick={Audioaction} className={classname} />
        <audio
          id="nftaudio"
          controls
          // autoPlay
          // loop
          // muted
          src={origFile}
        >
          {/* <source  type="audio/*" /> */}
        </audio>
        </div>
      </>
    ) : (
      <img src={Noimgeee} alt="audio" className={classname} />
    )
  ) : (
    <img src={Noimgeee} alt="noimg" className={classname} />
  ));
}
