import React, {
    forwardRef,
    useImperativeHandle, useState, useEffect
} from 'react';

import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


import $ from "jquery";
import { isEmpty, NumANdDotOnly, NumberOnly } from "../../actions/common";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { BurnNFT, getAllChain } from "../../actions/axioss/nft.axios";
import { Gettoken, updatetoken } from "../../actions/axioss/nft.axios"

import config from '../config/config'
import { network } from '../config/networkconfig'
import { checkChain } from 'views/hooks/useWallet';

toast.configure();

export const BurnRef = forwardRef((props, ref) => {

    const ContractCall = useContractProviderHook()
    const history = useHistory();
    const dispatch = useDispatch();


    const [item, setItem] = useState();
    const [owner, SetOwner] = useState("");
    const [burnQunatity, setBurnQuantity] = useState(1);
    const [onChangeBurnQty, setOnchangeBurnQty] = useState(1);

    const [Btn, SetBtn] = useState('start')
    const [Error, SetError] = useState('')

    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)

    const [disablestate, setdisablestate] = useState(false)
    // const [lazy,setlazy]=useState({})
    var chainid = null;



    const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);



    useEffect(() => {
        gettoken()
    }, [item?.NFTId])

    useImperativeHandle(
        ref,
        () => ({
            async Burn_Click(data) {
                var { owner, item } = data;
                console.log("data burn ", item)
                chainid = (item?.chainType) ? item?.chainType : config.default_chainId;

                var currentChainId = await web3.eth.getChainId();
                if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
                    if (item.status == "Lazymint") {
                        SetOwner(owner)
                        setItem(item)

                        if (item?.ContractType == "721") {
                            setBurnQuantity(1)
                            setOnchangeBurnQty(1)
                        }
                        else {
                            setBurnQuantity(owner.NFTBalance)
                        }
                        window.$("#burn_token_modal").modal("show")
                    }
                    else {
                    let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)

                        // if (Nftbalance != owner.NFTBalance) {
                            if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
                            toast.warning("You won't buy at this moment please refresh you data");
                            setTimeout(() => {
                                history.push("/")
                            }, 1000);
                        }
                        SetOwner(owner)
                        setItem(item)
                        if (item?.ContractType == "721") {
                            setBurnQuantity(1)
                            setOnchangeBurnQty(1)
                        }
                        else {
                            setBurnQuantity(owner.NFTBalance)
                        }
                        window.$("#burn_token_modal").modal("show")
                    }
                }
                else {
                    var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
                    var proceed = await checkChain(Number(sendchain), Number(chainid),"switch")
                    if (proceed) {
                        var Allnetwork = await getAllChain()
                        var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
                        var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
                        console.log("sdadasdasdas", curr[0]);
                        if (Allnetwork?.status) {
                            proceed.chainList = Allnetwork?.data;
                        }
                        toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
                        dispatch({
                            type: "Account_Section",
                            Account_Section: { AccountDetails: proceed }
                        })
                        dispatch({
                            type: "Register_Section",
                            Register_Section: {
                                currency: FinalCurrency,

                            },
                        });
                    }
                    else {
                        toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
                        window.$("#burn_token_modal").modal("hide")
                    }
                }



            }



        }),

    )

    const gettoken = async () => {
        console.log("accountAddress", accountAddress);
        var Resptoken = await Gettoken({ NFTId: item?.NFTId });
        console.log("naaaa", Resptoken);
        // setlazy(Resptoken.data[0])
    }

    const Validation = async () => {
        console.log("owner", typeof (onChangeBurnQty), typeof (owner.NFTBalance))
        // if(isEmpty(burnQunatity) || burnQunatity == 0 ||  burnQunatity == "0") return "Quantity  Required"
        // if(Number(burnQunatity)>Number(owner.NFTBalance)) return "Token Quantity Must be less than " + owner.NFTBalance

        if (isEmpty(onChangeBurnQty) || onChangeBurnQty == 0 || onChangeBurnQty == "0") return "Quantity  Required"
        if (Number(onChangeBurnQty) > Number(owner.NFTBalance)) return "Token Quantity Must be less than " + owner.NFTBalance
        if (await ContractCall.Contract_Base_Validation()) return await ContractCall.Contract_Base_Validation()
    }


    const BurnFunc = async () => {
        const id = toast.loading('Burning token')
        SetError('')
        SetBtn('process')
        var error = await Validation()
        console.log("valiation error", error)
        if (error) {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
            // SetBtn('error')
            SetBtn('try')

            SetError(error)
        }
        else {
            console.log("to transfer", item.ContractAddress, item.ContractType, burnQunatity, owner.NFTId, onChangeBurnQty)
            let cont = await ContractCall.Burn(item.ContractAddress, item.ContractType, onChangeBurnQty, owner.NFTId)
            console.log("transfer hash ", cont?.HashValue, cont)
            if (cont) {
                let newOwner = {
                    HashValue: cont?.HashValue,
                    // NFTBalance    :  owner.NFTBalance - burnQunatity,
                    // burnQunatity    : burnQunatity,
                    NFTBalance: owner.NFTBalance - onChangeBurnQty,
                    burnQunatity: onChangeBurnQty,
                    NFTId: owner.NFTId,
                    NFTOwner: owner.NFTOwner,
                    activity: "Burn",

                    TP: 0,
                    CN: '',

                }
                let Resp = await BurnNFT({ newOwner: newOwner, item: item })
                if (Resp.success == 'success') {
                    toast.update(id, { render: "Burn Successfully", type: "success", isLoading: false, autoClose: 1000 })
                    SetBtn('done')
                    $('#closeburn').trigger("click");
                    history.push(`/myitem/${payload.CustomUrl}`)
                }
                else {
                    toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                    SetBtn('try')
                }
            }
            else {
                toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
                SetBtn('try')
            }
        }
    }

    const Lazymint_BurnCall = async () => {
        const id = toast.loading('Burning token')
        SetError('')
        SetBtn('process')
        var error = await Validation()
        console.log("valiation error", error)
        if (error) {
            toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000 })
            // SetBtn('error')
            SetBtn('try')

            SetError(error)
        }
        else {
            console.log("to transfer", item.ContractAddress, item.ContractType, burnQunatity, owner.NFTId, onChangeBurnQty)

            let newOwner = {
                HashValue: owner?.HashValue,
                NFTBalance: owner.NFTBalance - onChangeBurnQty,
                burnQunatity: onChangeBurnQty,
                NFTId: owner.NFTId,
                NFTOwner: owner.NFTOwner,
                activity: "Burn",
                TP: 0,
                CN: '',

            }
            let Resp = await BurnNFT({ newOwner: newOwner, item: item })
            if (Resp.success == 'success') {
                toast.update(id, { render: "Burn Successfully", type: "success", isLoading: false, autoClose: 1000 })
                SetBtn('done')
                $('#closeburn').trigger("click");
                history.push(`/myitem/${payload.CustomUrl}`)
            }
            else {
                toast.update(id, { render: 'Server Error', type: 'error', isLoading: false, autoClose: 1000 })
                SetBtn('try')
            }

        }
    }

    return (

        <div className="modal fade primary_modal" id="burn_token_modal" tabIndex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title" id="burn_token_modalLabel">Burn token</h5>
                        <button type="button" id="closeburn" className="close" data-dismiss="modal" aria-label="Close"


                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-0">
                        <form className="bid_form">
                            <div className="px-4">
                                <p className="checkout_text_light_white text-center" style={{ color: 'black', fontWeight: 'bold' }}>{owner?.NFTBalance} Tokens Available</p>
                            </div>
                            <div className="px-4">
                                <p className="checkout_text_light_white">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
                            </div>
                            <div className="px-4 mt-4 pt-2">
                                <input
                                    id="burn"
                                    name="burn"
                                    className="form-control"
                                    disabled={burnQunatity == 1 || burnQunatity == "1" || Btn == "process"}
                                    value={onChangeBurnQty}
                                    onChange={(e) => {
                                        console.log("burn qunat", e.target.value)
                                        // setBurnQuantity(e.target.value)
                                        setOnchangeBurnQty(e.target.value)
                                    }}
                                />
                                <div className="text-center mt-3">
                                    {item?.status !== "Lazymint" ?
                                        <Button
                                            className="burn_btn_red primary_btn btn-block"
                                            disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                            onClick={Btn == 'start' || Btn === "try" ? BurnFunc : null}

                                        >
                                            {Btn == 'start' && 'Start'
                                                || Btn == 'try' && 'Try-Again'
                                                || Btn == 'error' && 'Error'
                                                || Btn == 'done' && 'Done'
                                                || Btn == 'process' && 'In-Progress'
                                            }


                                        </Button>
                                        :
                                        <Button
                                            className="burn_btn_red primary_btn btn-block"
                                            disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                                            onClick={Btn == 'start' || Btn === "try" ? Lazymint_BurnCall : null}

                                        >
                                            {Btn == 'start' && 'Start'
                                                || Btn == 'try' && 'Try-Again'
                                                || Btn == 'error' && 'Error'
                                                || Btn == 'done' && 'Done'
                                                || Btn == 'process' && 'In-Progress'
                                            }


                                        </Button>}
                                    <Button className="btn_outline_red btn-block create_btn" data-dismiss="modal" aria-label="Close"
                                        disabled={Btn == 'process'}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

