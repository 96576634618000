
import React from "react";
import {useHistory} from "react-router-dom";
import Avatars from "views/Avatar";
import moment from 'moment'
import config from "../config/config";
import ImgAudVideo from "views/separate/ImgAudVideo";
import { Link } from "react-router-dom";


export default function ActivityCard(props) {
var history=useHistory();
    var {
    item
    } = props;
    console.log("item activity",item)
 
    return (
      <div className="col-12 col-md-6 col-xl-4 mb-4">
      <div className="card my-0 h-100 acti_card">
        <div className="card-body px-3">
        <div className="media follow_media icon_img">
        <div className="icon-img-div">
  
                  <div className="img_media_new  mr-3">
               
									{
    <Link to={(item?.To)?`/info/${item?.CollectionNetwork}/${item?.ContractAddress}/${item?.To}/${item?.TokenId}`:"#"}  >
									
                    <div className="acticid">
                    {/* <Avatars  item="img-fluid" /> */}
               <ImgAudVideo
                file={`${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT/${item?.CompressedFile}`}
                origFile={`${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item?.OriginalFile}`}
                thumb={`${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                type={
                  item?.CompressedFile
                    ? item?.CompressedFile?.includes(".webp")
                      ? "image"
                      : item?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : item?.CompressedFile
                }
              />
              </div>
               </Link>
									}
                  </div>
      </div>
          <div className="media-body flex_body">
          { <div>
            
            <p className="my-0 media_text" >{item?.TokenName}</p>
            {(item?.Activity == "Mint")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} Minted ${item?.NFTQuantity} NFTs.`}</p>}
            {(item?.Activity == "PutOnSale")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} Placed ${item?.NFTQuantity} NFTs for sale.${item?.NFTPrice} ${item?.CoinName} each.`}</p>}
            {(item?.Activity == "CancelOrder")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} canceled ${item?.NFTQuantity} NFTs.`}</p>}
            {(item?.Activity == "Buy")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} bought ${item?.NFTQuantity} NFTs from ${(item?.From_DisplayName)}. `}</p>}
            {(item?.Activity == "Bid")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} placed a Bid on ${item?.NFTQuantity} NFTs for ${(item?.NFTPrice)} ${(item?.CoinName)} each. `}</p>}
            {(item?.Activity == "Edit")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} edited Bid on ${item?.NFTQuantity} NFTs to ${(item?.NFTPrice)} ${(item?.CoinName)} each. `}</p>}
            {(item?.Activity == "CancelBid")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)} cancelled Bid on ${item?.NFTQuantity} NFT(s) . `}</p>}
            {(item?.Activity == "Accept")&&<p className="my-0 media_text" >{`${(item?.From_DisplayName)} Accepted the Bid on ${item?.NFTQuantity} NFT(s) placed by ${(item?.To_DisplayName)}. `}</p>}
            {(item?.Activity == "Transfer")&&<p className="my-0 media_text" >{`${(item?.From_DisplayName)} Transferred  ${item?.NFTQuantity} NFT(s) to ${(item?.To_DisplayName)}. `}</p>}
          
            {/* {(item?.Activity == "PutOnSale")&&<p className="my-0 media_text" >{`${(item?.To_DisplayName)?String(item?.To_DisplayName).slice(0,7).concat(".."):String(item?.To).slice(0,7).concat("..")} Placed ${item?.NFTQuantity} NFTs for sale.`}</p>} */}
             
            

          {/* <p className="mt-0 media_num mt-0">
          <span></span>
         <b>name</b>   <span>activity  </span>
            <span className="media_text pl-1 px-1 ml-1">
            name</span>
         
           
          <> from <span className="media_text pl-1"  title="">
         name
            </span></>
             </p>  */}
              </div>}
         
          </div>
          </div>
          <div className="ml-2">
          <p className="mt-0 media_num mb-0 dur_text">	{moment(new Date(item?.updatedAt)).format('DD/MM/YYYY, h:mm a')}</p>
      </div>
         
        </div>
      </div>
    </div>
          
          
    )
}
