import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo
} from 'react';
import Web3 from 'web3';

import { Button } from '@material-ui/core';
import Convert from '../separate/Convert'
import {
  useHistory,
  useLocation
} from "react-router-dom";
import Card from "../../assets/images/carddet.jpg"
import axios from 'axios';
import randomInteger from 'random-int';
import { useSelector, useDispatch } from "react-redux";

import useContractProviderHook from "./../../actions/contractProviderHook";
import { isEmpty, NumANdDotOnly, NumberOnly } from "../../actions/common";
import { BidApprove, getAllChain } from "../../actions/axioss/nft.axios";
import config from '../config/config'
import Select from 'react-select'
import { Gettoken, updatetoken } from "../../actions/axioss/nft.axios"
import { toast } from 'react-toastify';
import { checkChain } from 'views/hooks/useWallet';
import { network } from '../config/networkconfig'
toast.configure();

export const AcceptBidRef = forwardRef((props, ref) => {
  console.log("props accept", props)
  const { currency } = useSelector(state => state.LoginReducer)
  const { web3, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
  const { sellerFees } = useSelector(state => state.LoginReducer.ServiceFees);
  const ContractCall = useContractProviderHook()
  const history = useHistory()
  const dispatch = useDispatch();
  const { payload } = useSelector(state => state.LoginReducer.User)
  const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
  const [Btn, SetBtn] = useState('start')
  const [Error, SetError] = useState({})
  const [TokenQuantity, SetTokenQuantity] = useState('1')
  const [TokenBal, SetTokenBal] = useState(0)
  const [show6, setShow6] = useState(true);
  const [TokenBtn, SetTokenBtn] = useState("start");
  const handleClose6 = () => setShow6(false);
  // const token_address  = (currency)?.filter(item=>item.label == bidder?.CoinName)?.pop()?.address ?? '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'
  const [token_address, settoken_addres] = useState("");
  const [YouWillGet, setYouWillGet] = useState("");
  const [disablestate, setdisablestate] = useState(false)


  // console.log("ddddddaesww", lazy);
  // useEffect(()=>{
  //     BalCal(token_address)
  //   },[bidder])

  //   const BalCal  = async (data)  =>  {
  //     console.log("balcal",token_address,bidder?.TokenBidderAddress)
  //    let  TokenBal =  await ContractCall.Token_Balance_Calculation(data??token_address,bidder?.TokenBidderAddress)
  //    SetTokenBal(TokenBal)
  //   }

  //   useEffect(()=>{
  //     console.log("useeffeect",(currency)?.filter(item=>item.label == bidder?.CoinName)?.pop()?.address ?? '0x7CAB80ce0E55F46378E493B584eE61aD68878f11')
  //     settoken_addres((currency)?.filter(item=>item.label == bidder?.CoinName)?.pop()?.address ?? '0x7CAB80ce0E55F46378E493B584eE61aD68878f11')
  //   },[bidder])

  //  const YouWillGet = useMemo(() => { 
  //     console.log("usememo dat",bidder?.TokenBidAmt,TokenQuantity)
  //     if(bidder && bidder?.TokenBidAmt){
  //     console.log("memo vall")

  //         return ContractCall.price_calculation((bidder?.TokenBidAmt*TokenQuantity).toString()) }
  //         else{
  //             console.log("no bidder")
  //         }
  //     }

  //     , [bidder?.TokenBidAmt,TokenQuantity,bidder])







  const Validation = async () => {
    console.log("validatoin check", TokenQuantity, bidder?.TokenBidAmt, TokenBal, bidder?.Pending, (Number(TokenQuantity) > Number(bidder?.Pending)), token_address, bidder?.TokenBidderAddress)
    var Error = {}
    if (isEmpty(TokenQuantity)) Error.TokenQuantity = "Must Select Atleast One Token"
    if (await ContractCall.Contract_Base_Validation()) Error.Wal = await ContractCall.Contract_Base_Validation()
    if (!isEmpty(TokenQuantity)) {
      if ((TokenQuantity * bidder?.TokenBidAmt) > web3.utils.fromWei(String(await ContractCall.allowance_721_1155(token_address, bidder?.TokenBidderAddress) ? await ContractCall.allowance_721_1155(token_address, bidder?.TokenBidderAddress) : 0))) Error.Wal = "Bidder Doesn't have enough Allowance"
      if ((TokenQuantity * bidder?.TokenBidAmt) > TokenBal) Error.Wal = "Bidder Doesn't have enough Balance"
      if ((Number(TokenQuantity) > Number(bidder?.Pending))) Error.Wal = `Token Quantity Must Be less Than ${bidder?.Pending}`
    }
    return Error
  }
  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus((item.ContractType == 721 || item.ContractType == "721") ? 'Sinle' : 'Multiple', item.ContractAddress);
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtn('start')
    } else SetTokenBtn("try");
  };

  const FormSubmit = async () => {

    const id = toast.loading('Accepting Token on processing')
    SetBtn('process')
    SetError({})
    var error = await Validation()
    if (!isEmpty(error)) {
      toast.update(id, { render: Object.values(error)[0], type: 'error', isLoading: false, autoClose: 1000 })
      SetBtn('error')
      SetError(error)
    }
    else {

      setdisablestate(true)
      SetBtn('process')
      toast.update(id, { render: 'Ready To Place Order', type: 'success', isLoading: false, autoClose: 1000 })
      // const id = toast.loading("Processing");
      const id2 = toast.loading('processing')
      let cont = await ContractCall.accept_721_1155(bidder?.CoinName,
        bidder?.TokenBidderAddress,
        [item.NFTId, web3.utils.toWei(String(bidder?.TokenBidAmt * TokenQuantity)), TokenQuantity, bidder?.ContractType],
        bidder?.ContractAddress)
      console.log("cont", cont);

      if (cont) {
        let adminProfit = await ContractCall.calculateAdminProfit(owner.NFTPrice)
        console.log("adminProfit", adminProfit);

        console.log("accept success", cont)
        var FormValue = {
          TokenBidderAddress: bidder?.TokenBidderAddress,
          NFTQuantity: TokenQuantity,
          NFTId: item.NFTId,
          ContractAddress: item.ContractAddress,
          CollectionNetwork: item.CollectionNetwork,
          ContractType: item.ContractType,
          from: 'accept',
          item: item,
          newOwner: {
            HashValue: cont.HashValue,
            NewTokenOwner: bidder?.TokenBidderAddress,
            NFTQuantity: TokenQuantity,
            NFTId: item.NFTId,
            NFTOwner: owner?.NFTOwner,
            PutOnSale: owner?.PutOnSale,
            PutOnSaleType: owner?.PutOnSaleType,
            TP: owner?.NFTPrice,
            CN: owner?.CoinName,
            activity: 'Accept',
            Category: item.Category,
            New_EmailId: bidder?.EmailId,
            Old_EmailId: payload?.EmailId,
            click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`,
            ADMINPROFIT: adminProfit.TotalEarns,
            Adminservicefee: adminProfit.serviceFee,
            Network: item.CollectionNetwork,
          }
        }
        console.log("FormValuedddddd", FormValue);
        let Resp = await BidApprove(FormValue)
        if (Resp.success == 'success') {
          toast.update(id2, { render: 'Accepting Token Successfully', type: 'success', isLoading: false, autoClose: 1000 })

          window.$("#accept_modal").modal("hide")
          SetBtn('done')

          history.push(`/my-item/${payload?.CustomUrl}`)
        }
        else {
          toast.update(id2, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
          SetBtn('try')
        }
      }
      else {
        toast.update(id2, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })
        SetBtn('try')
      }


    }

  }


  const onChange = async (e, data) => {
    SetError({})
    SetBtn('start')
    SetTokenQuantity(e.target.value)
  }



  const [item, setItem] = useState();
  const [owner, SetOwner] = useState("");
  const [approvestatus, setapprovestatus] = useState("");
  const [bidder, SetBidder] = useState("");
  const [lazy, setlazy] = useState({})
  var chainid = null;
  useEffect(() => { console.log("biddde ser=t") }, [bidder])


  console.log("bidderbid", item);

  useEffect(() => {
    pricecalculate()

  }, [bidder, bidder?.TokenBidAmt, TokenQuantity])


  // console.log("lazymintbid", lazy);

  const pricecalculate = async () => {
    console.log("bidder?.TokenBidAmt*TokenQuantity", bidder?.TokenBidAmt, TokenQuantity)
    var priceval = ContractCall.price_calculation((bidder?.TokenBidAmt * TokenQuantity).toString())
    console.log("resp you willget", priceval)
    setYouWillGet(priceval)

  }

  useImperativeHandle(
    ref,
    () => ({
      async AcceptBid_Click(data) {

        var { owner, bidder, approvestatus, item } = data;
        chainid = (item?.chainType) ? item?.chainType : config.default_chainId;
        var currentChainId = await web3.eth.getChainId();

        console.log("owner,bidder,approvestatus,item", approvestatus)
        if (Wallet_Details?.currentChainId == String(chainid) && String(currentChainId) == String(chainid)) {
          if (item.status == "Lazymint") {
            setItem(item)
            SetOwner(owner)
            SetBidder(bidder)
            setapprovestatus(approvestatus)
            if (approvestatus != "open") SetTokenBtn("done")


            var cal = await ContractCall.price_calculation((bidder?.TokenBidAmt * TokenQuantity).toString())
            console.log("imperative calculate", bidder?.TokenBidAmt, TokenQuantity, cal)
            setYouWillGet(cal)

            var tokenbal = await ContractCall.Token_Balance_Calculation((currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address, bidder?.TokenBidderAddress)
            console.log("data tokenbalance", (currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address, bidder?.TokenBidderAddress, tokenbal)
            SetTokenBal(tokenbal)

            settoken_addres((currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address)
            window.$("#accept_modal").modal("show");
          }
          else {
          let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)

            // if (Nftbalance != owner.NFTBalance) {
            if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {
              toast.warning("You won't buy at this moment please refresh you data");
              setTimeout(() => {
                history.push("/")
              }, 1000);
            }
            setItem(item)
            SetOwner(owner)
            SetBidder(bidder)
            setapprovestatus(approvestatus)
            if (approvestatus != "open") SetTokenBtn("done")


            var cal = await ContractCall.price_calculation((bidder?.TokenBidAmt * TokenQuantity).toString())
            console.log("imperative calculate", bidder?.TokenBidAmt, TokenQuantity, cal)
            setYouWillGet(cal)

            var tokenbal = await ContractCall.Token_Balance_Calculation((currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address, bidder?.TokenBidderAddress)
            console.log("data tokenbalance", (currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address, bidder?.TokenBidderAddress, tokenbal)
            SetTokenBal(tokenbal)

            settoken_addres((currency)?.filter(item => item.label == bidder?.CoinName)?.pop()?.address)
            window.$("#accept_modal").modal("show");
          }
        }
        else {
          var sendchain = Wallet_Details?.currentChainId == String(chainid) ? String(currentChainId) : Wallet_Details?.currentChainId
          var proceed = await checkChain(Number(sendchain), Number(chainid), "switch")
          if (proceed) {
            var Allnetwork = await getAllChain()
            var curr = Allnetwork.data.filter(item => item.networkid == Number(chainid))
            var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
            console.log("sdadasdasdas", curr[0]);
            console.log('sghfsgfsgs', Allnetwork)
            if (Allnetwork?.status) {
              proceed.chainList = Allnetwork?.data;
            }
            toast.success(network[chainid]?.chain + "Network Switched Successfully", { autoClose: 1000 })
            dispatch({
              type: "Account_Section",
              Account_Section: { AccountDetails: proceed }
            })
            dispatch({
              type: "Register_Section",
              Register_Section: {
                currency: FinalCurrency,

              },
            });

          }
          else {
            toast.error("Switch to " + network[chainid]?.chain + ' Network', { autoClose: 1000 })
            window.$("#Transfer_modal").modal("hide")
          }
        }



      }



    }),

  )
  console.log('fdfgdgober', owner)


  useEffect(() => {
    gettoken()
  }, [item?.NFTId])





  const gettoken = async () => {
    console.log("accountAddress", accountAddress);
    var Resptoken = await Gettoken({ NFTId: item?.NFTId });
    console.log("naaaa", Resptoken);
    setlazy(Resptoken.data[0])
  }
  console.log("itemdsdsdsd", item);

  async function LazyMintCall(signhash, RandomName, NonceHash) {
    const id = toast.loading("LazyMinting Processing");
    console.log("Minting Process", web3, owner)
    const _data = lazy;
    const value = bidder.CoinName == "CAKE" ? 0 : 0
    const _amount = owner.NFTPrice == "" || owner.NFTPrice == undefined ? 0 : web3.utils.toWei(String(owner.NFTPrice));
    const CoinName = bidder.CoinName
    _data.activity = "Mint";

    try {
      const cont = await ContractCall.lazyminting_721_1155(
        value, lazy.ContractType,
        // value,

        // console.log("retrive contract",config.IPFS + String(Tokens_Detail.MetaData), "Status", "lazy", Tokens_Detail.Randomname,"other",Tokens_Detail.NFTCreator, accountAddress, Tokens_Detail.signature)
        [config.IPFS + String(lazy.MetaData), "Status", "lazy", RandomName],
        [lazy.NFTCreator, bidder?.TokenBidderAddress, lazy.ContractAddress],
        [
          (TokenQuantity ? TokenQuantity : owner?.NFTBalance).toString(),

          lazy.ContractType,
          web3?.utils.toWei(lazy.NFTRoyalty),
          web3.utils.toWei(
            (bidder.TokenBidAmt * TokenQuantity
            ).toString()
          ),
          lazy.NFTQuantity,
          NonceHash,
          web3.utils.toWei(
            (bidder.TokenBidAmt
            ).toString()
          ),
        ],
        CoinName,
        signhash
      );
      console.log("contcont", cont);
      if (cont) {
        var HashValue = cont.HashValue;
        var NFTId = cont?.tokenCounts?.toString();
        var click = `${config.FRONT_URL}/info/${lazy.CollectionNetwork
          }/${lazy.ContractAddress
          }/${accountAddress}/${cont?.tokenCounts?.toString()}`;

        var status = "Minted"
        item.NFTId = NFTId;
        var Resptoken = await updatetoken({ NFTIdeee: lazy.NFTId, HashValue: HashValue, NFTId: NFTId, click: click, status: status });
        console.log("tokenaaaa", Resptoken);


        // var FormValue = {
        //   TokenBidderAddress: bidder?.TokenBidderAddress,
        //   NFTQuantity: TokenQuantity,
        //   NFTId: NFTId,
        //   ContractAddress: item.ContractAddress,
        //   CollectionNetwork: item.CollectionNetwork,
        //   ContractType: item.ContractType,
        //   from: 'accept',
        //   item: item,
        //   newOwner: {
        //     HashValue: HashValue,
        //     NewTokenOwner: bidder?.TokenBidderAddress,
        //     NFTQuantity: TokenQuantity,
        //     NFTId: NFTId,
        //     NFTOwner: owner?.NFTOwner,
        //     // PutOnSale: "false",
        //     // PutOnSaleType: "UnlimitedAuction",
        //     TP: "",
        //     CN: "",
        //     activity: 'Accept',
        //     Category: item.Category,
        //     New_EmailId: bidder?.EmailId,
        //     Old_EmailId: payload?.EmailId,
        //     click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`,
        //   }
        // }

        var FormValue = {
          TokenBidderAddress: bidder?.TokenBidderAddress,
          NFTQuantity: TokenQuantity,
          NFTId: item.NFTId,
          ContractAddress: item.ContractAddress,
          CollectionNetwork: item.CollectionNetwork,
          ContractType: item.ContractType,
          from: 'accept',
          item: item,
          newOwner: {
            HashValue: cont.HashValue,
            NewTokenOwner: bidder?.TokenBidderAddress,
            NFTQuantity: TokenQuantity,
            NFTId: item.NFTId,
            NFTOwner: owner?.NFTOwner,
            PutOnSale: owner?.PutOnSale,
            PutOnSaleType: owner?.PutOnSaleType,
            TP: owner?.NFTPrice,
            CN: owner?.CoinName,
            activity: 'Accept',
            Category: item.Category,
            New_EmailId: bidder?.EmailId,
            Old_EmailId: payload?.EmailId,
            click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`,
            // ADMINPROFIT: adminProfit.TotalEarns,
            // Adminservicefee: adminProfit.serviceFee,
            // Network: item.CollectionNetwork,
          }
        }

        console.log("FormValueFormValue", FormValue);
        let Resp = await BidApprove(FormValue)
        console.log("jhasdsbdbs", Resp);

        // let Resp = await CreateNFT(_data);
        // console.log("REsp after mint", Resp)
        // toast.update(id, {
        //   render: Resp.msg,ipfs
        //   type: Resp.success,
        //   isLoading: false,
        //   autoClose: 1000,
        // });
        if (Resp) {
          window.$("#accept_modal").modal("hide")
          toast.update(id, {
            render: "Successfully Minted",
            type: Resptoken.data,
            isLoading: false,
            autoClose: 1000,
          });
          history.push("/explore/All");

        }
        //   setMintButton("done");
        //   window.$("#closemint").click()
        //   window.$("#create_item_modal").modal("hide");
        //   history.push("/explore/All");
        //   // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
        // } 
        else {
          console.log("ddddddddddddddddd");
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
          // setMintButton("try");
        }

      }
      else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        // setMintButton("try");
      }
    } catch (err) {
      console.log("comntaract", err);
    }
  }

  const signcall = async () => {
    SetBtn('process')
    const id = toast.loading("Signature Call", { closeButton: true, theme: "light" });
    // var generator = require('generate-password');
    if (web3) {
      var randomNum = randomInteger(10000000, 100000000);
      console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
      var password = "Bidex"
      // var password = generator.generate({
      //   length: 10,
      //   numbers: true
      // });
      console.log("asdasdasdas", password);
      if (web3) {
        var web3RpcPro = new Web3(web3.providers);
        console.log("asddfsfsdvbvbvcb", web3RpcPro, accountAddress);
        const to = accountAddress
        const _amount = web3.utils.toWei(
          (bidder.TokenBidAmt
          ).toString()
        )
        console.log("ajhghjas", _amount);
        const _nonce = Date.now();
        console.log("qwewqewqreqwrqrq", password);

        var tot = _nonce + Number(randomNum);
        console.log("qwewqeqwewqeqweasdas", to, _amount, password, tot);
        const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
        console.log("asdsadasfdafaf", result);
        const signhash = await web3.eth.personal.sign(result, to);
        console.log("Signature", result, signhash)
        if (signhash) {
          // SetSignTxt('done')
          toast.update(id, {
            render: "Approved Successfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "light"
          });
          LazyMintCall(signhash, password, tot);
          // lazymint({ _nonce: tot, Randomname: password, signature: signhash })
        }
      }
    }
  }











  return (
    <div>
      <div id="Validation_PlaceABid" ></div>


      {/* accept bid Modal */}
      <div className="modal fade primary_modal" id="accept_modal" tabIndex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content model_acceptbid">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {/* {
                   
                    <video
                      id="my-video"
                      className="img-fluid"
                      autoPlay controls playsInline loop
                      preload="auto"
          
                    >
  
                      <source
                       
                        type="video/mp4" />
  
                    </video>}
  
                  {
           
                    <>
                    
  
                      {
  
                        <>
                          <audio controls
                            muted
                            alt='audio'
                            className="img-fluid"
                            playsInline loop
                            type="audio/mp3"
                            autostart="off"
                            id="audio_play"
                            src=""
                          >
                          </audio>
                        </>
                      }
                    </>
                  }
                  {
                   
  
                    <img
                      src=""
  
                      alt="Collections" className="img-fluid " />
                  } */}
                {/* <img src={Card} /> */}
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item?.NFTName}</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold word_braek_txt_new pl-2">{bidder?.TokenBidderAddress}</span>
              </p>
              <p className="info_title text-center">

                {bidder?.TokenBidAmt + ' ' + bidder?.CoinName + ' ' + 'Each'} for {Number(TokenQuantity)} Edition(s)</p>

              <div>
                <label className="primary_label" htmlFor="price_new">Quantity</label>
                <input type="text"
                  className="form-control primary_inp"
                  name="TokenQuantity"
                  id="TokenQuantity"
                  value={TokenQuantity}
                  onChange={(e) => onChange(e, 'inp')}
                  disabled={owner?.NFTBalance == '1' || owner?.NFTBalance == 1 || disablestate ? true : false}
                  placeholder="Enter your quantity"

                />

              </div>
              <div className="row mx-0 pb-3 pt-2">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee in % </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">{web3?.utils.fromWei(String(sellerFees))}% {bidder?.CoinName}</p>
                </div>
              </div>

              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Royalty fee in %

                  </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {item?.NFTRoyalty}%

                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">

                    {YouWillGet}{bidder?.CoinName}</p>
                </div>
              </div>
              <form className="">
                <div className="text-center">
                  {approvestatus === 'open' &&
                    <Button className="primary_btn btn-block"
                      disabled={(TokenBtn == 'process' || TokenBtn == 'done') ? true : false}
                      onClick={(TokenBtn == 'start' || TokenBtn == 'try') ? TokenApproveCall : null}
                    >
                      {TokenBtn == 'start' && 'Approve'}
                      {TokenBtn == 'process' && 'In-Progress'}
                      {TokenBtn == 'try' && 'Try-Again'}
                      {TokenBtn == 'done' && 'Done'}

                    </Button>}
                  {lazy.status == "Lazymint" ? <Button className="primary_btn btn-block"
                    disabled={Btn == 'error' || Btn === "process" || Btn === "done" || TokenBtn !== "done" ? true : false}
                    onClick={(Btn == 'start' || Btn === "try") ? signcall : null}>
                    {Btn == 'start' && 'Accept lazy'
                      || Btn == 'try' && 'Try-Again'
                      || Btn == 'error' && 'Error'
                      || Btn == 'done' && 'Done'
                      || Btn == 'process' && 'In-Progress'
                      || Btn == "putonsale" && "List"
                    }</Button> :
                    <Button className="primary_btn btn-block"
                      disabled={Btn == 'error' || Btn === "process" || Btn === "done" || TokenBtn !== "done" ? true : false}
                      onClick={(Btn == 'start' || Btn === "try") ? FormSubmit : null}
                    >
                      {Btn == 'start' && 'Accept Bid'
                        || Btn == 'try' && 'Try-Again'
                        || Btn == 'error' && 'Error'
                        || Btn == 'done' && 'Done'
                        || Btn == 'process' && 'In-Progress'
                        || Btn == "putonsale" && "List"
                      }

                    </Button>}



                  {/* <Button className="cancel btn btn-block"
                    disabled={Btn === "process" || TokenBtn == 'process' ?true:false}
                      data-dismiss="modal" aria-label="Close">Cancel</Button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      <div className="modal fade primary_modal" id="accept_price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i class="fas fa-check"></i>


                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                  >approve

                  </Button>
                </div>
                <div className="media approve_media">
                  <i class="fas fa-check"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Accept the Bid</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                  >

                    mint
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

