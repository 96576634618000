import React from "react";
import moment from "moment"
import Avatars from "views/Avatar"
import ImgAudVideo from "views/separate/ImgAudVideo";
import config from "views/config/config"



const NotificationCard = (props)=>{

    var {
        item
        } = props;

    console.log("props notification",item)


    return (

        <div className="media">


        {/* <Avatars item="img-fluid mr-2 img_user_noti align-self-center" /> */}
        <ImgAudVideo
                file={`${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT/${item?.CompressedFile}`}
                origFile={`${config.IMG_URL}/nft/${item?.Creator}/Original/NFT/${item?.OriginalFile}`}
                thumb={`${config.IMG_URL}/nft/${item?.Creator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                type={
                  item?.CompressedFile
                    ? item?.CompressedFile?.includes(".webp")
                      ? "image"
                      : item?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : item?.CompressedFile
                }
              />


        <div className="media-body flex_body">
          <div>
            {/* <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1">

              <span className="user_noti_colr mr-1" >
                @name </span>


              <span ></span> 	activity
              <span className="user_noti_colr mr-1"
              > @name </span></p> */}
              
            {(item?.Activity == "Mint")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} Minted ${item?.NFTQuantity} NFTs.`}</p>}
            {(item?.Activity == "PutOnSale")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} Placed ${item?.NFTQuantity} NFTs for sale.${item?.NFTPrice} ${item?.CoinName} each.`}</p>}
            {(item?.Activity == "CancelOrder")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} canceled ${item?.NFTQuantity} NFTs.`}</p>}
            {(item?.Activity == "Buy")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} bought ${item?.NFTQuantity} NFTs from ${(item?.From_DisplayName)}. `}</p>}
            {(item?.Activity == "Bid")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} placed a Bid on ${item?.NFTQuantity} NFTs for ${(item?.NFTPrice)} ${(item?.CoinName)} each. `}</p>}
            {(item?.Activity == "Edit")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} edited Bid on ${item?.NFTQuantity} NFTs to ${(item?.NFTPrice)} ${(item?.CoinName)} each. `}</p>}
            {(item?.Activity == "CancelBid")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.To_DisplayName)} cancelled Bid on ${item?.NFTQuantity} NFT(s) . `}</p>}
            {(item?.Activity == "Accept")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.From_DisplayName)} Accepted the Bid on ${item?.NFTQuantity} NFT(s) placed by ${(item?.To_DisplayName)}. `}</p>}
            {(item?.Activity == "Transfer")&&<p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1" >{`${(item?.From_DisplayName)} Transferred  ${item?.NFTQuantity} NFT(s) to ${(item?.To_DisplayName)}. `}</p>}
{/*           
              <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1">
               asjfklasjdfjas;lfdj;kasldfkl</p> */}
            <p className="mt-0 banner_user font_10 mb-0 banner_user_new mr-1">
            {moment(new Date(item?.updatedAt)).format('DD/MM/YYYY, h:mm a')}
            </p>
          </div>
        </div>
      </div>
    )
}


export default NotificationCard;