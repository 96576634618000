import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams ,useLocation} from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// mysid
import TokenCard from '../views/separate/TokenCard'
import { useSelector } from "react-redux";
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';

import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Ico(props) {


  const { ...rest } = props;

  const classes = useStyles();
  const location = useLocation();


  

  return (
  
    <div>

      <div className="home_header">


        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

<div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1 bid_section_section_1 hotbid_section">
          <div className="container-fluid container-theme container-lg">
            
              
              <div className="d-flex justify-content-center customDrop">
<h2 class="section-head">Top Collections</h2>
            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="align-items-center buyersbtns customDropdown">
                       
                       0 Day</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 200 }} className="nto_scrol_div">
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3" id="backgorundd" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >1 days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">7 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">30 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                      <div>
                     
                      </div>
                        </div>
            
          </div>
        </div>

        {/* NEW ACTIVITY */}
        <div className="container mt-3 d-none d-lg-block">
          <div className="table-responsive">
            <table class="table table_activity">
              {
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">#</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Collection Name</th>
                    <th scope="col">Avg Price</th>
                    <th scope="col"></th>

                  </tr>
                </thead>
              } 

<tbody>
                


                  
               
                      <>
                        <tr>
                          <th scope="row">
                       
                            </th>
                        <td>1</td>
                          <td>
                            <div class="xenox_td">

                       
                                
                            
                            <img src={require("../assets/images/fish.jpg")} class="img-fluid img-circle" alt="Shape" />
                          
                        
                              
                            
                              <div>
                                <p class="my-0 media_num"></p>
                                <p class="mt-0 media_text"></p>
                              </div>
                            </div>

                          </td>
                          <td>
                            <p class="my-0 media_text1">
                            
                            collectionName
                             </p>
        
                          </td>
                          <td>$0</td>
                          <td className="buynowbtnt">
                   
                              <Button>View</Button>
                              
                          </td>
                         





                        </tr>
                      </>
                
              </tbody>
            
            </table>
          </div>
        </div>
        <div className="container mt-3 d-block d-lg-none table-res">
          <div className="table-responsive">
            <table class="table table_activity">
              {
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Collection Name</th>
                    <th scope="col">Avg Price</th>
                   
                  </tr>
                </thead>
              } 

<tbody>
              

                 
                 
                   
                      <>
                        <tr>
                      
<td>1</td>
                         <td>
                            <div class="xenox_td">

                       
                                
                            
                            <img src={require("../assets/images/fish.jpg")} class="img-fluid img-circle" alt="Shape" />
                          
                        
                              
                            
                              <div>
                                <p class="my-0 media_num"></p>
                                <p class="mt-0 media_text"></p>
                              </div>
                            </div>

                          </td>
                         
                          <td>
                            <p class="my-0 media_text1">
                            
                           collectionName
                             </p>
        
                          </td>
                          <td className="ml-3">$0</td>
                          <td className="buynowbtnt">
                           
                              <Button>View</Button>
                         
                          </td>
                
                         





                        </tr>
                      </>
             
              </tbody>
         
            </table>
          </div>
        </div>


      </div>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  );
}
