import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { NavLink, Link, useLocation } from "react-router-dom";

import moment from 'moment'
import ReactMarkdown from 'react-markdown';
import { useSelector } from "react-redux";
import Countdown from "react-countdown";

import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import Avatars from "./Avatar";
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { AcceptBidRef } from './separate/AcceptBidRef';
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { LikeRef } from './separate/LikeRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { BurnRef } from './separate/BurnRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { TransferRef } from './separate/TransferRef';
import Profile from "../assets/images/carddet.jpg";





import config from "./config/config";
import ImgAudVideo from "views/separate/ImgAudVideo";
import { address_showing, isEmpty } from "../actions/common";
import useContractProviderHook from "../actions/contractProviderHook";
import { Token_Info_Func, GetLikeStatus } from "../actions/axioss/nft.axios";
import { findOwners } from "actions/axioss/user.axios";
import {Gettoken,updatetoken} from "../actions/axioss/nft.axios"





import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import Web3 from 'web3';
import Web3Utils from 'web3-utils';
import randomInteger from 'random-int';
import generator from 'generate-password';

var web3s = new Web3(config.RPC_URL)


console.log("asaaa",web3s);


const dashboardRoutes = [];

const useStyles = makeStyles(styles);


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}




export default function Info(props) {
  //   useEffect(() => {
  //     window.$('#PutOnSale_modal').modal('show');
  // });
  const classes = useStyles();
  const { ...rest } = props;
  // const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const Wallet_Details = useSelector((state)=>state.wallet_detail)

  console.log("Wallet_Details",Wallet_Details);
  const { sellerFees, buyerfees } = useSelector((state) => state.LoginReducer.ServiceFees);
  function hideDetail() {
    document.getElementById("image_div").classList.toggle('expand_img');
    // document.getElementById("img_des").classList.toggle('show_des');
    document.getElementById("detai_div").classList.toggle('hide_detail');
    document.getElementById("arrow_icon").classList.toggle('fa-shrink');

  }

  const { state } = useLocation();
  const ContractCall = useContractProviderHook();
  const [TabName, SetTabName] = useState("All");
  const [Tokens, SetTokens] = useState({
    All: {
      loader: true,
      page: 1,
      list: [],
      owner: {},
      myBid: {},
      highbid: {},
      myowner: {},
    },
  });
  const [Tokens_Detail, SetTokens_Detail] = useState({});
  const [Tokens_OwnerDetail, SetTokens_OwnerDetail] = useState({});
  console.log(Tokens_Detail, "Tokens_OwnerDetail");
  const [originalPath, setOriginalPath] = useState("")



  const [Explores, SetExplore] = useState([]);
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
  const [InfoDetail, SetInfoDetail] = useState({});

  const { network, Contract, Owner, Id } = useParams()
  const [Loader, setLoader] = useState(false);
  const [LoaderTab, setLoaderTab] = useState(false);
  const [OpenPopup, SetOpenPopup] = useState('')
  const [SendDet, SetSendDet] = useState({});
  const [BtnData, SetBtnData] = useState('start')
  const [tempLike, setTempLike] = useState(Tokens_Detail?.likecount)
  // const [lazy,setlazy]=useState({})
  // const { accountAddress, web3, } = useSelector(state => state.LoginReducer.AccountDetails);
  const { Web3Pro,web3p, web3, accountAddress,providerss } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  console.log("dshhdshd",Web3Pro,payload);
 
  const history = useHistory();
  var LikeForwardRef = useRef();

  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [heartClass, setHeartClass] = useState(false);

 
  // console.log("gdv",signature);

  useEffect(() => {
    Explore();
 
  }, [accountAddress]);



  useEffect(() => {
    setTempLike(Tokens_Detail?.likecount)
  }, [Tokens_Detail])

  useEffect(() => {
    GetIsLiked()
  }, [payload, Id])



  const GetIsLiked = async () => {
    var liked = await GetLikeStatus({ Id: Id, WalletAddress: payload?.WalletAddress })
    console.log("isliked info page", liked.isLiked)
    if ((liked?.isLiked) && (liked?.isLiked == false)) setHeartClass(false)
    else if ((liked?.isLiked) && (liked?.isLiked == true)) setHeartClass(true)

  }

  // const findOwner = async () => {
  //   var Resp = await findOwners({
  //     NFTCreator: Owner,
  //     ContractAddress: Contract,
  //     NFTId: Id,
  //   });
  //    console.log("Resp info findowners", Resp);
  //   if (Resp?.success == "success") {
  //     console.log("come ah");
  //     Explore();
  //   } else if (state && Resp?.success == "error") {
  //     console.log("come ah");
  //     SetInfoDetail(state);
  //     setLoader(false);
  //   } else {
  //     Explore();
  //   }
  // };











  useEffect(() => {
    console.log("typeof Tokens[TabName] == 'undefined'", typeof Tokens[TabName] == 'undefined')
    if (typeof Tokens[TabName] == 'undefined') {
      console.log("called useeffect")
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    } else setLoaderTab(false)
  }, [TabName, Contract, Owner, Id, accountAddress])



  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Auction Completed!</span>;
    } else {
      return (
        <span>
          {" "}
          <span className="hourds">{formatTime(days)} d</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(hours)} h</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(minutes)} m</span>{" "}
          <span className="semicolan">:</span>{" "}
          <span className="hourds">{formatTime(seconds)} s</span>{" "}
        </span>
      );
    }
  };

  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };


  // const POPUPACTION = useCallback(
  //   (data, item) => {
  //     console.log("Buy is Calling", data, item);
  //     if (accountAddress) {
  //       if (data == "Accept") {
  //         (async () => {
  //           let Statu = await ContractCall.GetApproveStatus(
  //             Tokens_Detail.ContractType == 721 ||
  //               Tokens_Detail.ContractType == "721"
  //               ? "Single"
  //               : "Multiple",
  //             Tokens_Detail.ContractAddress
  //           );
  //           if (Statu == false || Statu == "error") {
  //             toast.warn("Need To Approve");
  //             SetBtnData("open");
  //           } else {
  //             SetBtnData("error");
  //             SetOpenPopup(data);
  //           }
  //         })();
  //       } else{
  //         SetOpenPopup(data);


  //       } 
  //       SetSendDet(item);
  //     } else {
  //       if (data === "Share") {
  //         SetOpenPopup(data);
  //         SetSendDet(item);
  //       } else
  //         toast.error(" log in to connect to the wallet ", { autoClose: 1000 });
  //     }
  //   },
  //   [OpenPopup, accountAddress, Tokens_Detail.ContractAddress]
  // );
// useEffect(()=>{
//   if (payload?.kyc == "Approved") {
                        
//     POPUPACTION();
//   } 
//   else{
//     history.push(`/bidex`)
//     // toast.success("you have rejected, so not create nft")
//   }
// },[])

  const POPUPACTION = async (data, item) => {
    console.log("Buy is Calling", data, item);
    if (accountAddress) {
      SetSendDet(item);

      if (data == "Accept") {
        (async () => {
          
          let Statu = await ContractCall.GetApproveStatus(
            Tokens_Detail.ContractType == 721 ||
              Tokens_Detail.ContractType == "721"
              ? "Single"
              : "Multiple",
            Tokens_Detail.ContractAddress
          );
        
          console.log("btn data accept bid", Statu)
          if (Statu == false || Statu == "error") {
            toast.warn("Need To Approve");
            SetBtnData("open");
          } else {
            console.log("else called")
            SetBtnData("error");
            SetOpenPopup(data);
            
          }
          console.log("btn data accept bid", BtnData, SendDet)
          var AcceptBidCall = await AcceptBidForwardRef.current.AcceptBid_Click(
            {
              owner: Tokens[TabName]?.myowner,
              // bidder:SendDet,
              // approvestatus:BtnData,
              bidder: item ? item : {},
              approvestatus: (Statu == false || Statu == "error") ? "open" : "error",
              item:
              {
                NFTId: Tokens_Detail.NFTId,
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                NFTRoyalty: Tokens_Detail.NFTRoyalty,
                NFTCreator: Tokens_Detail.NFTCreator,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId

              }
            }
          )

        })();
      }
      else {
        SetOpenPopup(data);
        if (data && data == "createorder") {

          var putonsalecall = await PutOnSaleForwardRef.current.PutOnSale_Click(
            {
              owner: item,
              item: {
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,

                CompressedFile: Tokens_Detail.CompressedFile,
                CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
                OriginalFile: Tokens_Detail.OriginalFile,
                NFTCreator: Tokens_Detail.NFTCreator,
                NFTRoyalty: Tokens_Detail.RoNFTRoyaltyyalty,

                Category: Tokens_Detail.Category,
                NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
                CoinName: Tokens[TabName]?.myowner?.CoinName,
                PutOnSaleType: "FixedPrice",
                PutOnSale: true,
                ClockTime: null,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId

              }
            }
          );
        }
        if (data && data == "Cancel") {
          var cancelOrderCall = await CancelOrderForwardRef.current.CancelOrder_Click(
            {
              owner: item,
              types: "Cancel",
              item: {
                TokenName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
                CoinName: Tokens[TabName]?.myowner?.CoinName,
                CompressedFile: Tokens_Detail.CompressedFile,
                CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
                OriginalFile: Tokens_Detail.OriginalFile,
                NFTCreator: Tokens_Detail.NFTCreator,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId
              }
            }
          )

        }
        if(payload?.kyc == "Approved"){
          if (data && data == "Buy") {

            var putonsalecall = await PurchaseForwardRef.current.Purchase_click(
              {
                owner: item,
                item: {
                  NFTId: Tokens_Detail.NFTId,
                  NFTName: Tokens_Detail.NFTName,
                  ContractAddress: Tokens_Detail.ContractAddress,
                  ContractType: Tokens_Detail.ContractType,
                  NFTRoyalty: Tokens_Detail.NFTRoyalty,
                  NFTCreator: Tokens_Detail.NFTCreator,
                  CollectionNetwork: Tokens_Detail.CollectionNetwork,
                  Category: Tokens_Detail.Category,
                  status:Tokens_Detail.status,
                  chainType: Tokens_Detail.ChainId
                }
              }
            )
  
          }
          if (data && data == "Bid") {
            console.log("SendDet,", Tokens[TabName]?.myBid, SendDet, TabName)
            var placeBidCall = await PlaceAcceptCancelBidForwardRef.current.placeBid_Click(
              {
                bidder: !isEmpty(SendDet) ? SendDet : Tokens[TabName]?.myBid,
                bid: Tokens[TabName]?.highbid,
                owner: Tokens[TabName]?.owner,
                item: {
                  NFTId: Tokens_Detail.NFTId,
                  NFTName: Tokens_Detail.NFTName,
                  ContractAddress: Tokens_Detail.ContractAddress,
                  ContractType: Tokens_Detail.ContractType,
                  NFTRoyalty: Tokens_Detail.NFTRoyalty,
                  NFTCreator: Tokens_Detail.NFTCreator,
                  CollectionNetwork: Tokens_Detail.CollectionNetwork,
                  Category: Tokens_Detail.Category,
                  status:Tokens_Detail.status,
                  chainType: Tokens_Detail.ChainId
                }
              }
            )
  
          }
        }
        else{
          history.push(`/profile/${payload.CustomUrl}`)
          toast.error(`your kyc is ${payload?.kyc}, please try again`)
        }

        if (data && data == "CancelBid") {
          var placeBidCall = await PlaceAcceptCancelBidForwardRef.current.Cancel_Click(
            {
              owner: Tokens[TabName]?.owner,
              bidder: SendDet,
              item: {
                NFTId: Tokens_Detail.NFTId,
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                NFTRoyalty: Tokens_Detail.NFTRoyalty,
                NFTCreator: Tokens_Detail.NFTCreator,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId
              }
            }
          )

        }
        if (data && data == "Transfer") {
          console.log("Tokens_Detail", Tokens_Detail)
          var TransferCall = await TransferTokenRef.current.Transfer_Click(
            {
              owner: !isEmpty(SendDet) ? SendDet : Tokens[TabName].myowner,
              file: `${config.IMG_URL}/token/${Tokens_Detail.Creator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`,
              type: Tokens_Detail.CompressedFile ? (Tokens_Detail.CompressedFile?.includes('.webp') ? 'image' : Tokens_Detail.CompressedFile.includes('.webm') ? 'video' : 'audio') : Tokens_Detail.CompressedFile,
              thumb: Tokens_Detail.CompressedThumbFile,
              // noimg,
              item: {
                NFTId: Tokens_Detail.NFTId,
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                Royalty: Tokens_Detail.Royalty,
                NFTCreator: Tokens_Detail.NFTCreator,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId
                
              }
            }
          )

        }
        if (data && data == "Burn") {

          var BurnCall = await BurnTokenRef.current.Burn_Click(
            {
              owner: !isEmpty(SendDet) ? SendDet : Tokens[TabName].myowner,
              item: {
                NFTId: Tokens_Detail.NFTId,
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                Royalty: Tokens_Detail.Royalty,
                NFTCreator: Tokens_Detail.NFTCreator,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                status:Tokens_Detail.status,
                chainType: Tokens_Detail.ChainId
              }
            }
          )

        }
        if (data && data == "Report") {
          var ReportCall = await ReportTokenRef.current.Report_Click(
            {
              item: {
                NFTId: Tokens_Detail.NFTId,
                NFTName: Tokens_Detail.NFTName,
                ContractAddress: Tokens_Detail.ContractAddress,
                ContractType: Tokens_Detail.ContractType,
                NFTRoyalty: Tokens_Detail.NFTRoyalty,
                NFTCreator: Tokens_Detail.NFTCreator,
                CollectionNetwork: Tokens_Detail.CollectionNetwork,
                Category: Tokens_Detail.Category,
                status:Tokens_Detail.status,
                
              }
            }
          )

        }
        if (data && data == "Share") {
          var shareCall = await ShareRef.current.Share_Click(
            {
              title: `${Tokens_Detail.NFTName}  NFT`,
              url: `${config.FRONT_URL}/info/${Tokens_Detail.CollectionNetwork}/${Tokens_Detail.ContractAddress}/${SendDet.NFTOwner}/${Tokens_Detail.NFTId}`,
              quote: `${Tokens_Detail.NFTName} NFT`
            }
          )

        }

      }
    } else {
      if (data === "Share") {
        SetOpenPopup(data);
        SetSendDet(item);
      } else
        toast.error(" log in to connect to the wallet ", { autoClose: 1000 });
    }
  }

  console.log("Tokens[TabName]",Tokens[TabName]);


  const Explore = async (data, tab) => {
    console.log("explore info page called")
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 4,
      Owner: Owner,
      page: page ?? 1,
      from: "info",
      Contract: Contract,
      Id: Id.toString(),
      MyAdd: accountAddress && accountAddress,
    };
    console.log("Account Address Checing", SendDATA, accountAddress);
    let Resp = await Token_Info_Func(SendDATA);
    // console.log("Owners List", Resp.token.data[0].tokenowners_list);
    if (
      Resp?.token?.success == "success" &&
      Resp?.token?.data[0]?.Current_Owner.length > 0
    ) {
      if (TabName == "All") {
        console.log("length", Resp?.token?.data[0])
        console.log("tokenall", Resp?.token?.data[0]?.Current_Owner[0])
        console.log("orig patj", `${config.IMG_URL}/nft/${Resp?.token?.data[0].NFTCreator}/Original/NFT/${Resp?.token?.data[0].NFTOrginalImage}`)

        setOriginalPath(`${config.IMG_URL}/nft/${Resp?.token?.data[0].NFTCreator}/Original/NFT/${Resp?.token?.data[0].NFTOrginalImage}`)
        SetTokens_Detail(Resp?.token?.data[0]);
        SetTokens_OwnerDetail(Resp?.token?.data[0]?.Current_Owner[0])

        SetExplore(Resp?.Explore?.data);
      }
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [
              ...Tokens[TabName].list,
              ...(TabName == "owner"
                ? Resp.token.data[0].tokenowners_list
                : TabName == "bid"
                  ? Resp.Bid.data
                  : []),
            ],
            loader:
              Resp.token.Count ==
                Tokens[TabName]?.list?.length + Resp.token.data.length
                ? false
                : true,
            page: Tokens[TabName].page,
            owner:
              TabName == "All"
                ? Resp.token.data[0].Current_Owner.pop()
                : Tokens["All"].owner,
            myowner:
              TabName == "All"
                ? Resp.token.data[0].myowner.pop()
                : Tokens["All"].myowner,
            myBid: Resp?.myBid?.data.pop(),
            highbid: Resp?.highBid?.data[0],
          },
        },
      });
      console.log("kdgfdfad1111", Resp);
    } else {
      SetTokens({ [TabName]: { loader: true, page: 1, list: [] } });
    }

    setTimeout(() => {
      setLoaderTab(false);
      setLoader(false);
    }, 2000);
  };
  const tabChange = (newValue) => {
    setLoaderTab(true);
    SetTabName(newValue);
  };

  const closePop = () => {
    SetOpenPopup("");
  };
  const LikeAction = async () => {

    if (accountAddress) {
      console.log("to like ref data", Tokens_Detail)

      var check = await LikeForwardRef.current.hitLike(Tokens_Detail);
      console.log("cehck like", check)
      Explore()
      // if(check == "like") setTempLike(tempLike +1)
      // else setTempLike(tempLike - 1)
      toast.success("you " + check + "d this token", { autoClose: 500 });
    } else toast.error("Connect Wallet", { autoClose: 1000 });
  };

  function LikeList(data) {
    console.log("like data", data)
    setLikedTokenList(data);
  }

  const PutOnSaleForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const PurchaseForwardRef = useRef();
  const PlaceAcceptCancelBidForwardRef = useRef();   // ref for place , edit ,cancel bid
  const AcceptBidForwardRef = useRef();
  const TransferTokenRef = useRef();
  const BurnTokenRef = useRef();
  const ReportTokenRef = useRef();
  const ShareRef = useRef();

   




  return (


    <div className="inner_header info_pagess">

      <PutOnSaleRef
        ref={PutOnSaleForwardRef} />

      <CancelOrderRef
        ref={CancelOrderForwardRef}
      />

      <PurchaseNowRef
        ref={PurchaseForwardRef} />
      {/* For place Edit Cancel bid */}

      <PlaceAndAcceptBidRef
        ref={PlaceAcceptCancelBidForwardRef} />

      <AcceptBidRef
        ref={AcceptBidForwardRef}
        bidderdata={SendDet} />


      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        LikeList={LikeList} />

      <ShareNowRef
        ref={ShareRef} />


      <TransferRef
        ref={TransferTokenRef} />

      <BurnRef
        ref={BurnTokenRef} />

      <ReportNowRef
        ref={ReportTokenRef} />


      <>
        <Header
          fixed
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><Link to="/">
              <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: 50,
            color: "dark"
          }}
          {...rest}
        />
        <ScrollToTopOnMount />


        <div className={classes.pageHeader + " inner_pageheader info_header info"}>
          {/* info row */}
          {console.log("info page check", network, Contract, Owner, Id, Tokens_Detail)}
          <div className="row info_row mx-0 buyer_div">
            <div className="col-12 col-lg-6" id="image_div">
              <div className="flex_center">
                {
                  (Tokens_Detail.CompressedFile?.includes(".webp") || Tokens_Detail.CompressedFile?.includes(".webm")) &&
                  <div className="float-right arrow_expand" onClick={hideDetail}>
                    <i className="fas fa-arrows-alt" aria-hidden="true" id="arrow_icon"></i>
                  </div>
                }

                <div className="clearfix"></div>
                <div className="mid_profle_items">
                  {/* <img src={Profile} /> */}



                  <ImgAudVideo
                    file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT/${Tokens_Detail.CompressedFile}`}
                    type={
                      Tokens_Detail.CompressedFile
                        ? Tokens_Detail.CompressedFile?.includes(".webp")
                          ? "image"
                          : Tokens_Detail.CompressedFile.includes(".webm")
                            ? "video"
                            : "audio"
                        : Tokens_Detail.CompressedFile
                    }
                    thumb={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT_THUMB/${Tokens_Detail.CompressedThumbFile}`}
                    from="info"
                    origFile={originalPath}
                    pagetype="info"
                  //origFile={`${config.IMG_URL}/nft/${Tokens_Detail && Tokens_Detail.NFTCreator && Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail && Tokens_Detail.NFTOrginalImage && Tokens_Detail.NFTOrginalImage}`}
                  />
                </div>
                {/* <div className="img_des" id="img_des">
                  <p className="info_title">Token Name</p>
                  <h3 className="info_h3">by<span className="px-2">userInfoName</span>on<span className="pl-2">NFT</span></h3>

                </div> */}
              </div>

            </div>
            <div className="col-12 col-lg-6 bg_pink" id="detai_div">
              <div className="card_info">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="info_title">
                      {Tokens_Detail?.NFTName}</span>


                  </div>

                  <div className="masonry_likes">
                    {/* <i className="fas fa-redo pr-3 refresh_icon" aria-hidden="true" ></i> */}

                    <i className={heartClass ? "fas fa-heart mr-2 liked" : "fas fa-heart mr-2"} style={{ cursor: 'pointer' }} onClick={() => {
                      setHeartClass(!heartClass)
                      LikeAction()
                    }}></i>


                    <span className={'-likecount mr-2' + "badge badge_pink mr-2"}>{tempLike}</span>
                    <div className="dropdown dd_info_inline">
                      <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        {console.log("putonsale tye", Tokens[TabName]?.myowner?.PutOnSaleType, Tokens[TabName]?.myowner, TabName, Tokens, Tokens_OwnerDetail?.NFTOwner == accountAddress,
                          Tokens[TabName]?.myowner?.PutOnSaleType ==
                          "NotForSale" ||
                          Tokens[TabName]?.myowner?.PutOnSaleType ==
                          "UnlimitedAuction" ||
                          (Tokens[TabName]?.myowner?.PutOnSaleType ==
                            "TimedAuction" &&
                            new Date(
                              Tokens[TabName]?.myowner.EndClockTime
                            ).getTime() < Date.now()))}
                        {Tokens_OwnerDetail?.NFTOwner == accountAddress && 
                          <>
                       {console.log("Adawdadqw",Tokens_Detail)}
                       {/* {Tokens_Detail.status !== "Lazymint" && */}
                            <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => POPUPACTION('Burn', Tokens[TabName]?.myowner)}>Burn Token</span>
                            {/* } */}

                            <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => POPUPACTION('Transfer', Tokens[TabName]?.myowner)}>Transfer Token</span>
                            {/* {
                              (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "NotForSale" ||
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "UnlimitedAuction" ||
                                (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                  new Date(
                                    Tokens[TabName]?.myowner.EndClockTime
                                  ).getTime() < Date.now())) && (
                                <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() =>
                                  POPUPACTION(
                                    "createorder",
                                    Tokens[TabName]?.myowner
                                  )
                                } > Put On Sale</span>
                              )

                            } */}


                            <>

                              {/* {((Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress) && (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "FixedPrice")) && (
                                  <>
                                    <span className="dropdown-item" data-toggle="modal" data-target="#"
                                      onClick={() =>
                                        POPUPACTION(
                                          "createorder",
                                          Tokens[TabName]?.myowner
                                        )
                                      } >Change Price</span>

                                  </>)
                              } */}
                              {/* {((Tokens[TabName]?.myowner?.WalletAddress ==
                                accountAddress) && (
                                  Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "FixedPrice")) && (
                                  <>
                                    <span className="dropdown-item" data-toggle="modal" data-target="#"
                                      onClick={() =>
                                        POPUPACTION(
                                          "Cancel",
                                          Tokens[TabName]?.myowner
                                        )
                                      }
                                    >Cancel Order</span>

                                  </>
                                )} */}

                            </>

                          </>}
                          {console.log("Tokens[TabName]?.owner",Tokens[TabName]?.owner)}
                       {   Tokens_OwnerDetail?.NFTOwner != accountAddress &&
                        <span className="dropdown-item" data-toggle="modal" data-target="#report_page_modal"
                          onClick={() => POPUPACTION('Report', Tokens[TabName]?.owner)}
                        >Report</span>}
                        <span className="dropdown-item" data-toggle="modal" data-target="#share_modal"
                          onClick={() => POPUPACTION('Share', Tokens[TabName]?.owner)}
                        >Share</span>
                      </div>
                    </div>
                  </div>

                </div>
                <h3 className="info_h3">
                  <span className="pr-2">
                    <>Available Quantity : </>
                    {Tokens_OwnerDetail && Tokens_OwnerDetail?.NFTBalance} of {Tokens_OwnerDetail && Tokens_OwnerDetail?.NFTQuantity}


                  </span>

                </h3>

                <h3 className="info_h3">
                  {(Tokens_OwnerDetail && Tokens_OwnerDetail?.PutOnSaleType == "UnlimitedAuction") ?
                    <span className="pr-2">
                      Open For Bid
                    </span> :
                    (Tokens_OwnerDetail && Tokens_OwnerDetail?.PutOnSaleType == "TimedAuction") ?
                      <span className="pr-2">
                        <Countdown
                          date={new Date(Tokens_OwnerDetail?.EndClockTime)}
                          autoStart={true}
                          onStart={() => new Date(Tokens_OwnerDetail?.ClockTime)}
                          renderer={renderer}
                        >
                        </Countdown>
                      </span> :
                      <span className="pr-2">
                        price : {Tokens_OwnerDetail?.NFTPrice} {Tokens_OwnerDetail?.CoinName}
                      </span>
                  }

                </h3>





                <div className="text-center">
                  <badge className="badge badge-dark badge-timer my-3 badge_red">
                  </badge>
                </div>

                {/* <a target="_blank"><button type="submit" className="btn_ipfs">View On IPFS</button></a> */}
                <p className="info_des">{Tokens_Detail?.NFTDescription}</p>
                {/* <p className="info_des">

                  <ReactMarkdown children="unlock content" />

                </p> */}

                <nav className="masonry_tab_nav items_tab_outer mt-4 mb-3">
                  <div className="nav nav-tabs masonry_tab primary_tab items_tab d-block items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                    <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true"><div className="tab_head ">Info</div></a>
                    <a className="nav-link" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="active" aria-selected="false"><div className="tab_head"
                      onClick={() => tabChange("owner")}>Owners</div></a>
                    <a className="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false"><div className="tab_head"
                      onClick={() => tabChange("bid")}>Bid</div></a>
                    {/* <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false"><div className="tab_head">history</div></a> */}

                  </div>
                </nav>
                <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">

                  <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                    <div className="proposal_panel_overall">
                      <div className="inner_div_info">

                        {

                          <div className="media follow_media info_media">
                            <Link to={`/my-item/${Tokens_Detail?.Creator_ProfileUrl}`}>

                              <div className="info_media_img_div mr-3">
                                {Tokens_Detail?.Creator_Profile ?
                                  <img src={`${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`}></img> :
                                  <Avatars item="img-fluid"></Avatars>}

                              </div>
                            </Link>
                            <div className="media-body flex_body">
                              <div>
                                <p className="mt-0 media_num">Creator</p>
                                <p className="mt-0 media_text  mb-0" >{Tokens_Detail?.Creator_DisplayName ? Tokens_Detail?.Creator_DisplayName : Tokens_Detail?.NFTCreator}</p>

                              </div>
                              <div className="ml-2 ml-cus">
                                <div className="card owner_card my-0 border-0 shadow_rem">
                                  <div className="card-body p-2">
                                    <div className="flex_txt">
                                      <div className="media_num">{Tokens_Detail?.NFTRoyalty}%</div>

                                      <p className="mb-0 price_1 ml-1"> Royalty to the Creator</p>

                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        }
                      </div>

                    </div>
                  </div>
                  <div className="tab-pane fade" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                    <div className="proposal_panel_overall">

                      <div className="inner_div_info">
                        {Tokens["owner"]?.list?.map((data, key) => {
                          return (
                            <div className="media follow_media info_media">
                              {console.log("data owner", data)}
                              <Link to={`/my-item/${data?.CustomUrl}`}>
                                <div className="info_media_img_div mr-3">
                                  {(data?.Profile) ?
                                    <img src={`${config.IMG_URL}/user/${data?.NFTOwner}/profile/${data?.Profile}`}></img> :
                                    <Avatars item="img-fluid" />}

                                </div>
                              </Link>
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_num">Owned by</p>
                                  <p className="mt-0 media_text  mb-0">


                                    <span >{data?.DisplayName
                                      ? data?.DisplayName
                                      : data?.NFTOwner}</span>


                                  </p>

                                  {((data?.PutOnSaleType == "FixedPrice") && (data?.NFTOwner != accountAddress)) &&
                                    <>
                                      <p className="mt-0 media_text mb-0">
                                        {data?.NFTBalance}/{data?.NFTQuantity} on sale for

                                        {data?.NFTPrice}{data?.CoinName}

                                        {'each'}
                                      </p>
                                      {console.log("hdfnijdnfijnfwe",Tokens[TabName]?.owner,"TabName:",TabName,"Tokens:",Tokens,data)}
                                      <button className="create_btn mb-2 ml-1"
                                        onClick={() =>

                                          POPUPACTION("Buy", data)
                                        }>Buy Now</button>
                                    </>}
                                  {/* {((data?.PutOnSaleType == "TimedAuction" || data?.PutOnSaleType == "UnlimitedAuction" )&&
                                (data?.NFTOwner != accountAddress))&&
                                
                                <>
                                  <p className="mt-0 media_text mb-0">
                                  Available Quantity : {data?.NFTBalance}/{data?.NFTQuantity}</p>
                                  <button className="create_btn mb-2 ml-1" onClick={() => POPUPACTION("Bid", {})}>Bid Now</button>
                                  
                                    </>} */}
                                  {

                                    // <Button className="btn_outline_red ml-2 mb-2"  >Buy Now</Button>
                                  }

                                </div>

                              </div>
                            </div>
                          );
                        })
                        }
                      </div>

                    </div>
                  </div>

                  <div className="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                    <div className="proposal_panel_overall">
                      {console.log("tst token detials", Tokens["bid"])}

                      <div className="inner_div_info">
                        {Tokens["bid"]?.list?.length>0 ? Tokens["bid"]?.list?.map((data, key) => {
                          return (
                            <div className="media follow_media info_media">
                              {console.log("bid data mapping", data)}
                              <div className="info_media_img_div mr-3">
                                <Link to={`/my-item/${data?.CustomUrl}`}>

                                  <div className="img_prgo_re">

                                    {(data?.Profile) ?
                                      <img src={`${config.IMG_URL}/user/${data?.TokenBidderAddress}/profile/${data?.Profile}`}></img> :
                                      <Avatars item="img-fluid" />}

                                  </div>
                                </Link>
                              </div>
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_num mt-0 word_break_all">

                                    {data?.TokenBidAmt} {data?.CoinName} by    {data?.DisplayName
                                      ? data?.DisplayName
                                      : address_showing(
                                        data?.TokenBidderAddress
                                      )}




                                    <span>for {data?.NFTQuantity}/{Tokens_OwnerDetail && Tokens_OwnerDetail?.NFTQuantity} edition</span></p>
                                  <p className="my-0 media_text">{moment(data.updatedAt).fromNow()}</p>


                                  <div className="ml-2 ml-cus">
                                    {data?.WalletAddress == accountAddress && (
                                      <>
                                        <Button
                                          className="create_btn mb-2 ml-1"
                                          disableRipple
                                          onClick={() =>
                                            POPUPACTION("Bid", data)
                                          }
                                        >
                                          Edit Offer
                                        </Button>
                                        <Button
                                          className="create_btn mb-2 ml-1"
                                          disableRipple
                                          onClick={() =>
                                            // POPUPACTION("CancelBid", data)
                                            POPUPACTION("CancelBid", {})

                                          }
                                        >
                                          Cancel Offer
                                        </Button>
                                      </>
                                    )}
                                    {data?.WalletAddress !=
                                      Tokens["bid"]?.myowner?.WalletAddress &&
                                      Tokens["bid"]?.myowner?.WalletAddress ==
                                      accountAddress && (
                                        <Button
                                          className="create_btn mb-2 ml-1"
                                          disableRipple
                                          onClick={() =>{
                                            if(payload?.kyc == "Approved"){
                                              POPUPACTION("Accept", data)
                                            }
                                            else{
                                              history.push(`/profile/${payload.CustomUrl}`)
                                              toast.success(`your kyc is ${payload?.kyc}, please try again`)
                                            }
                                           
                                          }
                                            
                                          }
                                        >
                                          Accept Offer
                                        </Button>
                                      )}

                                  </div>

                                </div>


                              </div>

                            </div>)

                        }):<h6  className="my-0 media_text"  ><span>No Bids Yet</span></h6>}

                      </div>

                    </div>
                  </div>
                  {/* <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="hostory-tab">
                    <div className="proposal_panel_overall">
                      <div className="inner_div_info">


                        <div className="media follow_media info_media">
                          <div className="info_media_img_div mr-3">

                            {

                              <Avatars item="img-fluid"></Avatars>

                            }
                          </div>
                          <div className="media-body flex_body">
                            <div>

                              <p className="mt-0 media_num">{`Owned by`}</p>

                              <p className="mt-0 media_text  mb-0" >0x1234556677</p>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div> */}
                </div>
                <hr />
                <div className="card info_big_card shadow_rem mb-0">
                  <div className="card-body">
                    {!isEmpty(Tokens[TabName]?.highbid) && (

                      <>
                        <div className="media follow_media">
                          <div className="info_media_img_div mr-3">

                            {(Tokens[TabName]?.highbid?.Profile && Tokens[TabName]?.highbid?.CustomUrl) ?
                              <img src={`${config.IMG_URL}/user/${Tokens[TabName]?.highbid?.WalletAddress}/profile/${Tokens[TabName]?.highbid?.Profile}`}></img> :
                              <Avatars item="img-fluid"></Avatars>}
                          </div>
                          <div className="media-body flex_body">
                            <div>
                              <p className="mt-0 media_text_big_1">Highest bid by <span className="text_blk" >
                                {Tokens[TabName]?.highbid?.DisplayName}
                              </span></p>
                              <p className="mt-0 mb-0 media_text_big_2">

                                {Tokens[TabName]?.highbid?.TokenBidAmt}{" "}
                                {Tokens[TabName]?.highbid?.CoinName}
                                <span className="text_blk"></span></p>
                              {((Tokens[TabName]?.highbid?.WalletAddress !=
                                accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                accountAddress &&
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner?.EndClockTime
                                ).getTime() < Date.now())) && (
                                  <Button
                                    className="create_btn mb-2 ml-1"
                                    onClick={() =>
                                      {
                                        if(payload?.kyc == "Approved"){
                                          POPUPACTION(
                                            "Accept",
                                            Tokens[TabName]?.highbid
                                          )
                                        }
                                        else{
                                          history.push(`/profile/${payload.CustomUrl}`)
                                          toast.success(`your kyc is ${payload?.kyc}, please try again`)
                                        }
                                      }
                                     
                                    }
                                  >
                                    Accept
                                  </Button>
                                )}
                            </div>
                          </div>
                        </div></>
                    )}



                    <div className="mt-3 text-center">
                      {/* <Button className="create_btn mb-2 ml-1" data-target="#PutOnSale_modal" data-toggle="model">
                            Put on Sale
                          </Button> */}
                      {
                        (Tokens_Detail?.ContractType?.toString() ===
                          "721" ? (
                          Tokens[TabName]?.myowner?.WalletAddress ==
                            accountAddress ? (
                            Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "FixedPrice" ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION(
                                    "Cancel",
                                    Tokens[TabName]?.myowner
                                  )
                                }
                              >
                                Cancel Now
                              </Button>
                            ) : Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "NotForSale" ||
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "UnlimitedAuction" ||
                              (Tokens[TabName]?.myowner?.PutOnSaleType ==
                                "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner.EndClockTime
                                ).getTime() < Date.now()) ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION(
                                    "createorder",
                                    Tokens[TabName]?.myowner
                                  )
                                }
                              >
                                Put on Sale
                              </Button>
                            ) : (
                              Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "TimedAuction" &&
                              new Date(
                                Tokens[TabName]?.myowner?.EndClockTime
                              ).getTime() > Date.now() && (

                                <a href="#" className="tf-button">
                                  Auction is Live Now
                                </a>

                              )
                            )
                          ) : (
                            Tokens[TabName]?.owner &&
                            Tokens[TabName]?.owner?.WalletAddress !=
                            accountAddress &&
                            (Tokens[TabName]?.owner?.PutOnSaleType ==
                              "FixedPrice" ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                 
                                POPUPACTION("Buy", Tokens[TabName]?.owner)
}
                                
                              >
                                Buy Now
                              </Button>
                            ) : (
                              Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress && (
                                <Button
                                  className="create_btn mb-2 ml-1"
                                  onClick={() =>
                                    POPUPACTION("CancelBid", {})
                                  }
                                >
                                  Cancel Bid
                                </Button>
                              )
                            ))
                          )
                        ) : Tokens[TabName]?.myowner?.WalletAddress ==
                          Tokens[TabName]?.owner?.WalletAddress ? (
                          <>
                            {Tokens[TabName]?.myowner?.PutOnSaleType ==
                              "FixedPrice" && (
                                <Button
                                  className="create_btn mb-2 ml-1"
                                  onClick={() =>
                                    POPUPACTION(
                                      "Cancel",
                                      Tokens[TabName]?.myowner
                                    )
                                  }
                                >
                                  Cancel Now
                                </Button>
                              )}
                            {Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION("Bid", Tokens[TabName]?.myBid)
                                }
                              >
                                Edit Bid
                              </Button>
                            ) : (
                              Tokens[TabName]?.myowner?.WalletAddress !=
                              Tokens[TabName]?.owner?.WalletAddress && (
                                <Button
                                  className="create_btn mb-2 ml-1"
                                  onClick={() => { POPUPACTION("Bid", {})
                                 
                                  
                                 
                                }}
                                >
                                  Bid Now
                                </Button>
                              )
                            )}
                          </>
                        ) : Tokens[TabName]?.owner?.PutOnSaleType ===
                          "FixedPrice" ? (
                          <Button
                            className="create_btn mb-2 ml-1"
                            onClick={() =>
                              
                               POPUPACTION("Buy", Tokens[TabName].owner)
                            }
                          >
                            Buy Now
                          </Button>
                        ) : (
                          Tokens[TabName]?.myBid?.WalletAddress ==
                          accountAddress && (
                            <Button
                              className="create_btn mb-2 ml-1"
                              onClick={() => POPUPACTION("CancelBid", {})}
                            >
                              Cancel Bid
                            </Button>
                          )
                        ))}

                      {
                        (Tokens_Detail?.ContractType?.toString() ===
                          "721" ? (
                          Tokens[TabName]?.myowner?.WalletAddress ==
                            accountAddress ? (
                            Tokens[TabName]?.myowner?.PutOnSaleType ==
                            "FixedPrice" && (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION(
                                    "createorder",
                                    Tokens[TabName]?.myowner
                                  )
                                }
                              >
                                Change Price
                              </Button>
                            )
                          ) : (
                            Tokens[TabName]?.owner?.WalletAddress !=
                            accountAddress &&
                            (Tokens[TabName]?.owner?.PutOnSaleType ==
                              "TimedAuction" &&
                              new Date(
                                Tokens[TabName].owner.EndClockTime
                              )?.getTime() < Date.now() ? (
                              <a href="#" className="tf-button">
                                Auction End
                              </a>

                            ) : Tokens[TabName]?.highbid?.WalletAddress !=
                              accountAddress &&
                              Tokens[TabName]?.owner?.WalletAddress ==
                              accountAddress ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION(
                                    "Accept",
                                    Tokens[TabName]?.highbid
                                  )
                                }
                              >
                                Accept
                              </Button>
                            ) : Tokens[TabName]?.myBid?.WalletAddress ==
                              accountAddress ? (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() =>
                                  POPUPACTION("Bid", Tokens[TabName]?.myBid)
                                }
                              >
                                Edit Bid
                              </Button>
                            ) : (
                              <Button
                                className="create_btn mb-2 ml-1"
                                onClick={() => {POPUPACTION("Bid", {})}}
                              >
                                Bid now
                              </Button>
                            ))
                          )
                        ) : Tokens[TabName]?.myowner?.WalletAddress ==
                          Tokens[TabName]?.owner?.WalletAddress ? (
                          Tokens[TabName]?.owner?.PutOnSaleType ==
                            "FixedPrice" ? (
                            <Button
                              className="create_btn mb-2 ml-1"
                              onClick={() =>
                                POPUPACTION(
                                  "createorder",
                                  Tokens[TabName]?.myowner
                                )
                              }
                            >
                              Change Price
                            </Button>
                          ) : (
                            <Button
                              className="create_btn mb-2 ml-1"
                              onClick={() =>
                                POPUPACTION(
                                  "createorder",
                                  Tokens[TabName]?.myowner
                                )
                              }
                            >
                              Put on Sale
                            </Button>
                          )
                        ) : Tokens[TabName]?.owner?.WalletAddress !=
                          accountAddress &&
                          Tokens[TabName]?.highbid?.WalletAddress !=
                          accountAddress &&
                          Tokens[TabName]?.owner?.WalletAddress ==
                          accountAddress ? (
                          <Button
                            className="create_btn mb-2 ml-1"
                            onClick={() =>
                              POPUPACTION(
                                "Accept",
                                Tokens[TabName]?.highbid
                              )
                            }
                          >
                            Accept
                          </Button>
                        ) : Tokens[TabName]?.myBid?.WalletAddress ==
                          accountAddress ? (
                          <Button
                            className="create_btn mb-2 ml-1"
                            onClick={() =>
                              POPUPACTION("Bid", Tokens[TabName]?.myBid)
                            }
                          >
                            Edit Bid
                          </Button>
                        ) : (
                          <Button
                            className="create_btn mb-2 ml-1"
                            onClick={() => { POPUPACTION("Bid", {})}}
                          >
                            Bid Now{" "}
                          </Button>
                        ))}
                    </div>

                    <p className="mt-0 media_text_big_1 text-center">Service fee {web3?.utils.fromWei(String(sellerFees))} %,  {Tokens_OwnerDetail?.CoinName}  <>{Tokens_OwnerDetail?.NFTPrice} </>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}
